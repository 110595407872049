
export const TitleOptions: React.ReactNode[] = [
    (<option key={"title_none"} value=""></option>),
    (<option key={"title_ing"} value="Ing.">Ing.</option>),
    (<option key={"title_dipling"} value="Dipl-Ing.">Dipl-Ing.</option>),
    (<option key={"title_mag"} value="Mag.">Mag.</option>),
    (<option key={"title_dr"} value="Dr">Dr.</option>),
    (<option key={"title_kommr"} value="KommR">KommR</option>),
];

export const SalutationOptions: React.ReactNode[] = [
    (<option key={"salutation_frau"} value="Frau">Frau</option>),
    (<option key={"salutation_herr"} value="Herr">Herr</option>),
];

export const SalutationOptionsWithCompany: React.ReactNode[] = [
    ...SalutationOptions,
    (<option key={"salutation_herr"} value="Herr">Herr</option>),
];

