import React, { useState, useContext, useEffect } from "react";
import { Badge, Select, Box, Button, Skeleton, Checkbox, Divider, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";
import { IApiUserResponse } from "../../models/api/users/IApiUserResponse";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import { ApiContext } from "../../contexts/ApiProvider";
import { IApiUserEditRequest } from "../../models/api/users/IApiUserEditRequest";
import { AddUserDrawer } from "../../components/drawers/user/AddUserDrawer";
import { IApiRoleResponse } from "../../models/api/roles/IApiRoleResponse";
import { EditUserDrawer } from "../../components/drawers/user/EditUserDrawer";
import toast from "react-hot-toast";

interface IUsersProps {

}

export const Users: React.FunctionComponent<IUsersProps> = (props: IUsersProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { me, setAppShellConfig, projects, setDCM } = useContext(AppContext);
    const { getAllUsers, getAllRoles, postUser, patchUser, deleteUser } = useContext(ApiContext);

    const [filter, setFilter] = useState("");
    const [users, setUsers] = useState<IApiUserResponse[]>([]);
    const [roles, setRoles] = useState<IApiRoleResponse[]>([]);

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState<IApiUserResponse | undefined>(undefined);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: "Benutzer"
            },
            subHeading: {
                text: "Einstellungen"
            },
            rightButton: {
                text: "Erstellen",
                icon: FiPlus,
                ariaLabel: "erstellen",
                variant: "primary",
                onClick: () => {
                    setAdd(true);
                }
            }
        });
        setLoading(true);
        getAllUsers().then(users => {
            getAllRoles().then(roles => {
                setLoading(false);
                setRoles(roles);
                setUsers(users);
            });
        });
    }, [setAppShellConfig, me, navigate, getAllUsers, setUsers, getAllRoles, setRoles]);

    const deleteAction = (user: IApiUserResponse) => {
        setDCM({
            title: `Benutzer löschen`,
            body: `Sind Sie sicher, dass der Benutzer '${user.contact.firstName} ${user.contact.lastName}' (${user.email}) gelöscht werden soll?`,
            onConfirm: () => {
                var promise = deleteUser(user.id);
                toast.promise(promise, {
                    loading: `Benutzer wird gelöscht...`,
                    success: (result) => {
                        setUsers(old => {
                            var users = [...old];
                            var index = users.findIndex(u => u.id === result.id);
                            users.splice(index, 1);
                            return users;
                        });
                        return `Der Benutzer '${result.contact.firstName} ${result.contact.lastName}' (${user.email}) wurde erfolgreich gelöscht!`;
                    },
                    error: (code) => {
                        var message = `Fehler beim Löschen des Benutzers! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                });
                return promise;
            }
        });
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            <Stack spacing="4" width="full" direction="row" px="2" justifyContent="space-between" align="center">
                <InputGroup maxW={{ base: "75%", lg: undefined }}>
                    <InputLeftElement pointerEvents="none">
                        <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input placeholder="Filtern..." onChange={e => setFilter(e.target.value.toLocaleLowerCase())} boxShadow={boxShadow} />
                </InputGroup>
            </Stack>
            <ResponsiveTable 
                data={users
                    .filter(p => 
                        (p.contact.firstName && p.contact.firstName.toLowerCase().indexOf(filter) >= 0) ||
                        (p.contact.lastName && p.contact.lastName.toLowerCase().indexOf(filter) >= 0) ||
                        (p.contact.email && p.email.toLowerCase().indexOf(filter) >= 0))
                }
                loading={loading}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('contact.firstName', {
                        header: () => 'Vorname',
                        cell: info => info.renderValue(),
                    }),
                    columnHelper.accessor('contact.lastName', {
                        header: () => 'Nachname',
                        cell: info => info.renderValue(),
                    }),
                    columnHelper.accessor('email', {
                        header: () => 'E-Mail',
                        cell: info => info.renderValue(),
                    }),
                    columnHelper.accessor('role.name', {
                        header: () => 'Rolle',
                        cell: info => info.renderValue(),
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const user = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">
                                    <IconButton
                                        icon={<FiEdit2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Bearbeiten"
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            setEdit(user);
                                        }}
                                    />
                                    <IconButton
                                        icon={<FiTrash2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Löschen"
                                        disabled={me.user.id === user.id}
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            deleteAction(user);
                                        }}
                                    />                    
                                </HStack>
                            );
                        },
                    }),
                ]}
                getCard={(user) => ({
                    key: user.id,
                    value: user.id,
                    onClick: () =>  {},
                    content: (
                        <HStack spacing="4" justifyContent="space-between">
                            <VStack spacing="2" align="left">
                                <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                    {`${user.contact.firstName} ${user.contact.lastName}`}
                                </Text>
                                <HStack alignItems="center">
                                    <Text color="muted" fontSize={{ base: "xs", md: "sm" }}>
                                        {`E-Mail: ${user.email}`}
                                    </Text>
                                    <Badge colorScheme={user.role.name === "Administrator" ? 'red' : 'blue'} size="sm">
                                        {user.role.name}
                                    </Badge>
                                </HStack>
                            </VStack>
                            <HStack spacing="4" justifyContent="end">
                                <IconButton
                                    icon={<FiEdit2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Bearbeiten"
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        setEdit(user);
                                    }}
                                />
                            </HStack>
                        </HStack>
                    )
                })}
            />
            <AddUserDrawer 
                isOpen={add && roles.length > 0 /* wait for roles to be loaded... */} 
                onDiscard={() => setAdd(false)}
                onSubmit={(data) => {
                    var promise = postUser(data);
                    toast.promise(promise, {
                      loading: `Benutzer wird erstellt...`,
                      success: (result) => {
                        setAdd(false);
                        setUsers(old => ([...old, result]));
                        return `Der Benutzer '${result.contact.firstName} ${result.contact.lastName}' wurde erfolgreich erstellt!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Erstellen des Benutzers! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 409: {
                                message += `Ein Benutzer mit der E-Mail Adresse '${data.email}' existiert bereits!`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
                roles={roles}
            />
            <EditUserDrawer 
                defaultValue={edit as IApiUserEditRequest} 
                onDiscard={() => setEdit(undefined)} 
                onSubmit={(data) => {
                    var promise = patchUser(edit!.id, data);
                    toast.promise(promise, {
                      loading: `Benutzer wird aktualisiert...`,
                      success: (result) => {
                        setEdit(undefined);
                        setUsers(old => {
                            var users = [...old];
                            var index = users.findIndex(u => u.id === result.id);
                            users.splice(index, 1, result);
                            return users;
                        });
                        return `Der Benutzer '${result.contact.firstName} ${result.contact.lastName}' wurde erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Aktualisieren des Benutzers! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 404: {
                                message += `Fehler 404`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
                roles={roles}
                readonly={{
                    email: edit?.email,
                    role: (edit?.id === me.user.id) ? edit?.role : undefined,
                    self: (me.user.id === edit?.id)
                }}
            />
        </Stack>
    );

}