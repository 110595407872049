import React, { createContext, useContext, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { FiHome } from 'react-icons/fi';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { AppShell, IAppShellConfig } from './components/AppShell/AppShell';
import { DeleteConfirmationModal, IDeleteConfirmationModalConfig, IDeleteConfirmationModalProps } from './components/modals/DeleteConfirmationModal';
import { ApiContext } from './contexts/ApiProvider';
import { AuthContext } from './contexts/AuthProvider';
import { IApiMeResponse } from './models/api/me/IApiMeResponse';
import { IApiProjectSummaryResponse } from './models/api/project/IApiProjectSummaryResponse';
import { Home } from './pages/home/Home';
import { Profile } from './pages/profile/Profile';
import { ProjectContacts } from './pages/project-contacts/ProjectContacts';
import { ProjectCoreData } from './pages/project-coredata/ProjectCoreData';
import { ProjectDocuments } from './pages/project-documents/ProjectDocuments';
import { ProjectHome } from './pages/project-home/ProjectHome';
import { ProjectObservers } from './pages/project-observers/ProjectObservers';
import { ProjectParties } from './pages/project-parties/ProjectParties';
import { ProjectProtocols } from './pages/project-protocols/ProjectProtocols';
import { ProjectSafetyChecklist } from './pages/project-safetychecklist/ProjectSafetyChecklist';
import { Projects } from './pages/projects/Projects';
import { Settings } from './pages/settings/Settings';
import { Users } from './pages/settings/Users';

interface IAppContext {
  me: IApiMeResponse;
  projects: IApiProjectSummaryResponse[];
  addProject(project: IApiProjectSummaryResponse): void;
  updateProject(project: IApiProjectSummaryResponse): void;
  setAppShellConfig: React.Dispatch<React.SetStateAction<IAppShellConfig | undefined>>;
  setDCM: React.Dispatch<React.SetStateAction<IDeleteConfirmationModalConfig | undefined>>;
  setMe: React.Dispatch<React.SetStateAction<IApiMeResponse | undefined>>;
  reloadProjects(): void;
}

export const AppContext = createContext({} as IAppContext);

function App() {

  const { logout } = useContext(AuthContext);  
  const { getMe, getAllProjects } = useContext(ApiContext);

  const [me, setMe] = useState<IApiMeResponse | undefined>(undefined);
  const [projects, setProjects] = useState<IApiProjectSummaryResponse[]>([]);
  const [appShellConfig, setAppShellConfig] = useState<IAppShellConfig | undefined>(undefined);
  const [dcm, setDCM] = useState<IDeleteConfirmationModalConfig | undefined>(undefined);

  useEffect(() => {
    if(!me) {
      getMe().then(me => {
        setMe(me);
        getAllProjects().then(projects => {
          setProjects(projects);
        })
      });
    }
  }, [me, getMe, getAllProjects]);

  const addProject = (project: IApiProjectSummaryResponse) => {
    setProjects(oldState => [...oldState, project]);
  };

  const updateProject = (project: IApiProjectSummaryResponse) => {
    setProjects(oldState => {
      const index = oldState.findIndex(p => p.id === project.id);
      if(index < 0) {
        return [...oldState, project];
      } else {
        var newState = [...oldState];
        newState.splice(index, 1, {
          ...oldState[index],
          ...project
        });
        return newState;
      }
    });
  };

  const reloadProjects = () => {
    var promise = getAllProjects();
    toast.promise(promise, {
      loading: `Projekte werden aktualisiert...`,
      success: (result) => {
        setProjects(result);
        return `Projekte wurden erfolgreich aktualisiert!`;
      },
      error: (code: number) => {
        var message = `Fehler beim Aktualisieren der Projekte!`;
        switch(code) {
          case 400: {
            message += `Fehler 400`;
            break;
          }
          case 404: {
            message += `Fehler 404`;
            break;
          }
          case 500: {
            message += `Fehler 500`;
            break;
          }
        }
        return message;
      },
    });
    return promise;
  };

  if(!me) {
    return (<h1>Loding app settings...</h1>); 
  }

  return (
    <AppContext.Provider
      value={{
        me,
        projects,
        addProject,
        updateProject,
        setAppShellConfig,
        setDCM,
        setMe,
        reloadProjects
      }}
    >
      <BrowserRouter>
        <Toaster 
          position="bottom-right"
          gutter={8}
          toastOptions={{
            style: {
              fontSize: 'var(--chakra-fontSizes-sm)',
            },
          }}
        />
        <AppShell config={appShellConfig}>
          <Routes>
            <Route path="/"
                   element={
                    <Home />
                   }
            />
            <Route path="/login"
                   element={
                    <Navigate replace to="/" />
                   }
            />
            <Route path="/profile"
                   element={
                    <Profile />
                   }
            />
            <Route path="/projects"
                   element={
                    <Projects />
                   }
            />
            <Route path="/settings/*"
                   element={
                    <Settings />
                   }
            />
            <Route path="/projects/:projectId"
                   element={
                    <ProjectHome />
                   }
            />
            <Route path="/projects/:projectId/protocols"
                   element={
                    <ProjectProtocols />
                   }
            />
            <Route path="/projects/:projectId/contacts"
                   element={
                    <ProjectContacts />
                   }
            />
            <Route path="/projects/:projectId/coredata"
                   element={
                    <ProjectCoreData />
                   }
            />
            <Route path="/projects/:projectId/parties"
                   element={
                    <ProjectParties />
                   }
            />
            <Route path="/projects/:projectId/observers"
                   element={
                    <ProjectObservers />
                   }
            />
            <Route path="/projects/:projectId/documents"
                   element={
                    <ProjectDocuments />
                   }
            />
            <Route path="/projects/:projectId/safetychecklist"
                   element={
                    <ProjectSafetyChecklist />
                   }
            />
          </Routes>
        </AppShell>
        <DeleteConfirmationModal clear={() => setDCM(undefined)} {...dcm} />
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;