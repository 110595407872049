import { Stack } from '@chakra-ui/react'
import * as React from 'react'


import { IApiTradeCreateRequest } from '../../../models/api/trade/IApiTradeCreateRequest';
import { IBaseDrawerProps } from '../BaseDrawer';
import { FormikProps } from 'formik';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';
import { InputFormControl } from '../../formcontrols/InputFormControl';

export interface IAddTradeDrawerProps extends Omit<IBaseDrawerProps<IApiTradeCreateRequest>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText"> {
    defaultValue?: IApiTradeCreateRequest;
}

export const AddTradeDrawer = (props: IAddTradeDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.isOpen}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue ?? {
                value: ""
            }}
            headerText="Gewerk erstellen"
        >
            {({ handleSubmit, errors, touched, isSubmitting }: FormikProps<IApiTradeCreateRequest>) => (
                <Stack px="0" spacing="4">
                    <InputFormControl 
                        field="value"
                        label="Bezeichnung"
                        isInvalid={(errors.value && touched.value) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Bezeichnung ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.value}
                    />
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}