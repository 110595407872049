import { Box, HStack, useTheme } from '@chakra-ui/react'
import * as React from 'react'
import { BsCaretRightFill } from 'react-icons/bs'

interface NavItemProps {
  onClick(): void,
  label: string
  subtle?: boolean
  active?: boolean
  icon: React.ReactElement
  endElement?: React.ReactElement
  children?: React.ReactNode
}

export const NavItem = (props: NavItemProps) => {
  const { active, subtle, icon, children, label, endElement, onClick } = props;
  const theme = useTheme();

  return (
    <HStack
      as="a"
      onClick={onClick}
      w="full"
      px="3"
      py="2"
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="all 0.2s"
      bg={active ? theme.colors.brand["700"] : undefined}
      _hover={{ bg: theme.colors.brand["700"] }}
      _active={{ bg: theme.colors.brand["600"] }}
    >
      <Box fontSize="lg" color={active ? 'currentcolor' : theme.colors.brand["400"]}>
        {icon}
      </Box>
      <Box flex="1" fontWeight="inherit" color={subtle ? theme.colors.brand["400"] : undefined}>
        {label}
      </Box>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  )
}
