import { Avatar, Box, HStack, Text } from '@chakra-ui/react'
import * as React from 'react'

interface UserProfileProps {
  name: string
  image: string
  email: string
  onClick?(): void;
}

export const UserProfile = (props: UserProfileProps) => {
  const { name, image, email, onClick } = props
  return (
    <HStack spacing="3" ps="2" _hover={{ cursor: onClick ? "pointer" : undefined }} onClick={() => (onClick && onClick())}>
      <Avatar name={name} src={image} boxSize="10" />
      <Box>
        <Text color="on-accent" fontWeight="medium" fontSize="sm">
          {name}
        </Text>
        <Text color="on-accent-muted" fontSize="sm">
          {email}
        </Text>
      </Box>
    </HStack>
  )
}
