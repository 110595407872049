import { Stack, useBreakpointValue, FormControl, FormLabel, HStack, IconButton } from '@chakra-ui/react'
import * as React from 'react'


import { IApiSafetyChecklistCreateRequest } from '../../../models/api/safetychecklist/IApiSafetyChecklistCreateRequest';
import { IBaseDrawerProps } from '../BaseDrawer';
import { Field, FieldProps, FormikProps } from 'formik';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { SafetyChecklistEditor } from '../../SafetyChecklistEditor/SafetyChecklistEditor';
import { IApiProjectOptionObserver } from '../../../models/api/project/options/IApiProjectOptionObserver';
import { ContactSearch } from '../../ContactSearch/ContactSearch';
import { ContactSearchResult } from '../../ContactSearch/ContactSearchResult';
import { FiEdit2 } from 'react-icons/fi';

export interface IAddObserverDrawerProps extends Omit<IBaseDrawerProps<IApiProjectOptionObserver>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText"> {
    defaultValue?: IApiProjectOptionObserver;
    exclusions: string[];
}

export const AddObserverDrawer = (props: IAddObserverDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.isOpen}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue ?? {
                active: true,
                role: "",
                contact: undefined
            }}
            headerText="Beobachter hinzufügen"
            validateOnBlur={false}
        >
            {({ handleSubmit, errors, touched, isSubmitting, setFieldValue }: FormikProps<IApiProjectOptionObserver>) => (
                <Stack px="0" spacing="4">
                    <FormControl>
                        <FormLabel htmlFor="contact">Kontakt</FormLabel>
                        <Field name="contact" validate={(value: any) => (value === undefined) ? "Kontakt ist erforderlich!" : undefined}>
                            {({ meta: { value } }: FieldProps) => (
                                <>
                                    {(!value && (
                                        <ContactSearch onSelect={(c) => setFieldValue("contact", c, true)} exclusions={props.exclusions} />
                                    )) || (
                                        <HStack justifyContent="space-between">
                                            <ContactSearchResult contact={value} />
                                            <IconButton aria-label="bearbeiten" icon={<FiEdit2 />} variant="ghost" onClick={() => setFieldValue("contact", undefined, true)} />
                                        </HStack>
                                    )}
                                </>
                            )}
                        </Field>                        
                    </FormControl>
                    <InputFormControl 
                        field="role"
                        label="Rolle (optional)"
                        isInvalid={(errors.role && touched.role) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.role}
                    />
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}