import React, { useState, useContext, useEffect } from "react";
import { Badge, Select, Box, Button, ButtonGroup, Checkbox, Divider, HStack, Skeleton, IconButton, Input, Tag, InputLeftElement, FormControl, FormLabel, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";
import { IApiProjectOptionObserver } from "../../models/api/project/options/IApiProjectOptionObserver";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import { ApiContext } from "../../contexts/ApiProvider";
import { ContactSearchResult } from "../../components/ContactSearch/ContactSearchResult";
import toast from "react-hot-toast";
import { AddObserverDrawer } from "../../components/drawers/observer/AddObserverDrawer";

interface IProjectObserversProps {

}

export const ProjectObservers: React.FunctionComponent<IProjectObserversProps> = (props: IProjectObserversProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { setAppShellConfig, projects, setDCM } = useContext(AppContext);

    const { projectId } = useParams();
    const project = projects.find(p => p.id === projectId);   
    const [option, setOption] = useState<IApiProjectOptionObserver[]>([]);

    const { getProjectObservers, patchProjectObservers } = useContext(ApiContext);
    const [add, setAdd] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Beobachter`
            },
            subHeading: {
                text: `${project?.name} (${project?.ref})`
            },
            rightButton: {
                text: "Hinzufügen",
                icon: FiPlus,
                ariaLabel: "erstellen",
                variant: "primary",
                onClick: () => {
                    setAdd(true);
                }
            }
        });
        if(!project) {
            navigate(`/projects`);
            return;
        }
        setLoading(true);
        getProjectObservers(project.id).then(observers => {
            setLoading(false);
            setOption(observers);
        });
    }, [setAppShellConfig, project, navigate]);

    if(!project) {
        return <h1>Projekt nicht gefunden!</h1>
    }

    const deleteAction = (observer: IApiProjectOptionObserver) => {
        setDCM({
            title: `Beobachter löschen`,
            body: `Sind Sie sicher, dass dieser Beobachter gelöscht werden soll?`,
            onConfirm: () => {
                var observers = [...option];
                var index = observers.findIndex(o => o.contact.id === observer.contact.id);
                if(index < 0) {
                    // error, should never happen
                }
                observers.splice(index, 1);
                var promise = patchProjectObservers(project.id, observers);
                toast.promise(promise, {
                    loading: `Beobachter wird gelöscht...`,
                    success: (result) => {
                        setOption(result);
                        return `Der Beobachter wurde erfolgreich gelöscht!`;
                    },
                    error: (code) => {
                        var message = `Fehler beim Löschen des Beobachters! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 404: {
                                    message += `Fehler 404`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                });
                return promise;
            }
        });
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            <ResponsiveTable 
                data={option}
                loading={loading}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('active', {
                        header: () => 'Status',
                        cell: info => {
                            const active = info.getValue();
                            const observer = info.row.original;
                            return (
                                <Badge colorScheme={active ? 'green' : 'red'} size="sm" _hover={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        var observers = [...option];
                                        var index = observers.findIndex(o => o.contact.id === observer.contact.id);
                                        if(index < 0) {
                                            // error, should never happen
                                        }
                                        observers.splice(index, 1, {
                                            ...observers[index],
                                            active: !observers[index].active
                                        });
                                        var promise = patchProjectObservers(project.id, observers);
                                        toast.promise(promise, {
                                          loading: `Beobachter wird ${active ? 'deaktiviert' : 'aktiviert'}...`,
                                          success: (result) => {
                                            setOption(result);
                                            return `Beobachter wurde erfolgreich ${active ? 'deaktiviert' : 'aktiviert'}!`;
                                          },
                                          error: (code: number) => {
                                            var message = `Fehler beim ${active ? 'Deaktivieren' : 'Aktivieren'} des Beobachters! `;
                                            switch(code) {
                                                case 400: {
                                                    message += `Fehler 400`;
                                                    break;
                                                }
                                                case 404: {
                                                    message += `Fehler 404`;
                                                    break;
                                                }
                                                case 500: {
                                                    message += `Fehler 500`;
                                                    break;
                                                }
                                            }
                                            return message;
                                          },
                                        });
                                        return promise;
                                    }}
                                >
                                    {active ? 'aktiv' : 'inaktiv'}
                                </Badge>
                            );
                        },
                    }),
                    columnHelper.accessor('contact', {
                        header: () => 'Kontakt',
                        cell: info => (<ContactSearchResult contact={info.getValue()} />)
                    }),
                    columnHelper.accessor('role', {
                        header: () => 'Rolle',
                        cell: info => info.getValue()
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const observer = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">  
                                    <IconButton
                                        icon={<FiTrash2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Löschen"
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            deleteAction(observer);
                                        }}
                                    />                 
                                </HStack>
                            );
                        }
                    }),
                ]}
                getCard={(observer) => ({
                    key: observer.contact.id,
                    value: observer.contact.id,
                    content: (
                        <HStack spacing="4" justifyContent="space-between">
                            <VStack alignItems="start">
                                <ContactSearchResult contact={observer.contact} />
                                {observer.role && observer.role.length > 0 && (
                                    <>
                                        <Divider />
                                        <Tag colorScheme="gray" size="sm" overflowWrap="anywhere">{`Rolle: ${observer.role}`}</Tag>
                                    </>
                                )}
                            </VStack>
                            <HStack spacing="4" justifyContent="end">
                                <IconButton
                                    icon={<FiTrash2 fontSize="1.125rem" />}
                                    variant="ghost"
                                    aria-label="Löschen"
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        deleteAction(observer);
                                    }}
                                />
                            </HStack>
                        </HStack>
                    )
                })}
            />
            <AddObserverDrawer 
                isOpen={add} 
                onDiscard={() => setAdd(false)}
                onSubmit={(data) => {
                    var promise = patchProjectObservers(project.id, [...option, data]);
                    toast.promise(promise, {
                      loading: `Beobachter wird erstellt...`,
                      success: (result) => {
                        setAdd(false);
                        setOption(result);
                        return `Der Beobachter wurde erfolgreich erstellt!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Erstellen des Beobachters! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 404: {
                                message += `Fehler 404`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
                exclusions={option.map(o => o.contact.id)}
            />
        </Stack>
    );

}