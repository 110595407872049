import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, FormLabel, Divider, Switch } from '@chakra-ui/react'
import { FormikProps } from 'formik';
import * as React from 'react'

import { IApiRoleResponse } from '../../../models/api/roles/IApiRoleResponse';
import { IApiUserEditRequest } from '../../../models/api/users/IApiUserEditRequest';
import { IAuthUserRole } from '../../../models/auth/IAuthUserRole';
import { SalutationOptions, TitleOptions } from '../../../select-options/SelectOptions';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { PasswordFormControl } from '../../formcontrols/PasswordFormControl';
import { SelectFormControl } from '../../formcontrols/SelectFormControl';

import { BaseDrawer, IBaseDrawerProps } from '../BaseDrawer';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';

export interface IEditUserDrawerProps extends Omit<IBaseDrawerProps<IApiUserEditRequest>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText" | "isOpen"> {
    defaultValue: IApiUserEditRequest | undefined;
    roles: IApiRoleResponse[];
    readonly: {
        email: string | undefined;
        role: IAuthUserRole | undefined;
        self: boolean | undefined;
    }
}

export const EditUserDrawer = (props: IEditUserDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.defaultValue !== undefined}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue}
            headerText="Benutzer bearbeiten"
        >
            {({ handleSubmit, errors, touched, setFieldValue, values, isSubmitting }: FormikProps<IApiUserEditRequest>) => (
                <Stack px="0" spacing="4">
                    <FormControl>
                        <FormLabel>E-Mail</FormLabel>
                        <Input disabled={true} defaultValue={props.readonly.email} />
                        <FormHelperText></FormHelperText>
                    </FormControl>
                    {!props.readonly.self && (
                        <SelectFormControl 
                            field="role.id"
                            label="Benutzerrolle"
                            helperText={props.roles.find(r => r.id === values.role.id)?.description}
                            isInvalid={(errors.role && errors.role.id && touched.role && touched.role.id) ? true : false}
                            disabled={isSubmitting}
                            options={() => props.roles.map(r => (
                                <option key={r.id} value={r.id}>{r.name}</option>
                            ))}
                            onChange={(e) => {
                                var role: (IAuthUserRole | undefined) = props.roles.find(r => r.id === e.target.value);
                                if(role !== undefined) {
                                    setFieldValue("role", role, false);
                                }
                            }}
                            validate={(value: string) => {
                                let error;
                                if (value === null || value === undefined || value === "0") {
                                    error = "Benutzerrolle ist erforderlich!";
                                }
                                return error;
                            }}
                            errorMessage={errors.role && errors.role.id}
                        />
                    )}
                    <Divider />
                    <SelectFormControl 
                        field="contact.salutation"
                        label="Anrede"
                        isInvalid={(errors.contact?.salutation && touched.contact?.salutation) ? true : false}
                        disabled={isSubmitting}
                        options={() => SalutationOptions.filter(so => so !== "Firma")}
                        onChange={(e) => {
                            setFieldValue("contact.salutation", e.target.value, true);
                        }}
                        errorMessage={errors.contact?.salutation && errors.contact?.salutation}
                    />
                    <SelectFormControl 
                        field="contact.title"
                        label="Titel"
                        isInvalid={(errors.contact?.salutation && touched.contact?.salutation) ? true : false}
                        disabled={isSubmitting || values.contact?.salutation === "Firma"}
                        options={() => TitleOptions}
                        onChange={(e) => setFieldValue("contact.title", e.target.value, true)}
                        errorMessage={errors.contact?.title && errors.contact?.title}
                    />
                    <InputFormControl 
                        field="contact.firstName"
                        label="Vorname"
                        isInvalid={(errors.contact?.firstName && touched.contact?.firstName) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Vorname ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.contact?.firstName}
                    />
                    <InputFormControl 
                        field="contact.lastName"
                        label="Nachname"
                        isInvalid={(errors.contact?.lastName && touched.contact?.lastName) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Nachname ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.contact?.lastName}
                    />
                    <Divider />
                    <FormControl display='flex' alignItems='center'>
                        <Switch 
                            size='md' 
                            id='use-org-address' 
                            defaultChecked={!values.customAddress} 
                            onChange={e => {
                                setFieldValue("customAddress", !e.target.checked, true);
                                setFieldValue("contact.name", "", true);
                                setFieldValue("contact.street1", "", true);
                                setFieldValue("contact.street2", "", true);
                                setFieldValue("contact.city", "", true);
                                setFieldValue("contact.zip", "", true);
                                setFieldValue("contact.country", "", true);
                            }} 
                        />
                        <FormLabel htmlFor='use-org-address' mb='0' ml={2} fontSize="md">
                            Adresseinstellungen der Organisation verwenden?
                        </FormLabel>
                    </FormControl>
                    <InputFormControl 
                        field="contact.name"
                        label="Firmenname"
                        isInvalid={(errors.contact?.name && touched.contact?.name) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.name}
                    />
                    <InputFormControl 
                        field="contact.street1"
                        label="Adresszeile 1"
                        isInvalid={(errors.contact?.street1 && touched.contact?.street1) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.street1}
                    />
                    <InputFormControl 
                        field="contact.street2"
                        label="Adresszeile 2"
                        isInvalid={(errors.contact?.street2 && touched.contact?.street2) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.street2}
                    />
                    <InputFormControl 
                        field="contact.zip"
                        label="Postleitzahl"
                        isInvalid={(errors.contact?.zip && touched.contact?.zip) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.zip}
                        validate={(value: string) => {
                            let error;
                            if ((value !== null && value !== undefined && value.length > 0) && (value.length < 4 || isNaN(parseInt(value)))) {
                                error = "Ungültige Postleitzahl!";
                            }
                            return error;
                        }}
                    />
                    <InputFormControl 
                        field="contact.city"
                        label="Ort"
                        isInvalid={(errors.contact?.city && touched.contact?.city) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.city}
                    />
                    <SelectFormControl 
                        field="contact.country"
                        label="Land"
                        isInvalid={(errors.contact?.country && touched.contact?.country) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        options={() => [
                            (<option key={"country_none"} value=""></option>),
                            (<option key={"country_at"} value="AT">Österreich</option>),
                            (<option key={"country_de"} value="DE">Deutschland</option>),
                            (<option key={"country_ch"} value="CH">Schweiz</option>),
                        ]}
                        onChange={(e) => setFieldValue("contact.country", e.target.value, true)}
                        errorMessage={errors.contact?.salutation && errors.contact?.salutation}
                    />
                    <Divider />
                    <FormControl display='flex' alignItems='center'>
                        <Switch 
                            size='md' 
                            id='use-org-contact' 
                            defaultChecked={!values.customContact} 
                            onChange={e => {
                                setFieldValue("customContact", !e.target.checked, true);
                                setFieldValue("contact.phone1", "", true);
                                setFieldValue("contact.phoneM", "", true);
                            }} 
                        />
                        <FormLabel htmlFor='use-org-contact' mb='0' ml={2} fontSize="md">
                            Kontakteinstellungen der Organisation verwenden?
                        </FormLabel>
                    </FormControl>
                    <InputFormControl 
                        field="contact.phone1"
                        label="Telefon"
                        isInvalid={(errors.contact?.phone1 && touched.contact?.phone1) ? true : false}
                        disabled={!values.customContact || isSubmitting}
                        errorMessage={errors.contact?.phone1}
                    />
                    <InputFormControl 
                        field="contact.phoneM"
                        label="Mobil"
                        isInvalid={(errors.contact?.phoneM && touched.contact?.phoneM) ? true : false}
                        disabled={!values.customContact || isSubmitting}
                        errorMessage={errors.contact?.phoneM}
                    />
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}