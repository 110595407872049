import React, { useState, useContext, useEffect } from "react";
import { Badge, Select, Box, Button, ButtonGroup, Skeleton, Divider, HStack, Icon, IconButton, Tag, FormLabel, InputLeftElement, List, Tooltip, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus, FiCheck, FiSquare, FiX } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";
import { IApiProjectOptionParty } from "../../models/api/project/options/IApiProjectOptionParty";
import { ApiContext } from "../../contexts/ApiProvider";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import { ContactSearchResult } from "../../components/ContactSearch/ContactSearchResult";
import toast from "react-hot-toast";
import { RadioIconButton, RadioIconButtonGroup } from "../../components/RadioIconButtonGroup/RadioIconButtonGroup";
import Helpers from "../../Helpers";
import { AddPartyDrawer } from "../../components/drawers/party/AddPartyDrawer";
import { IApiTradeResponse } from "../../models/api/trade/IApiTradeResponse";

interface IProjectPartyDocumentStateProps {
    defaultValue: boolean | null;
    onChange(value: boolean | null): void;
    isDisabled?: boolean;
}

export const boolToString = (value: boolean | null) => (value === null) ? "null" : (value ? "true" : "false");
export const stringToBool = (value: string) => (value === "null") ? null : (value === "true" ? true : false);

export const ProjectPartyDocumentState = (props: IProjectPartyDocumentStateProps) => {

    const radioButtonProps = {
        w: "24px",
        h: "24px",
        minW: "24px",
        maxW: "24px",
        minH: "24px",
        maxH: "24px"
    };

    return (
        <RadioIconButtonGroup key="aba" defaultValue={boolToString(props.defaultValue)} size="md" onChange={e => props.onChange(stringToBool(e))} isDisabled={props.isDisabled}>
            <RadioIconButton value="true" aria-label="true" {...radioButtonProps} icon={<FiCheck fontSize="0.75rem" />}
                             _checked={{
                                 bg: 'green.200'
                             }}
            />
            <RadioIconButton value="false" aria-label="false" {...radioButtonProps} icon={<FiX fontSize="0.75rem" />}
                             _checked={{
                                bg: 'red.200'
                             }} 
            
            />
            <RadioIconButton value="null" aria-label="null" {...radioButtonProps} icon={<FiSquare fontSize="0.75rem" />} />
        </RadioIconButtonGroup>
    );

}

interface IProjectPartiesProps {

}

export const ProjectParties: React.FunctionComponent<IProjectPartiesProps> = (props: IProjectPartiesProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { setAppShellConfig, projects, setDCM } = useContext(AppContext);

    const { projectId } = useParams();
    const project = projects.find(p => p.id === projectId);   
    const [option, setOption] = useState<IApiProjectOptionParty[]>([]);
    const [trades, setTrades] = useState<IApiTradeResponse[]>([]);

    const { getProjectParties, patchProjectParties, getAllTrades } = useContext(ApiContext);
    const [add, setAdd] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Beteiligtenliste`
            },
            subHeading: {
                text: `${project?.name} (${project?.ref})`
            },
            rightButton: {
                text: "Hinzufügen",
                icon: FiPlus,
                ariaLabel: "erstellen",
                variant: "primary",
                onClick: () => {
                    setAdd(true);
                }
            }
        });
        if(!project) {
            navigate(`/projects`);
            return;
        }
        setLoading(true);
        getProjectParties(project.id).then(parties => {
            setLoading(false);
            setOption(parties);
            getAllTrades().then(result => setTrades(result));
        });
    }, [setAppShellConfig, project, navigate]);

    if(!project) {
        return <h1>Projekt nicht gefunden!</h1>
    }

    const deleteAction = (party: IApiProjectOptionParty) => {
        setDCM({
            title: `Projektbeteiligten löschen`,
            body: `Sind Sie sicher, dass dieser Projektbeteiligte gelöscht werden soll?`,
            onConfirm: () => {
                var parties = [...option];
                var index = parties.findIndex(p => p.contact.id === party.contact.id);
                if(index < 0) {
                    // error, should never happen
                }
                parties.splice(index, 1);
                var promise = patchProjectParties(project.id, parties);
                toast.promise(promise, {
                    loading: `Projektbeteiligter wird gelöscht...`,
                    success: (result) => {
                        setOption(result);
                        return `Der Projektbeteiligte wurde erfolgreich gelöscht!`;
                    },
                    error: (code) => {
                        var message = `Fehler beim Löschen des Projektbeteiligten! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 404: {
                                    message += `Fehler 404`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                });
                return promise;
            }
        });
    };

    const setDocumentStateAction = (party: IApiProjectOptionParty, property: keyof(IApiProjectOptionParty), value: boolean | null) => {

        var index = option.indexOf(party);
        if(index < 0) {
            // error, should never happen
            return;
        }
        
        var parties = [...option];
        parties.splice(index, 1, {
            ...party,
            [property]: value
        });
        var promise = patchProjectParties(project.id, parties);
        toast.promise(promise, {
            loading: `Dokumentenstatus wird aktualisiert...`,
            success: (result) => {
                setOption(result);
                return `Der Dokumentenstatus wurde erfolgreich aktualisiert!`;
            },
            error: (code) => {
                var message = `Fehler beim Aktualisieren des Dokumentenstatus! `;
                    switch(code) {
                        case 400: {
                            message += `Fehler 400`;
                            break;
                        }
                        case 404: {
                            message += `Fehler 404`;
                            break;
                        }
                        case 500: {
                            message += `Fehler 500`;
                            break;
                        }
                    }
                return message;
            }
        });

    }

    return (
        <Stack spacing="4" height="full" direction="column">
            <ResponsiveTable 
                data={option}
                loading={loading}
                cellWidths={[
                    "5%", "30%", "20%", "15%", "22.5%", "7.5%"
                ]}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('active', {
                        header: () => 'Status',
                        cell: info => {
                            const active = info.getValue();
                            const party = info.row.original;
                            return (
                                <Badge colorScheme={active ? 'green' : 'red'} size="sm" _hover={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        var parties = [...option];
                                        var index = parties.findIndex(p => p.contact.id === party.contact.id);
                                        if(index < 0) {
                                            // error, should never happen
                                        }
                                        parties.splice(index, 1, {
                                            ...parties[index],
                                            active: !parties[index].active
                                        });
                                        var promise = patchProjectParties(project.id, parties);
                                        toast.promise(promise, {
                                          loading: `Projektbeteiligter wird ${active ? 'deaktiviert' : 'aktiviert'}...`,
                                          success: (result) => {
                                            setOption(result);
                                            return `Projektbeteiligter wurde erfolgreich ${active ? 'deaktiviert' : 'aktiviert'}!`;
                                          },
                                          error: (code: number) => {
                                            var message = `Fehler beim ${active ? 'Deaktivieren' : 'Aktivieren'} des Projektbeteiligten! `;
                                            switch(code) {
                                                case 400: {
                                                    message += `Fehler 400`;
                                                    break;
                                                }
                                                case 404: {
                                                    message += `Fehler 404`;
                                                    break;
                                                }
                                                case 500: {
                                                    message += `Fehler 500`;
                                                    break;
                                                }
                                            }
                                            return message;
                                          },
                                        });
                                        return promise;
                                    }}
                                >
                                    {active ? 'aktiv' : 'inaktiv'}
                                </Badge>
                            );
                        },
                    }),
                    columnHelper.accessor('contact', {
                        header: () => 'Firma',
                        cell: info => (<ContactSearchResult contact={info.getValue()} />)
                    }),
                    columnHelper.accessor('trade', {
                        header: () => 'Gewerk',
                        cell: info => info.getValue().value
                    }),
                    columnHelper.accessor('startDate', {
                        header: () => 'Arbeitsbeginn',
                        cell: info => Helpers.formatDate(info.getValue())
                    }),
                    columnHelper.display({
                        id: 'nachweise',
                        header: () => 'Dokumente',
                        cell: info => {
                            const party = info.row.original;
                            return (
                                <Stack spacing={{ base: '2', xl: '4'}} direction={{ base: 'column', xl: 'row'}}>  
                                    <Stack spacing={{ base: '1', xl: '0'}} direction={{ base: 'row', xl: 'column'}} justifyContent={{ base: "space-between", xl: undefined}}>
                                        <FormLabel fontSize='xs'><Tooltip label='Arbeitsbeginnanzeige' fontSize='sm'>ABA</Tooltip></FormLabel>
                                        <ProjectPartyDocumentState defaultValue={party.aba} isDisabled={!party.active} 
                                            onChange={(value) => setDocumentStateAction(party, "aba", value)} 
                                        />
                                    </Stack>    
                                    <Stack spacing={{ base: '1', xl: '0'}} direction={{ base: 'row', xl: 'column'}} justifyContent={{ base: "space-between", xl: undefined}}>
                                        <FormLabel fontSize='xs'><Tooltip label='Unterweisungsnachweis' fontSize='sm'>UWN</Tooltip></FormLabel>
                                        <ProjectPartyDocumentState defaultValue={party.uwn} isDisabled={!party.active} 
                                            onChange={(value) => setDocumentStateAction(party, "uwn", value)} 
                                        />
                                    </Stack> 
                                    <Stack spacing={{ base: '1', xl: '0'}} direction={{ base: 'row', xl: 'column'}} justifyContent={{ base: "space-between", xl: undefined}}>
                                        <FormLabel fontSize='xs'><Tooltip label='Baustellenevaluierung' fontSize='sm'>BEV</Tooltip></FormLabel>
                                        <ProjectPartyDocumentState defaultValue={party.bev} isDisabled={!party.active} 
                                            onChange={(value) => setDocumentStateAction(party, "bev", value)} 
                                        />
                                    </Stack>             
                                </Stack>
                            );
                        }
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const party = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">  
                                    <IconButton
                                        icon={<FiTrash2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Löschen"
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            deleteAction(party);
                                        }}
                                    />                 
                                </HStack>
                            );
                        }
                    }),
                ]}
                getCard={(party) => ({
                    key: party.contact.id,
                    value: party.contact.id,
                    content: (
                        <VStack spacing="4">
                            <HStack w="full" justifyContent="space-between">
                                <ContactSearchResult contact={party.contact} />
                                <VStack spacing="2">  
                                    <HStack spacing="1" justifyContent="space-between" w="full" alignItems="center">
                                        <FormLabel fontSize='xs'><Tooltip label='Arbeitsbeginnanzeige' fontSize='sm'>ABA</Tooltip></FormLabel>
                                        <ProjectPartyDocumentState defaultValue={party.aba} isDisabled={!party.active} 
                                            onChange={(value) => setDocumentStateAction(party, "aba", value)} 
                                        />
                                    </HStack>    
                                    <HStack spacing="1" justifyContent="space-between" w="full" alignItems="center">
                                        <FormLabel fontSize='xs'><Tooltip label='Unterweisungsnachweis' fontSize='sm'>UWN</Tooltip></FormLabel>
                                        <ProjectPartyDocumentState defaultValue={party.uwn} isDisabled={!party.active} 
                                            onChange={(value) => setDocumentStateAction(party, "uwn", value)} 
                                        />
                                    </HStack> 
                                    <HStack spacing="1" justifyContent="space-between" w="full" alignItems="center">
                                        <FormLabel fontSize='xs'><Tooltip label='Baustellenevaluierung' fontSize='sm'>BEV</Tooltip></FormLabel>
                                        <ProjectPartyDocumentState defaultValue={party.bev} isDisabled={!party.active} 
                                            onChange={(value) => setDocumentStateAction(party, "bev", value)} 
                                        />
                                    </HStack>             
                                </VStack>
                            </HStack>
                            <Divider />
                            <HStack w="full" justifyContent="space-between">
                                <Badge colorScheme={party.active ? 'green' : 'red'} size="sm" _hover={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        var parties = [...option];
                                        var index = parties.findIndex(p => p.contact.id === party.contact.id);
                                        if(index < 0) {
                                            // error, should never happen
                                        }
                                        parties.splice(index, 1, {
                                            ...parties[index],
                                            active: !parties[index].active
                                        });
                                        var promise = patchProjectParties(project.id, parties);
                                        toast.promise(promise, {
                                          loading: `Projektbeteiligter wird ${party.active ? 'deaktiviert' : 'aktiviert'}...`,
                                          success: (result) => {
                                            setOption(result);
                                            return `Projektbeteiligter wurde erfolgreich ${party.active ? 'deaktiviert' : 'aktiviert'}!`;
                                          },
                                          error: (code: number) => {
                                            var message = `Fehler beim ${party.active ? 'Deaktivieren' : 'Aktivieren'} des Projektbeteiligten! `;
                                            switch(code) {
                                                case 400: {
                                                    message += `Fehler 400`;
                                                    break;
                                                }
                                                case 404: {
                                                    message += `Fehler 404`;
                                                    break;
                                                }
                                                case 500: {
                                                    message += `Fehler 500`;
                                                    break;
                                                }
                                            }
                                            return message;
                                          },
                                        });
                                        return promise;
                                    }}
                                >
                                    {party.active ? 'aktiv' : 'inaktiv'}
                                </Badge>
                                <Tag colorScheme={'gray'} size="sm" lineHeight="1.25" overflowWrap="anywhere">
                                    {`Gewerk: ${party.trade.value}`}
                                </Tag>
                                <Tag colorScheme={'gray'} size="sm" lineHeight="1.25">
                                    {`Arbeitsbeginn: ${Helpers.formatDate(party.startDate)}`}
                                </Tag>
                                <IconButton
                                    icon={<FiTrash2 fontSize="1.125rem" />}
                                    variant="ghost"
                                    aria-label="Löschen"
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        deleteAction(party);
                                    }}
                                />
                            </HStack>
                        </VStack>
                    )
                })}
            />
            <AddPartyDrawer 
                isOpen={add} 
                onDiscard={() => setAdd(false)}
                onSubmit={(data) => {
                    console.log(data)
                    var promise = patchProjectParties(project.id, [...option, data]);
                    toast.promise(promise, {
                      loading: `Projektbeteiligter wird erstellt...`,
                      success: (result) => {
                        setAdd(false);
                        setOption(result);
                        return `Der Projektbeteiligte wurde erfolgreich erstellt!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Erstellen des Projektbeteiligten! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 404: {
                                message += `Fehler 404`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
                exclusions={option.map(p => p.contact.id)}
                trades={trades}
            />
        </Stack>
    );

}