import { As, Box, Button, Divider, Textarea ,Flex, Heading, HStack, Icon, FormErrorMessage, IconButton, useColorModeValue as mode, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ListIcon, List, ListItem, Tag, TagLeftIcon, FormControl, FormHelperText, FormLabel } from '@chakra-ui/react'
import * as React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
  } from '@chakra-ui/react'
import { useState, useEffect } from 'react';


import { IApiProjectCreateRequest } from '../../../models/api/project/IApiProjectCreateRequest';
import { BaseDrawer, IBaseDrawerProps } from '../BaseDrawer';
import { Field, FormikProps } from 'formik';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';
import { InputFormControl } from '../../formcontrols/InputFormControl';

export interface IAddProjectDrawerProps extends Omit<IBaseDrawerProps<IApiProjectCreateRequest>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText"> {
    defaultValue?: IApiProjectCreateRequest;
}

export const AddProjectDrawer = (props: IAddProjectDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.isOpen}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue ?? {
                name: "",
                ref: ""
            }}
            headerText="Projekt erstellen"
        >
            {({ handleSubmit, errors, touched, isSubmitting }: FormikProps<IApiProjectCreateRequest>) => (
                <Stack px="0" spacing="4">
                    <InputFormControl 
                        field="ref"
                        label="Referenz (Geschäftszahl)"
                        helperText="Interne Projektnummer"
                        isInvalid={(errors.ref && touched.ref) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Referenz ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.ref}
                    />
                    <InputFormControl 
                        field="name"
                        label="Name"
                        helperText="Interner Projekttitel"
                        isInvalid={(errors.name && touched.name) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Name ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.name}
                    />
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}