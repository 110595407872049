import { createContext, JSXElementConstructor, ReactElement, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IApiMeResponse } from "../models/api/me/IApiMeResponse";
import { IApiRoleResponse } from "../models/api/roles/IApiRoleResponse";
import { IApiUserCreateRequest } from "../models/api/users/IApiUserCreateRequest";
import { IApiUserEditRequest } from "../models/api/users/IApiUserEditRequest";
import { IApiUserResponse } from "../models/api/users/IApiUserResponse";
import { IApiProjectCreateRequest } from "../models/api/project/IApiProjectCreateRequest";
import { IApiProjectEditRequest } from "../models/api/project/IApiProjectEditRequest";
import { IApiProjectSummaryResponse } from "../models/api/project/IApiProjectSummaryResponse";
import { AuthContext } from "./AuthProvider";
import { IApiTradeResponse } from "../models/api/trade/IApiTradeResponse";
import { IApiTradeEditRequest } from "../models/api/trade/IApiTradeEditRequest";
import { IApiTradeCreateRequest } from "../models/api/trade/IApiTradeCreateRequest";
import { IApiDeficitResponse } from "../models/api/deficit/IApiDeficitResponse";
import { IApiDeficitCreateRequest } from "../models/api/deficit/IApiDeficitCreateRequest";
import { IApiDeficitEditRequest } from "../models/api/deficit/IApiDeficitEditRequest";
import { IApiContactResponse } from "../models/api/contact/IApiContactResponse";
import { IApiContactEditRequest } from "../models/api/contact/IApiContactEditRequest";
import { IApiContactCreateRequest } from "../models/api/contact/IApiContactCreateRequest";
import { IApiSafetyChecklistCreateRequest } from "../models/api/safetychecklist/IApiSafetyChecklistCreateRequest";
import { IApiSafetyChecklistEditRequest } from "../models/api/safetychecklist/IApiSafetyChecklistEditRequest";
import { IApiSafetyChecklistResponse, IApiSafetyChecklistTopicResponse } from "../models/api/safetychecklist/IApiSafetyChecklistResponse";
import { IApiProjectOptionCoreData } from "../models/api/project/options/IApiProjectOptionCoreData";
import { IApiProjectOptionParty } from "../models/api/project/options/IApiProjectOptionParty";
import { IApiProjectOptionObserver } from "../models/api/project/options/IApiProjectOptionObserver";
import { IApiProjectOptionSafetyChecklist } from "../models/api/project/options/IApiProjectOptionSafetyChecklist";
import { IApiFileListResponse } from "../models/api/file/IApiFileListResponse";
import { IApiFileUploadResponse } from "../models/api/file/IApiFileUploadResponse";
import { IApiProtocolResponse, IApiProtocolResponseDeficit, IApiProtocolResponseDeficitEntry, IApiProtocolResponseDeficitEntryAttachment } from "../models/api/protocol/IApiProtocolResponse";
import { IApiProtocolOptionSafetyChecklistResponse } from "../models/api/protocol/options/IApiProtocolOptionSafetyChecklistResponse";
import { IApiProtocolOptionTimeResponse } from "../models/api/protocol/options/IApiProtocolOptionTimeResponse";
import { IApiProtocolOptionDateResponse } from "../models/api/protocol/options/IApiProtocolOptionDateResponse";
import { IApiProtocolOptionDeficitClosedResponse } from "../models/api/protocol/options/IApiProtocolOptionDeficitClosedResponse";
import { IApiProtocolOptionDeficitResponsibilityResponse } from "../models/api/protocol/options/IApiProtocolOptionDeficitResponsibilityResponse";
import { IApiProtocolOptionFixUntilResponse } from "../models/api/protocol/options/IApiProtocolOptionFixUntilResponse";
import { IApiProtocolOptionImminentDangerResponse } from "../models/api/protocol/options/IApiProtocolOptionImminentDangerResponse";
import { IApiProtocolOptionResendDocumentsResponse } from "../models/api/protocol/options/IApiProtocolOptionResendDocumentsResponse";
import { IApiProtocolOptionDeficitEntryTextResponse } from "../models/api/protocol/options/IApiProtocolOptionDeficitEntryTextResponse";
import { IApiProtocolOptionDeficitEntrySaveResponse } from "../models/api/protocol/options/IApiProtocolOptionDeficitEntrySaveResponse";
import { IApiProtocolOptionDeficitTitleResponse } from "../models/api/protocol/options/IApiProtocolOptionDeficitTitleResponse";
import { IApiFileInfoEditRequest } from "../models/api/file/IApiFileInfoEditRequest";
import { IApiFileInfoEditResponse } from "../models/api/file/IApiFileInfoEditResponse";
import { IApiFileDataEditResponse } from "../models/api/file/IApiFileDataEditResponse";
import { IApiFileRestoreEditResponse } from "../models/api/file/IApiFileRestoreEditResponse";
import { IApiSuggestionResponse } from "../models/api/suggestion/IApiSuggestionResponse";
import { IApiProjectContactResponse } from "../models/api/contact/IApiProjectContactResponse";
import { IApiProtocolSummaryResponse } from "../models/api/protocol/IApiProtocolSummaryResponse";
import { IApiProtocolSubmissionResponse } from "../models/api/protocol/IApiProtocolSubmissionResponse";
import { IApiDeficitsMetadataResponse } from "../models/api/deficit/IApiDeficitsMetadataResponse";
import { IApiContactsMetadataResponse } from "../models/api/contact/IApiContactsMetadataResponse";
import { IApiOrganizationEditRequest } from "../models/api/organization/IApiOrganizationEditRequest";
import { IApiOrganizationResponse } from "../models/api/organization/IApiOrganizationResponse";
import { IApiMeEditRequest } from "../models/api/me/IApiMeEditRequest";
import { IApiMePwdTicketResponse } from "../models/api/me/IApiMePwdTicketResponse";

interface IApiContext {
    getMe(): Promise<IApiMeResponse>;
    getMePwdTicket(): Promise<IApiMePwdTicketResponse>;
    patchMe(data: IApiMeEditRequest): Promise<IApiMeResponse>;

    patchOrganization(id: string, data: IApiOrganizationEditRequest): Promise<IApiOrganizationResponse>;

    getProject(id: string): Promise<IApiProjectSummaryResponse>;
    getAllProjects(): Promise<IApiProjectSummaryResponse[]>
    postProject(data: IApiProjectCreateRequest): Promise<IApiProjectSummaryResponse>;
    patchProject(id: string, data: IApiProjectEditRequest): Promise<IApiProjectSummaryResponse>;
    
    getProjectCoreData(id: string): Promise<IApiProjectOptionCoreData>;
    getProjectParties(id: string): Promise<IApiProjectOptionParty[]>;
    getProjectObservers(id: string): Promise<IApiProjectOptionObserver[]>;
    getProjectSafetyChecklist(id: string): Promise<IApiProjectOptionSafetyChecklist>;
    getProjectContacts(id: string): Promise<IApiProjectContactResponse[]>;
    patchProjectCoreData(id: string, data: IApiProjectOptionCoreData): Promise<IApiProjectOptionCoreData>;
    patchProjectParties(id: string, data: IApiProjectOptionParty[]): Promise<IApiProjectOptionParty[]>;
    patchProjectObservers(id: string, data: IApiProjectOptionObserver[]): Promise<IApiProjectOptionObserver[]>;
    patchProjectSafetyChecklist(id: string, data: IApiProjectOptionSafetyChecklist): Promise<IApiProjectOptionSafetyChecklist>;

    postProjectProtocol(projectId: string): Promise<IApiProtocolResponse>;

    getAllUsers(): Promise<IApiUserResponse[]>;

    getAllRoles(): Promise<IApiRoleResponse[]>;

    postUser(data: IApiUserCreateRequest): Promise<IApiUserResponse>;
    patchUser(id: string, data: IApiUserEditRequest): Promise<IApiUserResponse>;
    deleteUser(id: string): Promise<IApiUserResponse>;

    getAllTrades(): Promise<IApiTradeResponse[]>;
    postTrade(data: IApiTradeCreateRequest): Promise<IApiTradeResponse>;
    patchTrade(id: string, data: IApiTradeEditRequest): Promise<IApiTradeResponse>;
    deleteTrade(id: string): Promise<IApiTradeResponse>;

    getFilteredDeficits(filter: string): Promise<IApiDeficitResponse[]>;
    getPagedDeficits(page: number): Promise<IApiDeficitResponse[]>;
    getDeficitsMetadata(): Promise<IApiDeficitsMetadataResponse>;
    // ---
    postDeficit(data: IApiDeficitCreateRequest): Promise<IApiDeficitResponse>;
    patchDeficit(id: string, data: IApiDeficitEditRequest): Promise<IApiDeficitResponse>;
    deleteDeficit(id: string): Promise<IApiDeficitResponse>;

    getFilteredContacts(filter: string): Promise<IApiContactResponse[]>;
    getPagedContacts(page: number): Promise<IApiContactResponse[]>;
    getContactsMetadata(): Promise<IApiContactsMetadataResponse>;
    // ---
    postContact(data: IApiContactCreateRequest): Promise<IApiContactResponse>;
    patchContact(id: string, data: IApiContactEditRequest): Promise<IApiContactResponse>;
    deleteContact(id: string): Promise<IApiContactResponse>;

    getAllChecklists(): Promise<IApiSafetyChecklistResponse[]>;

    postSafetyChecklist(data: IApiSafetyChecklistCreateRequest): Promise<IApiSafetyChecklistResponse>;
    patchSafetyChecklist(id: string, data: IApiSafetyChecklistEditRequest): Promise<IApiSafetyChecklistResponse>;
    deleteSafetyChecklist(id: string): Promise<IApiSafetyChecklistResponse>;

    getFile(folderName: string, fileId: string): Promise<void>;
    getAllFiles(folderName: string, project?: string): Promise<IApiFileListResponse[]>;
    postFile(folderName: string, fileName: string, projectId: string, contentType: string): Promise<IApiFileUploadResponse>;
    patchFileInfo(folderName: string, fileId: string, data: IApiFileInfoEditRequest): Promise<IApiFileInfoEditResponse>;
    patchFileData(folderName: string, fileId: string): Promise<IApiFileDataEditResponse>;
    patchFileRestore(folderName: string, fileId: string): Promise<IApiFileRestoreEditResponse>;
    deleteFile(folderName: string, fileId: string): Promise<IApiFileListResponse>;

    getProtocol(protocolId: string): Promise<IApiProtocolResponse>;
    deleteProtocol(protocolId: string): Promise<IApiProtocolSummaryResponse[]>;
    submitProtocol(protocolId: string, preview: boolean): Promise<IApiProtocolSubmissionResponse>;

    postProtocolDeficit(protocolId: string): Promise<IApiProtocolResponseDeficit>;
    postProtocolDeficitEntry(protocolId: string, deficitId: string): Promise<IApiProtocolResponseDeficitEntry>;
    postProtocolDeficitEntryAttachment(protocolId: string, deficitId: string, entryId: string, folderName: string, fileName: string, blobId: string): Promise<IApiProtocolResponseDeficitEntryAttachment>;

    patchProtocolDate(protocolId: string, date: string): Promise<IApiProtocolOptionDateResponse>;
    patchProtocolTime(protocolId: string, time: string): Promise<IApiProtocolOptionTimeResponse>;
    patchProtocolImminentDanger(protocolId: string, imminentDanger: boolean): Promise<IApiProtocolOptionImminentDangerResponse>;
    patchProtocolFixUntil(protocolId: string, fixUntil: string): Promise<IApiProtocolOptionFixUntilResponse>;
    patchProtocolResendDocuments(protocolId: string, resendDocuments: boolean): Promise<IApiProtocolOptionResendDocumentsResponse>;
    patchProtocolSafetyChecklistTemplate(protocolId: string, sourceProjectId: string): Promise<IApiSafetyChecklistTopicResponse[]>;
    patchProtocolSafetyChecklist(protocolId: string, topicId: string, entryId: string, value: boolean | null): Promise<IApiProtocolOptionSafetyChecklistResponse>;
    patchProtocolDeficitResponsibility(protocolId: string, deficitId: string, responsible: IApiContactResponse | null): Promise<IApiProtocolOptionDeficitResponsibilityResponse>;
    patchProtocolDeficitClosed(protocolId: string, deficitId: string, closed: boolean): Promise<IApiProtocolOptionDeficitClosedResponse>;
    patchProtocolDeficitTitle(protocolId: string, deficitId: string, title: string): Promise<IApiProtocolOptionDeficitTitleResponse>;
    patchProtocolDeficitEntryText(protocolId: string, deficitId: string, entryId: string, text: string): Promise<IApiProtocolOptionDeficitEntryTextResponse>;
    patchProtocolDeficitEntrySave(protocolId: string, deficitId: string, entryId: string, save: boolean): Promise<IApiProtocolOptionDeficitEntrySaveResponse>;

    deleteProtocolDeficit(protocolId: string, deficitId: string): Promise<IApiProtocolResponseDeficit[]>;
    deleteProtocolDeficitEntry(protocolId: string, deficitId: string, entryId: string): Promise<IApiProtocolResponseDeficitEntry[]>;
    deleteProtocolDeficitEntryAttachment(protocolId: string, deficitId: string, entryId: string, attachmentId: string): Promise<IApiProtocolResponseDeficitEntryAttachment[]>;

    getSuggestions(protocolId: string, deficitId: string, entryId: string, type: string, text?: string): Promise<IApiSuggestionResponse[]>;
}

export const ApiContext = createContext({} as IApiContext);

export const ApiProvider = (props: { 
    baseUri: string,
    children: ReactElement<any, string | JSXElementConstructor<any>>
}) => {

    const { getAccessTokenSilently } = useContext(AuthContext);

    const queryDebounceInterval = 500;
    const contactQueryTimeout = useRef<any>();
    const deficitQueryTimeout = useRef<any>();
    const suggestionsQueryTimeout = useRef<any>();

    async function httpGet<T>(path: string) : Promise<T> {
        var token = await getAccessTokenSilently();
        var result = await fetch(`${props.baseUri}/${path}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            method: 'GET'
        });

        //console.log(token)

        if(result.ok) {
            return result.json();   
        } else {
            throw result.status;
        }      
    }

    async function httpPost<T1, T2>(path: string, payload?: T1) : Promise<T2> {
        var token = await getAccessTokenSilently();
        var result = await fetch(`${props.baseUri}/${path}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(payload)
        });

        if(result.ok) {
            return result.json();   
        } else {
            throw result.status;
        }
    }

    async function httpPatch<T1, T2>(path: string, payload?: T1) : Promise<T2> {
        var token = await getAccessTokenSilently();
        var result = await fetch(`${props.baseUri}/${path}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            method: 'PATCH',
            body: JSON.stringify(payload)
        });

        if(result.ok) {
            return result.json();   
        } else {
            throw result.status;
        }
    }

    async function httpDelete<T1, T2>(path: string) : Promise<T2> {
        var token = await getAccessTokenSilently();
        var result = await fetch(`${props.baseUri}/${path}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            method: 'DELETE'
        });

        if(result.ok) {
            return result.json();   
        } else {
            throw result.status;
        }
    }

    function getMe(): Promise<IApiMeResponse> { 
        return httpGet(`me`);
    }

    function getMePwdTicket(): Promise<IApiMePwdTicketResponse> {
        return httpGet(`me/pwdticket`);
    }

    function patchMe(data: IApiMeEditRequest): Promise<IApiMeResponse> {
        return httpPatch(`me`, data);
    }

    function patchOrganization(id: string, data: IApiOrganizationEditRequest): Promise<IApiOrganizationResponse> {
        return httpPatch(`organizations/${id}`, data);
    }

    function getProject(id: string): Promise<IApiProjectSummaryResponse> { 
        return httpGet(`projects/${id}`);
    }

    function getAllProjects(): Promise<IApiProjectSummaryResponse[]> { 
        return httpGet(`projects`);
    }

    function postProject(data: IApiProjectCreateRequest): Promise<IApiProjectSummaryResponse> { 
        return httpPost(`projects`, data);
    }

    function patchProject(id: string, data: IApiProjectEditRequest): Promise<IApiProjectSummaryResponse> { 
        return httpPatch(`projects/${id}`, data);
    }

    function getProjectCoreData(id: string): Promise<IApiProjectOptionCoreData> { 
        return httpGet(`projects/${id}/coredata`);
    }

    function getProjectParties(id: string): Promise<IApiProjectOptionParty[]> { 
        return httpGet(`projects/${id}/parties`);
    }

    function getProjectObservers(id: string): Promise<IApiProjectOptionObserver[]> { 
        return httpGet(`projects/${id}/observers`);
    }

    function getProjectSafetyChecklist(id: string): Promise<IApiProjectOptionSafetyChecklist> { 
        return httpGet(`projects/${id}/safetychecklist`);
    }

    function getProjectContacts(id: string): Promise<IApiProjectContactResponse[]> {
        return httpGet(`projects/${id}/contacts`);
    }

    function patchProjectCoreData(id: string, data: IApiProjectOptionCoreData): Promise<IApiProjectOptionCoreData> { 
        return httpPatch(`projects/${id}/coredata`, data);
    }

    function patchProjectParties(id: string, data: IApiProjectOptionParty[]): Promise<IApiProjectOptionParty[]> { 
        return httpPatch(`projects/${id}/parties`, data);
    }

    function patchProjectObservers(id: string, data: IApiProjectOptionObserver[]): Promise<IApiProjectOptionObserver[]> { 
        return httpPatch(`projects/${id}/observers`, data);
    }

    function patchProjectSafetyChecklist(id: string, data: IApiProjectOptionSafetyChecklist): Promise<IApiProjectOptionSafetyChecklist> { 
        return httpPatch(`projects/${id}/safetychecklist`, data);
    }

    function getAllUsers(): Promise<IApiUserResponse[]> { 
        return httpGet(`users`);
    }

    function postUser(data: IApiUserCreateRequest): Promise<IApiUserResponse> { 
        return httpPost(`users`, data);
    }

    function patchUser(id: string, data: IApiUserEditRequest): Promise<IApiUserResponse> { 
        return httpPatch(`users/${id}`, data);
    }

    function deleteUser(id: string): Promise<IApiUserResponse> {
        return httpDelete(`users/${id}`);
    }

    function getAllRoles(): Promise<IApiRoleResponse[]> { 
        return httpGet(`roles`);
    }

    function getAllTrades(): Promise<IApiTradeResponse[]> { 
        return httpGet(`trades`);
    }

    function postTrade(data: IApiTradeCreateRequest): Promise<IApiTradeResponse> { 
        return httpPost(`trades`, data);
    }

    function patchTrade(id: string, data: IApiTradeEditRequest): Promise<IApiTradeResponse> { 
        return httpPatch(`trades/${id}`, data);
    }

    function deleteTrade(id: string): Promise<IApiTradeResponse> { 
        return httpDelete(`trades/${id}`);
    }

    function getFilteredDeficits(filter: string): Promise<IApiDeficitResponse[]> {

        //Clear the previous timeout.
        clearTimeout(deficitQueryTimeout.current)

        // If there is no search term, do not make API call
        if (!filter || !filter.trim() || filter.length < 4) {
            return new Promise((resolve, reject) => resolve([]));
        }

        return new Promise((resolve, reject) => {
            deficitQueryTimeout.current = setTimeout(() => {
                httpGet<IApiDeficitResponse[]>(`deficits?filter=${filter}`).then(result => resolve(result), error => reject(error));  
            }, queryDebounceInterval);
        });

        // return httpGet(`deficits?filter=${filter}`);
    }

    function getPagedDeficits(page: number): Promise<IApiDeficitResponse[]> {
        return  httpGet(`deficits?page=${page}`)
    }

    function getDeficitsMetadata(): Promise<IApiDeficitsMetadataResponse> {
        return  httpGet(`deficits`)
    }

    function postDeficit(data: IApiDeficitCreateRequest): Promise<IApiDeficitResponse> { 
        return httpPost(`deficits`, data);
    }

    function patchDeficit(id: string, data: IApiDeficitEditRequest): Promise<IApiDeficitResponse> { 
        return httpPatch(`deficits/${id}`, data);
    }

    function deleteDeficit(id: string): Promise<IApiDeficitResponse> { 
        return httpDelete(`deficits/${id}`);
    }

    
    function getFilteredContacts(filter: string): Promise<IApiContactResponse[]> {
        
        //Clear the previous timeout.
        clearTimeout(contactQueryTimeout.current)

        // If there is no search term, do not make API call
        if (!filter || !filter.trim() || filter.length < 4) {
            return new Promise((resolve, reject) => resolve([]));
        }

        return new Promise((resolve, reject) => {
            contactQueryTimeout.current = setTimeout(() => {
                httpGet<IApiContactResponse[]>(`contacts?filter=${filter}`).then(result => resolve(result), error => reject(error));  
            }, queryDebounceInterval);
        });

        // return httpGet(`deficits?filter=${filter}`);
    }

    function getPagedContacts(page: number): Promise<IApiContactResponse[]> {
        return  httpGet(`contacts?page=${page}`)
    }

    function getContactsMetadata(): Promise<IApiContactsMetadataResponse> {
        return  httpGet(`contacts`)
    }

    function postContact(data: IApiContactCreateRequest): Promise<IApiContactResponse> { 
        return httpPost(`contacts`, data);
    }

    function patchContact(id: string, data: IApiContactEditRequest): Promise<IApiContactResponse> { 
        return httpPatch(`contacts/${id}`, data);
    }

    function deleteContact(id: string): Promise<IApiContactResponse> { 
        return httpDelete(`contacts/${id}`);
    }


    function getAllChecklists(): Promise<IApiSafetyChecklistResponse[]> { 
        return httpGet(`checklists`);
    }
    
    function postSafetyChecklist(data: IApiSafetyChecklistCreateRequest): Promise<IApiSafetyChecklistResponse> { 
        return httpPost(`checklists`, data);
    }
    
    function patchSafetyChecklist(id: string, data: IApiSafetyChecklistEditRequest): Promise<IApiSafetyChecklistResponse> { 
        return httpPatch(`checklists/${id}`, data);
    }
    
    function deleteSafetyChecklist(id: string): Promise<IApiSafetyChecklistResponse> { 
        return httpDelete(`checklists/${id}`);
    }

    function getFile(folderName: string, fileId: string): Promise<void> { 
        return getAccessTokenSilently()
            .then(token => fetch(`${props.baseUri}/files/${folderName}/${fileId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'GET'
            }))
            .then(result => {
                if (!result.ok) {
                    throw Error(result.statusText);
                }
                return result.json();
            })
            .then((blobInfo: IApiFileListResponse) => {
                if(blobInfo.url) {
                    window.open(blobInfo.url, '_blank');
                }
            });

        /*
        var filename = "";
        return getAccessTokenSilently()
            .then(token => fetch(`${props.baseUri}/files/${folderName}/${fileId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'GET'
            }))
            // https://stackoverflow.com/a/42274086
            .then(result => {
                if (!result.ok) {
                    throw Error(result.statusText);
                }
        
                const header = result.headers.get('Content-Disposition');
                const parts = header!.split(';');
                filename = parts[1].split('=')[1].replaceAll("\"", "");
        
                return result.blob();
            })
            .then(blob => {
                if (blob != null) {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }  
            });
        */
    }

    function getAllFiles(folderName: string, project?: string): Promise<IApiFileListResponse[]> { 
        return httpGet(`files/${folderName}${project ? `?project=${project}` : ``}`);
    }

    function postFile(folderName: string, fileName: string, projectId: string, contentType: string, parent?: string): Promise<IApiFileUploadResponse> { 
        return httpPost(`files/${folderName}/${fileName}`, {
            project: projectId,
            contentType,
            parent
        });
    }

    function patchFileInfo(folderName: string, fileId: string, data: IApiFileInfoEditRequest): Promise<IApiFileInfoEditResponse> { 
        return httpPatch(`files/${folderName}/${fileId}/info`, data);
    }

    function patchFileData(folderName: string, fileId: string): Promise<IApiFileDataEditResponse> { 
        return httpPatch(`files/${folderName}/${fileId}/data`);
    }

    function patchFileRestore(folderName: string, fileId: string): Promise<IApiFileRestoreEditResponse> { 
        return httpPatch(`files/${folderName}/${fileId}/restore`);
    }

    function deleteFile(folderName: string, fileId: string): Promise<IApiFileListResponse> { 
        return httpDelete(`files/${folderName}/${fileId}`);
    }

    function postProjectProtocol(projectId: string): Promise<IApiProtocolResponse> {
        return httpPost(`projects/${projectId}/protocols`);
    }

    function getProtocol(protocolId: string): Promise<IApiProtocolResponse> {
        return httpGet(`protocols/${protocolId}`);
    }

    function deleteProtocol(protocolId: string): Promise<IApiProtocolSummaryResponse[]> {
        return httpDelete(`protocols/${protocolId}`);
    }

    function submitProtocol(protocolId: string, preview: boolean = true): Promise<IApiProtocolSubmissionResponse> {
        return httpPost(`protocols/${protocolId}?preview=${preview}`);
    }

    function postProtocolDeficit(protocolId: string): Promise<IApiProtocolResponseDeficit> {
        return httpPost(`protocols/${protocolId}/deficits`);
    }

    function postProtocolDeficitEntry(protocolId: string, deficitId: string): Promise<IApiProtocolResponseDeficitEntry> {
        return httpPost(`protocols/${protocolId}/deficits/${deficitId}/entries`);
    }

    function postProtocolDeficitEntryAttachment(protocolId: string, deficitId: string, entryId: string, folderName: string, fileName: string, blobId: string): Promise<IApiProtocolResponseDeficitEntryAttachment> {
        return httpPost(`protocols/${protocolId}/deficits/${deficitId}/entries/${entryId}/attachments`, {
            folderName,
            fileName,
            blobId
        });
    }
    
    function patchProtocolDate(protocolId: string, date: string): Promise<IApiProtocolOptionDateResponse> {
        return httpPatch(`protocols/${protocolId}`, {
            date
        });
    }
    
    function patchProtocolTime(protocolId: string, time: string): Promise<IApiProtocolOptionTimeResponse> {
        return httpPatch(`protocols/${protocolId}`, {
            time
        });
    }
    
    function patchProtocolImminentDanger(protocolId: string, imminentDanger: boolean): Promise<IApiProtocolOptionImminentDangerResponse> {
        return httpPatch(`protocols/${protocolId}`, {
            imminentDanger
        });
    }
    
    function patchProtocolFixUntil(protocolId: string, fixUntil: string): Promise<IApiProtocolOptionFixUntilResponse> {
        return httpPatch(`protocols/${protocolId}`, {
            fixUntil
        });
    }
    
    function patchProtocolResendDocuments(protocolId: string, resendDocuments: boolean): Promise<IApiProtocolOptionResendDocumentsResponse> {
        return httpPatch(`protocols/${protocolId}`, {
            resendDocuments
        });
    }
    
    function patchProtocolSafetyChecklistTemplate(protocolId: string, sourceProjectId: string): Promise<IApiSafetyChecklistTopicResponse[]> {
        return httpPatch(`protocols/${protocolId}`, {
            safetyChecklist: sourceProjectId
        });
    }

    function patchProtocolSafetyChecklist(protocolId: string, topicId: string, entryId: string, value: boolean | null): Promise<IApiProtocolOptionSafetyChecklistResponse> {
        return httpPatch(`protocols/${protocolId}/safetychecklist/topics/${topicId}/entries/${entryId}`, {
            value
        });
    }
    
    function patchProtocolDeficitResponsibility(protocolId: string, deficitId: string, responsible: IApiContactResponse | null): Promise<IApiProtocolOptionDeficitResponsibilityResponse> {
        return httpPatch(`protocols/${protocolId}/deficits/${deficitId}`, {
            responsible
        });
    }
    
    function patchProtocolDeficitClosed(protocolId: string, deficitId: string, closed: boolean): Promise<IApiProtocolOptionDeficitClosedResponse> {
        return httpPatch(`protocols/${protocolId}/deficits/${deficitId}`, {
            closed
        });
    }

    function patchProtocolDeficitTitle(protocolId: string, deficitId: string, title: string): Promise<IApiProtocolOptionDeficitTitleResponse> {
        return httpPatch(`protocols/${protocolId}/deficits/${deficitId}`, {
            title
        });
    }
    
    function patchProtocolDeficitEntryText(protocolId: string, deficitId: string, entryId: string, text: string): Promise<IApiProtocolOptionDeficitEntryTextResponse> {
        return httpPatch(`protocols/${protocolId}/deficits/${deficitId}/entries/${entryId}`, {
            text
        });
    }
    
    function patchProtocolDeficitEntrySave(protocolId: string, deficitId: string, entryId: string, save: boolean): Promise<IApiProtocolOptionDeficitEntrySaveResponse> {
        return httpPatch(`protocols/${protocolId}/deficits/${deficitId}/entries/${entryId}`, {
            save
        });
    }

    function deleteProtocolDeficit(protocolId: string, deficitId: string): Promise<IApiProtocolResponseDeficit[]> {
        return httpDelete(`protocols/${protocolId}/deficits/${deficitId}`);
    }
    
    function deleteProtocolDeficitEntry(protocolId: string, deficitId: string, entryId: string): Promise<IApiProtocolResponseDeficitEntry[]> {
        return httpDelete(`protocols/${protocolId}/deficits/${deficitId}/entries/${entryId}`);
    }

    function deleteProtocolDeficitEntryAttachment(protocolId: string, deficitId: string, entryId: string, attachmentId: string): Promise<IApiProtocolResponseDeficitEntryAttachment[]> {
        return httpDelete(`protocols/${protocolId}/deficits/${deficitId}/entries/${entryId}/attachments/${attachmentId}`);
    }

    function getSuggestions(protocolId: string, deficitId: string, entryId: string, type: string, text: string = ""): Promise<IApiSuggestionResponse[]> {

        //Clear the previous timeout.
        clearTimeout(suggestionsQueryTimeout.current)

        return new Promise((resolve, reject) => {
            suggestionsQueryTimeout.current = setTimeout(() => {
                httpGet<IApiSuggestionResponse[]>(`suggestions/${protocolId}/${deficitId}/${entryId}?type=${type}&text=${text}`).then(result => resolve(result), error => reject(error));  
            }, queryDebounceInterval);
        });
    }
    
    return (
        <ApiContext.Provider
            value={{
                getMe,
                getMePwdTicket,
                patchMe,
                patchOrganization,
                getProject,
                getAllProjects,
                postProject,
                patchProject,

                getProjectCoreData,
                getProjectParties,
                getProjectObservers,
                getProjectSafetyChecklist,
                getProjectContacts,
                patchProjectCoreData,
                patchProjectParties,
                patchProjectObservers,
                patchProjectSafetyChecklist,

                getAllUsers,
                getAllRoles,
                postUser,
                patchUser,
                deleteUser,
                getAllTrades,
                postTrade,
                patchTrade,
                deleteTrade,
                getFilteredDeficits,
                getPagedDeficits,
                getDeficitsMetadata,
                postDeficit,
                patchDeficit,
                deleteDeficit,
                getFilteredContacts,
                getPagedContacts,
                getContactsMetadata,
                postContact,
                patchContact,
                deleteContact,
                getAllChecklists,
                postSafetyChecklist,
                patchSafetyChecklist,
                deleteSafetyChecklist,

                getFile,
                getAllFiles,
                postFile,
                patchFileInfo,
                patchFileData,
                patchFileRestore,
                deleteFile,

                postProjectProtocol,
                getProtocol,
                deleteProtocol,
                submitProtocol,

                postProtocolDeficit,
                postProtocolDeficitEntry,
                postProtocolDeficitEntryAttachment,
                patchProtocolDate,
                patchProtocolTime,
                patchProtocolImminentDanger,
                patchProtocolFixUntil,
                patchProtocolResendDocuments,
                patchProtocolSafetyChecklistTemplate,
                patchProtocolSafetyChecklist,

                patchProtocolDeficitResponsibility,
                patchProtocolDeficitClosed,
                patchProtocolDeficitTitle,

                patchProtocolDeficitEntryText,
                patchProtocolDeficitEntrySave,
                
                deleteProtocolDeficit,
                deleteProtocolDeficitEntry,
                deleteProtocolDeficitEntryAttachment,

                getSuggestions
            }}
        >
            { props.children }
        </ApiContext.Provider>
    );
};