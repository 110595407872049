import React, { useState, useContext, useEffect } from "react";
import { Badge, Select, Box, Button, Skeleton, Checkbox, Divider, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus } from "react-icons/fi";
import { useNavigate  } from "react-router-dom";
import { AppContext } from "../../App";
import { ISettingsProps } from "./Settings";
import { IApiSafetyChecklistResponse } from "../../models/api/safetychecklist/IApiSafetyChecklistResponse";
import { ApiContext } from "../../contexts/ApiProvider";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import toast from "react-hot-toast";
import { AddChecklistDrawer } from "../../components/drawers/checklist/AddChecklistDrawer";
import { EditChecklistDrawer } from "../../components/drawers/checklist/EditChecklistDrawer";
import { IApiSafetyChecklistEditRequest } from "../../models/api/safetychecklist/IApiSafetyChecklistEditRequest";

interface IChecklistsProps extends ISettingsProps {

}

export const Checklists: React.FunctionComponent<IChecklistsProps> = (props: IChecklistsProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { me, setAppShellConfig, setDCM } = useContext(AppContext);
    const { getAllChecklists, postSafetyChecklist, patchSafetyChecklist, deleteSafetyChecklist } = useContext(ApiContext);

    const [checklists, setChecklists] = useState<IApiSafetyChecklistResponse[]>([]);
    const [filter, setFilter] = useState("");

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState<IApiSafetyChecklistResponse | undefined>(undefined);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Checklisten`
            },
            subHeading: {
                text: `Einstellungen`
            },
            rightButton: {
                text: "Erstellen",
                icon: FiPlus,
                ariaLabel: "erstellen",
                variant: "primary",
                onClick: () => {
                    setAdd(true);
                }
            }
        });
        setLoading(true);
        getAllChecklists().then(checklists => {
            setChecklists(checklists);
            setLoading(false);
        });
    }, [setAppShellConfig, me, navigate, setAdd]);

    const deleteAction = (checklist: IApiSafetyChecklistResponse) => {
        setDCM({
            title: `Checkliste löschen`,
            body: `Sind Sie sicher, dass die Checkliste '${checklist.name}' gelöscht werden soll?`,
            onConfirm: () => {
                var promise = deleteSafetyChecklist(checklist.id);
                toast.promise(promise, {
                    loading: `Checkliste wird gelöscht...`,
                    success: (result) => {
                        setChecklists(old => {
                            var checklists = [...old];
                            var index = checklists.findIndex(t => t.id === result.id);
                            checklists.splice(index, 1);
                            return checklists;
                        });
                        return `Die Checkliste '${result.name}' wurde erfolgreich gelöscht!`;
                    },
                    error: (code) => {
                        var message = `Fehler beim Löschen der Checkliste! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                });
                return promise;
            }
        });
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            <Stack spacing="4" width="full" direction="row" px="2" justifyContent="space-between" align="center">
                <InputGroup maxW={{ base: "75%", lg: undefined }}>
                    <InputLeftElement pointerEvents="none">
                        <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input placeholder="Filtern..." onChange={e => setFilter(e.target.value.toLocaleLowerCase())} boxShadow={boxShadow} />
                </InputGroup>
            </Stack>
            <ResponsiveTable 
                data={checklists
                    .filter(p => p.name.toLowerCase().indexOf(filter) >= 0 || p.description.toLowerCase().indexOf(filter) >= 0)}
                loading={loading}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('name', {
                        header: () => 'Name',
                        cell: info => info.getValue(),
                    }),
                    columnHelper.accessor('description', {
                        header: () => 'Beschreibung',
                        cell: info => info.getValue(),
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const checklist = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">
                                    <IconButton
                                        icon={<FiEdit2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Bearbeiten"
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            setEdit(checklist);
                                        }}
                                    />
                                    <IconButton
                                        icon={<FiTrash2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Löschen"
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            deleteAction(checklist);
                                        }}
                                    />                           
                                </HStack>
                            );
                        },
                    }),
                ]}
                getCard={(checklist) => ({
                    key: checklist.id,
                    value: checklist.id,
                    content: (
                        <HStack spacing="4" justifyContent="space-between">
                            <VStack spacing="2" align="left">
                                <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                    {checklist.name}
                                </Text>
                            </VStack>
                            <HStack spacing="4" justifyContent="end">
                                <IconButton
                                    icon={<FiEdit2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Bearbeiten"
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        setEdit(checklist);
                                    }}
                                />
                                <IconButton
                                    icon={<FiTrash2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Löschen"
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        deleteAction(checklist);
                                    }}
                                />
                            </HStack>
                        </HStack>
                    )
                })}
            />
            <AddChecklistDrawer 
                isOpen={add} 
                onDiscard={() => setAdd(false)}
                onSubmit={(data) => {
                    var promise = postSafetyChecklist(data);
                    toast.promise(promise, {
                      loading: `Checkliste wird erstellt...`,
                      success: (result) => {
                        setAdd(false);
                        setChecklists(old => ([...old, result]));
                        return `Die Checkliste '${result.name}' wurde erfolgreich erstellt!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Erstellen der Checkliste!`;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
            />
            <EditChecklistDrawer 
                defaultValue={edit as IApiSafetyChecklistEditRequest} 
                onDiscard={() => setEdit(undefined)} 
                onSubmit={(data) => {
                    var promise = patchSafetyChecklist(edit!.id, data);
                    toast.promise(promise, {
                      loading: `Checkliste wird aktualisiert...`,
                      success: (result) => {
                        setEdit(undefined);
                        setChecklists(old => {
                            var users = [...old];
                            var index = checklists.findIndex(t => t.id === result.id);
                            checklists.splice(index, 1, result);
                            return checklists;
                        });
                        return `Die Checkliste '${result.name}' wurde erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Aktualisieren der Checkliste!`;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 404: {
                                message += `Fehler 404`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
            />
        </Stack>
    );
}