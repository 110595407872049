import { useColorModeValue as mode, Input, Divider, Stack, HStack, InputGroup, IconButton, Button, useBreakpointValue, InputLeftElement, Icon, Skeleton, List, ListItem } from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
  } from '@chakra-ui/react'
import { Field, FormikProps } from 'formik';
import { prependOnceListener } from 'process';
import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import { ApiContext } from '../../../contexts/ApiProvider';
import Helpers from '../../../Helpers';

import { IApiContactEditRequest } from '../../../models/api/contact/IApiContactEditRequest';
import { IApiContactResponse } from '../../../models/api/contact/IApiContactResponse';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { SelectFormControl } from '../../formcontrols/SelectFormControl';
import { TextAreaFormControl } from '../../formcontrols/TextAreaFormControl';
import { IBaseDrawerProps } from '../BaseDrawer';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';
import { ContactSearchResult } from '../../ContactSearch/ContactSearchResult';
import { ContactSearch } from '../../ContactSearch/ContactSearch';

export interface IContactSearchDrawerConfig {
    onSubmit?(data: IApiContactResponse): void;
}

export interface IContactSearchDrawerProps extends IContactSearchDrawerConfig {
    headerText?: string;
    discardText?: string;
    clear(): void;
}

export const ContactSearchDrawer = ({ onSubmit, headerText, discardText, clear }: IContactSearchDrawerProps) => {
    const isPhone = useBreakpointValue({ base: true, md: false });
    const boxShadow = mode('sm', 'sm-dark');

    const { getFilteredContacts } = useContext(ApiContext);

    const [contacts, setContacts] = useState<IApiContactResponse[]>([]);
    const searchRef = useRef<any>();
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        setContacts([]);
    }, [setContacts, onSubmit]);

    return (
        <Drawer
            isOpen={onSubmit !== undefined}
            placement='right'
            onClose={clear}
            size={isPhone ? 'full' : 'md'}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <DrawerOverlay />
            <DrawerContent h="full">
                <DrawerHeader>
                    <HStack justifyContent="space-between">
                        <span>
                            {headerText ?? "Kontakt suchen"}
                        </span>
                        {/*
                        <IconButton
                            icon={<FiX fontSize="1.25rem" />}
                            variant="ghost"
                            aria-label="Discard"
                            onClick={clear}
                        />
                        */}
                    </HStack>
                </DrawerHeader>
                <Divider />
                <DrawerBody px="4">
                    <ContactSearch onSelect={(c) => onSubmit && onSubmit(c)} />
                </DrawerBody>
                <DrawerFooter px="4">
                    <Button disabled={searching} variant='secondary' onClick={clear} w="full">
                        {discardText ?? "Abbrechen"}
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}