import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, PinInputField, PinInput, TableCaption, TableContainer, Tfoot, Checkbox, Switch } from '@chakra-ui/react'
import * as React from 'react'
import { useContext, useState } from 'react';
import { FiCheck, FiEye } from 'react-icons/fi';
import { ProtocolContext } from './ProtocolProvider';
import { RadioButton, RadioButtonGroup } from '../../RadioButtonGroup/RadioButtonGroup';
import { IApiProtocolSubmissionResponse } from '../../../models/api/protocol/IApiProtocolSubmissionResponse';

export interface IProtocolDrawerSummaryProps {
    readOnly?: boolean;
    onSubmit(result: IApiProtocolSubmissionResponse): void;
}

const protocolDateToDisplayDate = (protocolDate: string): string => {
    var parts = protocolDate.split('-');
    return `${parts[2]}.${parts[1]}.${parts[0]}`;
}

export const ProtocolDrawerSummary = (props: IProtocolDrawerSummaryProps) => {

    const { protocol, safetyChecklistStatistics, setImminentDanger, setFixUntil, setResendDocuments, onSubmit, onPreview } = useContext(ProtocolContext);

    const [submitEnabled, setSubmitEnabled] = useState(false);

    return (
        <VStack spacing={4} px={2} py={4} display="block" overflow="auto">
            <VStack w="full" px={2}>
                <Text style={{ 
                        fontFamily: "var(--chakra-fonts-heading)", 
                        fontWeight: "var(--chakra-fontWeights-medium)",
                        fontSize: "var(--chakra-fontSizes-sm)"
                    }}>
                    Begehungsprotokoll vom</Text>
                <Text><b>{protocolDateToDisplayDate(protocol.date)} ({protocol.time} Uhr)</b></Text>
            </VStack>
            <Divider />
            <TableContainer w="100%" px={2}>
                <Table variant='simple' size='sm' textAlign='center'>
                    <TableCaption placement='top' fontSize="sm" mt={0}>Sicherheitscheckliste</TableCaption>
                    <Thead>
                        <Tr>
                            <Th w="33%" textAlign='center'>nicht<br/>erfüllt</Th>
                            <Th w="33%" textAlign='center'>erfüllt</Th>
                            <Th w="33%" textAlign='center'>nicht<br/>zutreffend</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            {safetyChecklistStatistics.map((value, index) => (<Td key={`scs-${index}`} textAlign='center'><b>{value}</b></Td>))}
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <TableContainer w="100%" px={2}>
                <Table variant='simple' size='sm' textAlign='center'>
                    <TableCaption placement='top' fontSize="sm" mt={0}>Mängel</TableCaption>
                    <Thead>
                        <Tr>
                            <Th w="50%" textAlign='center'>offen</Th>
                            <Th w="50%" textAlign='center'>erledigt</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td textAlign='center'><b>{protocol.deficits.filter(d => !d.closed).length}</b></Td>
                            <Td textAlign='center'><b>{protocol.deficits.filter(d => d.closed).length}</b></Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <VStack width="full">
                <Text fontFamily="heading" fontSize="sm" color="gray.600" fontWeight="medium" px={4} py={2}>Gefahr im Verzug</Text>
                <RadioButtonGroup key={"danger"} defaultValue={`${protocol.imminentDanger}`} size={'md'} display="flex" width="full"
                                  onChange={(e: string) => setImminentDanger(e === "false" ? false : true)}
                >
                    <RadioButton value="false" _checked={{ bg: 'green.200' }} grow={true} disabled={props.readOnly ?? false} >nein</RadioButton>
                    <RadioButton value="true" _checked={{ bg: 'red.200' }} grow={true} disabled={props.readOnly ?? false} >ja</RadioButton>
                </RadioButtonGroup> 
            </VStack>
            <VStack width="full">
                <Text fontFamily="heading" fontSize="sm" color="gray.600" fontWeight="medium" px={4} py={2}>Mängel zu beseitigen bis</Text>
                <RadioButtonGroup key={"fixUntil"} defaultValue={protocol.fixUntil === "sofort" ? "sofort" : "date"} size={'md'} display="flex" width="full"
                                  onChange={(e: string) => e === "sofort" ? setFixUntil("sofort") : setFixUntil((new Date()).toISOString().substring(0, 10))}
                >
                    <RadioButton value="sofort" grow={true} disabled={props.readOnly ?? false}>Sofort</RadioButton>
                    <RadioButton value="date" grow={true} disabled={props.readOnly ?? false}>Datum</RadioButton>
                </RadioButtonGroup> 
                {(protocol.fixUntil !== "sofort") && (
                    <Input type="date" defaultValue={protocol.fixUntil === "sofort" ? "sofort" : protocol.fixUntil ?? (new Date()).toISOString().substring(0, 10)} textAlign="center" onChange={e => setFixUntil(e.currentTarget.valueAsDate!.toISOString().substring(0, 10))} disabled={props.readOnly ?? false} />
                )}
            </VStack>
            <VStack width="full">
                <Text fontFamily="heading" fontSize="sm" color="gray.600" fontWeight="medium" px={4} py={2}>BauKG Dokumente mitsenden</Text>
                <RadioButtonGroup key={"resend"} defaultValue={`${protocol.resendDocuments}`} size={'md'} display="flex" width="full"
                                  onChange={(e: string) => setResendDocuments(e === "false" ? false : true)}
                >
                    <RadioButton value="false" grow={true} disabled={props.readOnly ?? false}>nein</RadioButton>
                    <RadioButton value="true" grow={true} disabled={props.readOnly ?? false}>ja</RadioButton>
                </RadioButtonGroup> 
            </VStack>
            {!(props.readOnly ?? false) && (
                <>
                    <Divider />
                    <FormControl display='flex' alignItems='center' mt="6 !important">
                        <Switch id="enable-submit" size="lg" ml={2} mr={4} defaultChecked={submitEnabled} onChange={e => setSubmitEnabled(e.currentTarget.checked)} />
                        <FormLabel htmlFor='enable-submit' mb='0' fontSize="md">
                            Protokoll vollständig und bereit zur Veröffentlichung
                        </FormLabel>
                    </FormControl>
                    <HStack>
                        <Button variant="primary" mt="2 !important" px={2} size="lg" flexGrow={2} 
                            disabled={!submitEnabled}
                            leftIcon={<FiCheck />}
                            onClick={() => onSubmit().then(result => props.onSubmit(result))}
                            >
                                Protokoll abschließen
                        </Button>
                        <Button variant="outline" mt="2 !important" px={2} size="lg" flexGrow={1} 
                            aria-label="preview"
                            leftIcon={<FiEye />}
                            onClick={onPreview}
                            >
                                Vorschau
                        </Button>
                    </HStack>
                </>
            )}
        </VStack>
    );
}