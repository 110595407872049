import { createContext, JSXElementConstructor, ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { IApiProtocolResponse, IApiProtocolResponseDeficit, IApiProtocolResponseDeficitEntry, IApiProtocolResponseDeficitEntryAttachment } from "../../../models/api/protocol/IApiProtocolResponse";
import { IApiProjectOptionParty } from "../../../models/api/project/options/IApiProjectOptionParty";
import { IApiProjectOptionSafetyChecklist } from "../../../models/api/project/options/IApiProjectOptionSafetyChecklist";
import { IApiProjectSummaryResponse } from "../../../models/api/project/IApiProjectSummaryResponse";
import { ApiContext } from "../../../contexts/ApiProvider";
import toast from "react-hot-toast";
import { IApiContactResponse } from "../../../models/api/contact/IApiContactResponse";
import { StorageContext } from "../../../contexts/StorageProvider";
import { ProtocolDeficitEntryEditor } from "../../ProtocolDeficitEntryEditor/ProtocolDeficitEntryEditor";
import { IApiFileListResponse } from "../../../models/api/file/IApiFileListResponse";
import { IApiFileDataEditResponse } from "../../../models/api/file/IApiFileDataEditResponse";
import { IApiProtocolOptionDeficitTitleResponse } from "../../../models/api/protocol/options/IApiProtocolOptionDeficitTitleResponse";
import { IApiProtocolOptionDeficitClosedResponse } from "../../../models/api/protocol/options/IApiProtocolOptionDeficitClosedResponse";
import { IApiProtocolOptionDeficitResponsibilityResponse } from "../../../models/api/protocol/options/IApiProtocolOptionDeficitResponsibilityResponse";
import { IApiSafetyChecklistTopicResponse } from "../../../models/api/safetychecklist/IApiSafetyChecklistResponse";
import { IApiProtocolOptionSafetyChecklistResponse } from "../../../models/api/protocol/options/IApiProtocolOptionSafetyChecklistResponse";
import { IApiProtocolOptionFixUntilResponse } from "../../../models/api/protocol/options/IApiProtocolOptionFixUntilResponse";
import { IApiProtocolOptionResendDocumentsResponse } from "../../../models/api/protocol/options/IApiProtocolOptionResendDocumentsResponse";
import { IApiProtocolOptionImminentDangerResponse } from "../../../models/api/protocol/options/IApiProtocolOptionImminentDangerResponse";
import { IApiProtocolOptionDateResponse } from "../../../models/api/protocol/options/IApiProtocolOptionDateResponse";
import { IApiProtocolOptionTimeResponse } from "../../../models/api/protocol/options/IApiProtocolOptionTimeResponse";
import { IApiSuggestionResponse } from "../../../models/api/suggestion/IApiSuggestionResponse";
import { IApiProtocolSubmissionResponse } from "../../../models/api/protocol/IApiProtocolSubmissionResponse";

interface IProtocolContext {
    protocol: IApiProtocolResponse;
    parties: IApiProjectOptionParty[];
    safetyChecklist: IApiProjectOptionSafetyChecklist;
    safetyChecklistStatistics: number[];

    setDate(date: string): Promise<IApiProtocolOptionDateResponse>;
    setTime(time: string): Promise<IApiProtocolOptionTimeResponse>;
    setImminentDanger(imminentDanger: boolean): Promise<IApiProtocolOptionImminentDangerResponse>;
    setFixUntil(fixUntil: string): Promise<IApiProtocolOptionFixUntilResponse>;
    setResendDocuments(resendDocuments: boolean): Promise<IApiProtocolOptionResendDocumentsResponse>;

    setChecklistEntry(topicId: string, entryId: string, value: boolean | null): Promise<IApiProtocolOptionSafetyChecklistResponse>;
    refreshChecklist(): Promise<IApiSafetyChecklistTopicResponse[]>;


    addDeficit(): Promise<IApiProtocolResponseDeficit>;
    setDeficitResponsibility(deficitId: string, contact: IApiContactResponse | null): Promise<IApiProtocolOptionDeficitResponsibilityResponse>;
    setDeficitClosed(deficitId: string, closed: boolean): Promise<IApiProtocolOptionDeficitClosedResponse>;
    setDeficitTitle(deficitId: string, title: string): Promise<IApiProtocolOptionDeficitTitleResponse>;
    deleteDeficit(deficitId: string): Promise<IApiProtocolResponseDeficit[]>;

    addDeficitEntry(deficitId: string): Promise<IApiProtocolResponseDeficitEntry>;
    setDeficitEntryText(deficitId: string, entryId: string, text: string): void;
    setDeficitEntrySave(deficitId: string, entryId: string, save: boolean): void;
    deleteDeficitEntry(deficitId: string, entryId: string): Promise<IApiProtocolResponseDeficitEntry[]>;

    addDeficitEntryAttachment(deficitId: string, entryId: string, files: FileList): Promise<IApiProtocolResponseDeficitEntryAttachment[]>;
    updateDeficitEntryAttachment(deficitId: string, entryId: string, attachmentId: string, blobId: string, file: File): Promise<IApiFileDataEditResponse>;
    deleteDeficitEntryAttachment(deficitId: string, entryId: string, attachmentId: string): Promise<IApiProtocolResponseDeficitEntryAttachment[]>;
    restoreDeficitEntryAttachment(deficitId: string, entryId: string, attachmentId: string, blobId: string): Promise<IApiFileDataEditResponse>;

    getTextSuggestions(deficitId: string, entryId: string, text: string): Promise<IApiSuggestionResponse[]>;
    getImageSuggestions(deficitId: string, entryId: string): Promise<IApiSuggestionResponse[]>;

    onSubmit(): Promise<IApiProtocolSubmissionResponse>;
    onPreview(): Promise<IApiProtocolSubmissionResponse>;

    setOverlay: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}

export const ProtocolContext = createContext({} as IProtocolContext);

export const ProtocolProvider = (props: { 

    project: IApiProjectSummaryResponse;
    protocolId: string | undefined;

    children: (props: {
        protocol: IApiProtocolResponse,
        parties: IApiProjectOptionParty[],
        safetyChecklist: IApiProjectOptionSafetyChecklist,
        safetyChecklistStatistics: number[],
        overlay: React.ReactNode | undefined,
    }) => ReactElement<any, string | JSXElementConstructor<any>>;

    parties: IApiProjectOptionParty[];
    safetyChecklist: IApiProjectOptionSafetyChecklist;

}) => {

    const { project, protocolId } = props;
    const { 
        getProtocol, 
        submitProtocol,

        postProtocolDeficit,
        postProtocolDeficitEntry,
        postProtocolDeficitEntryAttachment,

        patchProtocolDate,
        patchProtocolTime, 
        patchProtocolImminentDanger, 
        patchProtocolFixUntil,
        patchProtocolResendDocuments,

        patchProtocolSafetyChecklistTemplate,
        patchProtocolSafetyChecklist,

        patchProtocolDeficitResponsibility,
        patchProtocolDeficitClosed,
        patchProtocolDeficitTitle,
        
        patchProtocolDeficitEntryText,
        patchProtocolDeficitEntrySave,

        deleteProtocolDeficit,
        deleteProtocolDeficitEntry,
        deleteProtocolDeficitEntryAttachment,

        getSuggestions

    } = useContext(ApiContext);
    
    const { uploadImages, updateImage, restoreImage } = useContext(StorageContext);

    const [protocol, setProtocol] = useState<IApiProtocolResponse | undefined>(undefined);
    const [overlay, setOverlay] = useState<React.ReactNode | undefined>(undefined);

    useEffect(() => {
        if(!protocolId && protocol) {
            setProtocol(undefined);
        } else if(protocolId && !protocol) {
            getProtocol(protocolId).then(protocol => setProtocol(protocol));
        }
    }, [protocolId, project]);

    const safetyChecklistStatistics = useMemo(() => {
        
        var value = [0, 0, 0];
        if(protocol && protocol.safetyChecklist) {
            protocol.safetyChecklist.forEach((topic, topicIndex) => {
                topic.entries.forEach((entry, entryIndex) => {
                    switch(protocol.safetyChecklist[topicIndex].entries[entryIndex].value) {
                        case false: { value[0]++; break; }
                        case true:  { value[1]++; break; }
                        case null:  { value[2]++; break; }
                    }
                });
            })
        }
        return value;

    }, [protocol]);

    
    if(!protocol) {
      return <></>
      //return <h1>Loading...</h1>
    }

    return (
        <ProtocolContext.Provider
            value={{
                protocol: protocol,
                parties: props.parties,
                safetyChecklist: props.safetyChecklist,
                safetyChecklistStatistics: safetyChecklistStatistics,
                setDate: (date: string) => {
                    var promise = patchProtocolDate(protocol.id, date);
                    toast.promise(promise, {
                      loading: `Datum wird aktualisiert...`,
                      success: (date) => {
                        setProtocol(oldProtocol => ({
                            ...oldProtocol!,
                            ...date
                        }));
                        return `Datum erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        return `Fehler beim Aktualisieren des Datums!`;
                      }
                    });
                    return promise
                },
                setTime: (time: string) => {
                    var promise = patchProtocolTime(protocol.id, time);
                    toast.promise(promise, {
                      loading: `Uhrzeit wird aktualisiert...`,
                      success: (time) => {
                        setProtocol(oldProtocol => ({
                            ...oldProtocol!,
                            ...time
                        }));
                        return `Uhrzeit erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        return `Fehler beim Aktualisieren der Uhrzeit!`;
                      }
                    });
                    return promise;
                },

                setImminentDanger: (imminentDanger: boolean) => {
                    var promise = patchProtocolImminentDanger(protocol.id, imminentDanger);
                    toast.promise(promise, {
                      loading: `Wird aktualisiert...`,
                      success: (imminentDanger) => {
                        setProtocol(oldProtocol => ({
                            ...oldProtocol!,
                            ...imminentDanger
                        }));
                        return `Erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        return `Fehler beim Aktualisieren!`;
                      }
                    });
                    return promise;
                },
                setFixUntil: (fixUntil: string) => {
                    var promise = patchProtocolFixUntil(protocol.id, fixUntil);
                    toast.promise(promise, {
                      loading: `Wird aktualisiert...`,
                      success: (fixUntil) => {
                        setProtocol(oldProtocol => ({
                            ...oldProtocol!,
                            ...fixUntil
                        }));
                        return `Erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        return `Fehler beim Aktualisieren!`;
                      }
                    });
                    return promise;
                },
                setResendDocuments: (resendDocuments: boolean) => {
                    var promise = patchProtocolResendDocuments(protocol.id, resendDocuments);
                    toast.promise(promise, {
                      loading: `Wird aktualisiert...`,
                      success: (resendDocuments) => {
                        setProtocol(oldProtocol => ({
                            ...oldProtocol!,
                            ...resendDocuments
                        }));
                        return `Erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        return `Fehler beim Aktualisieren!`;
                      }
                    });
                    return promise;
                },

                setChecklistEntry: (topicId: string, entryId: string, value: boolean | null) => {
                    var promise = patchProtocolSafetyChecklist(protocol.id, topicId, entryId, value);
                    toast.promise(promise, {
                      loading: `Eintrag wird aktualisiert...`,
                      success: (result) => {
                        setProtocol(oldProtocol => {
                            var newProtocol = { ...oldProtocol! };
                            var newChecklist = [...newProtocol!.safetyChecklist];
                            var topicIndex = newChecklist.findIndex(t => t.id === result.topicId);
                            if(topicIndex >= 0) {
                                var newTopic = {...newChecklist[topicIndex]};
                                var entryIndex = newTopic.entries.findIndex(e => e.id === result.entryId);
                                if(entryIndex >= 0) {
                                    var newEntry = {...newTopic.entries[entryIndex]};
                                    newEntry.value = result.value;
                                    newTopic.entries.splice(entryIndex, 1, newEntry);
                                    newChecklist.splice(topicIndex, 1, newTopic);
                                    newProtocol.safetyChecklist = newChecklist;
                                    return newProtocol;
                                }
                            }
                            return oldProtocol!;
                        });
                        return `Eintrag erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        return `Fehler beim Aktualisieren des Eintrags!`;
                      }
                    });
                    return promise;
                },
                refreshChecklist: () => {
                  var promise = patchProtocolSafetyChecklistTemplate(protocol.id, project.id);
                  toast.promise(promise, {
                    loading: `Checkliste wird aktualisiert...`,
                    success: (result) => {
                      setProtocol(oldProtocol => {
                          var newProtocol = { ...oldProtocol! };
                          newProtocol.safetyChecklist = result;
                          return newProtocol;
                      });
                      return `Checkliste erfolgreich aktualisiert!`;
                    },
                    error: (code) => {
                      return `Fehler beim Aktualisieren der Checkliste!`;
                    }
                  });
                  return promise;
                },

                setDeficitResponsibility: (deficitId: string, contact: IApiContactResponse | null) => {
                    var promise = patchProtocolDeficitResponsibility(protocol.id, deficitId, contact);
                    toast.promise(promise, {
                        loading: `Wird aktualisiert...`,
                        success: (result) => {
                          setProtocol(oldProtocol => {
                            var newProtocol = { ...oldProtocol! };
                            var newDeficits = [...newProtocol!.deficits];
                            var deficitIndex = newDeficits.findIndex(d => d.id === result.deficitId);
                            if(deficitIndex >= 0) {
                                var newDeficit = {...newDeficits[deficitIndex]};
                                newDeficit.responsible = result.responsible;
                                newDeficits.splice(deficitIndex, 1, newDeficit);
                                newProtocol.deficits = newDeficits;
                                return newProtocol;
                            }
                            return oldProtocol!;
                          });
                          return `Erfolgreich aktualisiert!`;
                        },
                        error: (code) => {
                          return `Fehler beim Aktualisieren!`;
                        }
                    });
                    return promise;
                },
                setDeficitClosed: (deficitId: string, closed: boolean) => {
                    var promise = patchProtocolDeficitClosed(protocol.id, deficitId, closed);
                    toast.promise(promise, {
                        loading: `Wird aktualisiert...`,
                        success: (result) => {
                          setProtocol(oldProtocol => {
                            var newProtocol = { ...oldProtocol! };
                            var newDeficits = [...newProtocol!.deficits];
                            var deficitIndex = newDeficits.findIndex(d => d.id === result.deficitId);
                            if(deficitIndex >= 0) {
                                var newDeficit = {...newDeficits[deficitIndex]};
                                newDeficit.closed = result.closed;
                                newDeficits.splice(deficitIndex, 1, newDeficit);
                                newProtocol.deficits = newDeficits;
                                return newProtocol;
                            }
                            return oldProtocol!;
                          });
                          return `Erfolgreich aktualisiert!`;
                        },
                        error: (code) => {
                          return `Fehler beim Aktualisieren!`;
                        }
                    });
                    return promise;
                },
                setDeficitTitle: (deficitId: string, title: string) => {
                    var promise = patchProtocolDeficitTitle(protocol.id, deficitId, title);
                    toast.promise(promise, {
                        loading: `Wird aktualisiert...`,
                        success: (result) => {
                          setProtocol(oldProtocol => {
                            var newProtocol = { ...oldProtocol! };
                            var newDeficits = [...newProtocol!.deficits];
                            var deficitIndex = newDeficits.findIndex(d => d.id === result.deficitId);
                            if(deficitIndex >= 0) {
                                var newDeficit = {...newDeficits[deficitIndex]};
                                newDeficit.title = result.title;
                                newDeficits.splice(deficitIndex, 1, newDeficit);
                                newProtocol.deficits = newDeficits;
                                return newProtocol;
                            }
                            return oldProtocol!;
                          });
                          return `Erfolgreich aktualisiert!`;
                        },
                        error: (code) => {
                          return `Fehler beim Aktualisieren!`;
                        }
                    });
                    return promise;
                },

                setDeficitEntryText: (deficitId: string, entryId: string, text: string) => {
                  var promise = patchProtocolDeficitEntryText(protocol.id, deficitId, entryId, text);
                    toast.promise(promise, {
                        loading: `Wird aktualisiert...`,
                        success: (result) => {
                          setProtocol(oldProtocol => {
                            var newProtocol = { ...oldProtocol! };
                            var newDeficits = [...newProtocol!.deficits];
                            var deficitIndex = newDeficits.findIndex(d => d.id === result.deficitId);
                            if(deficitIndex >= 0) {
                                var newDeficit = {...newDeficits[deficitIndex]};
                                var entryIndex = newDeficit.entries.findIndex(e => e.id === entryId);
                                if(entryIndex >= 0) {
                                  var newEntry = {...newDeficit.entries[entryIndex]};
                                  newEntry.text = result.text;
                                  newDeficit.entries.splice(entryIndex, 1, newEntry);
                                  newDeficits.splice(deficitIndex, 1, newDeficit);
                                  newProtocol.deficits = newDeficits;
                                }                                
                                return newProtocol;
                            }
                            return oldProtocol!;
                          });
                          return `Erfolgreich aktualisiert!`;
                        },
                        error: (code) => {
                          return `Fehler beim Aktualisieren!`;
                        }
                    });
                    return promise;
                },
                setDeficitEntrySave: (deficitId: string, entryId: string, save: boolean) => {
                  var promise = patchProtocolDeficitEntrySave(protocol.id, deficitId, entryId, save);
                  toast.promise(promise, {
                      loading: `Wird aktualisiert...`,
                      success: (result) => {
                        setProtocol(oldProtocol => {
                          var newProtocol = { ...oldProtocol! };
                          var newDeficits = [...newProtocol!.deficits];
                          var deficitIndex = newDeficits.findIndex(d => d.id === result.deficitId);
                          if(deficitIndex >= 0) {
                              var newDeficit = {...newDeficits[deficitIndex]};
                              var entryIndex = newDeficit.entries.findIndex(e => e.id === entryId);
                              if(entryIndex >= 0) {
                                var newEntry = {...newDeficit.entries[entryIndex]};
                                newEntry.save = result.save;
                                newDeficit.entries.splice(entryIndex, 1, newEntry);
                                newDeficits.splice(deficitIndex, 1, newDeficit);
                                newProtocol.deficits = newDeficits;
                              }                                
                              return newProtocol;
                          }
                          return oldProtocol!;
                        });
                        return `Erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        return `Fehler beim Aktualisieren!`;
                      }
                  });
                  return promise;
                },

                addDeficit: () => {
                    var promise = postProtocolDeficit(protocol.id);
                    toast.promise(promise, {
                      loading: `Mangel wird hinzugefügt...`,
                      success: (deficit) => {
                        setProtocol(oldProtocol => ({
                            ...oldProtocol!,
                            deficits: [...oldProtocol!.deficits, deficit]
                        }));
                        return `Mangel erfolgreich hinzugefügt!`;
                      },
                      error: (code) => {
                        return `Fehler beim Hinzugefügen des Mangels!`;
                      }
                    });
                    return promise;
                },
                addDeficitEntry: (deficitId: string) => {
                    var promise = postProtocolDeficitEntry(protocol.id, deficitId);
                    toast.promise(promise, {
                      loading: `Eintrag wird hinzugefügt...`,
                      success: (entry) => {
                        setProtocol(oldProtocol => {
                          var newProtocol = { ...oldProtocol! };
                          var newDeficits = [...newProtocol!.deficits];
                          var deficitIndex = newDeficits.findIndex(d => d.id === deficitId);
                          if(deficitIndex >= 0) {
                              var newDeficit = {...newDeficits[deficitIndex]};
                              var newEntries = [...newDeficit.entries, entry];
                              newDeficit.entries = newEntries;
                              newDeficits.splice(deficitIndex, 1, newDeficit);
                              newProtocol.deficits = newDeficits;
                              return newProtocol;
                          }
                          return oldProtocol!;
                        });
                        setOverlay(<ProtocolDeficitEntryEditor deficitId={deficitId} entryId={entry.id} />);
                        return `Eintrag erfolgreich hinzugefügt!`;
                      },
                      error: (code) => {
                        return `Fehler beim Hinzugefügen des Eintrags!`;
                      }
                    });
                    return promise;
                },
                deleteDeficit: (deficitId: string) => {
                  var promise = deleteProtocolDeficit(protocol.id, deficitId);
                  toast.promise(promise, {
                    loading: `Mangel wird entfernt...`,
                    success: (deficits) => {
                      setProtocol(oldProtocol => {
                        var newProtocol = { ...oldProtocol! };
                        newProtocol.deficits = deficits;
                        return newProtocol;
                      });
                      return `Mangel erfolgreich entfernt!`;
                    },
                    error: (code) => {
                      return `Fehler beim Entfernen des Mangels!`;
                    }
                  });
                  return promise;
                },
                deleteDeficitEntry: (deficitId: string, entryId: string) => {
                  var promise = deleteProtocolDeficitEntry(protocol.id, deficitId, entryId);
                  toast.promise(promise, {
                    loading: `Eintrag wird entfernt...`,
                    success: (entries) => {
                      setProtocol(oldProtocol => {
                        var newProtocol = { ...oldProtocol! };
                        var newDeficits = [...newProtocol!.deficits];
                        var deficitIndex = newDeficits.findIndex(d => d.id === deficitId);
                        if(deficitIndex >= 0) {
                          var newDeficit = {...newDeficits[deficitIndex]};
                          newDeficit.entries = entries;
                          newDeficits.splice(deficitIndex, 1, newDeficit);
                          newProtocol.deficits = newDeficits;
                          return newProtocol;
                        }
                        return oldProtocol!;
                      });
                      return `Eintrag erfolgreich entfernt!`;
                    },
                    error: (code) => {
                      return `Fehler beim Entfernen des Eintrags!`;
                    }
                  });
                  return promise;
                },          
                deleteDeficitEntryAttachment: (deficitId: string, entryId: string, attachmentId: string) => {
                  var promise = deleteProtocolDeficitEntryAttachment(protocol.id, deficitId, entryId, attachmentId);
                  toast.promise(promise, {
                    loading: `Foto wird entfernt...`,
                    success: (attachments) => {
                      setProtocol(oldProtocol => {
                        var newProtocol = { ...oldProtocol! };
                        var newDeficits = [...newProtocol!.deficits];
                        var deficitIndex = newDeficits.findIndex(d => d.id === deficitId);
                        if(deficitIndex >= 0) {
                          var newDeficit = {...newDeficits[deficitIndex]};
                          var newEntries = [...newDeficit.entries];
                          var entryIndex = newEntries.findIndex(e => e.id === entryId);
                          if(entryIndex >= 0) {
                              var newEntry = {...newEntries[entryIndex]};
                              newEntry.attachments = attachments;
                              newEntries.splice(entryIndex, 1, newEntry);
                              newDeficit.entries = newEntries;
                              newDeficits.splice(deficitIndex, 1, newDeficit);
                              newProtocol.deficits = newDeficits;
                              return newProtocol;
                          }
                        }
                        return oldProtocol!;
                      });
                      return `Foto erfolgreich entfernt!`;
                    },
                    error: (code) => {
                      return `Fehler beim Entfernen des Fotos!`;
                    }
                  });
                  return promise;
                },
                addDeficitEntryAttachment: (deficitId: string, entryId: string, files: FileList) => {
                  var promise = new Promise<IApiProtocolResponseDeficitEntryAttachment[]>((resolve, reject) => {
                    uploadImages(files, project.id).then(blobInfos => {
                      Promise.all(blobInfos.map(b => postProtocolDeficitEntryAttachment(protocol.id, deficitId, entryId, b.folderName, b.fileName, b.id)))
                      .then(attachments => resolve(attachments));
                    })
                  });
                  toast.promise(promise, {
                    loading: files.length > 1 ? `Fotos werden hochgeladen...` : `Foto wird hochgeladen...`,
                    success: (result) => {
                      setProtocol(oldProtocol => {
                        var newProtocol = { ...oldProtocol! };
                          var newDeficits = [...newProtocol!.deficits];
                          var deficitIndex = newDeficits.findIndex(d => d.id === deficitId);
                        if(deficitIndex >= 0) {
                            var newDeficit = {...newDeficits[deficitIndex]};
                            var entryIndex = newDeficit.entries.findIndex(e => e.id === entryId);
                            if(entryIndex >= 0) {
                                var newEntry = {...newDeficit.entries[entryIndex]};
                                newEntry.attachments = [...newEntry.attachments, ...result];
                                newDeficit.entries.splice(entryIndex, 1, newEntry);
                                newDeficits.splice(deficitIndex, 1, newDeficit);
                                newProtocol.deficits = newDeficits;
                                return newProtocol;
                            }
                        }
                        return oldProtocol!;
                      });
                      return files.length > 1 ? `Die Fotos wurden erfolgreich hochgeladen!` : `Das Foto wurde erfolgreich hochgeladen!`;
                    },
                    error: (code) => {
                        var message = `Hochladen fehlgeschlagen! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 404: {
                                    message += `Fehler 404`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                  });
                  return promise;
                },
                updateDeficitEntryAttachment: (deficitId: string, entryId: string, attachmentId: string, blobId: string, file: File) => {
                  var promise = new Promise<IApiFileDataEditResponse>((resolve, reject) => {
                    updateImage(blobId, file).then(response => resolve(response));
                  });
                  toast.promise(promise, {
                    loading: `Foto wird aktualisiert...`,
                    success: (result) => {
                      setProtocol(oldProtocol => {
                        var newProtocol = { ...oldProtocol! };
                        var newDeficits = [...newProtocol!.deficits];
                        var deficitIndex = newDeficits.findIndex(d => d.id === deficitId);
                        if(deficitIndex >= 0) {
                          var newDeficit = {...newDeficits[deficitIndex]};
                          var entryIndex = newDeficit.entries.findIndex(e => e.id === entryId);
                          if(entryIndex >= 0) {
                            var newEntry = {...newDeficit.entries[entryIndex]};
                            var attachmentIndex = newEntry.attachments.findIndex(a => a.id === attachmentId);
                            if(attachmentIndex >= 0) {
                              var newAttachments = [...newEntry.attachments];
                              newAttachments.splice(attachmentIndex, 1, {
                                ...newEntry.attachments[attachmentIndex],
                                downloadUrl: result.downloadUrl,
                                thumbnailUrl: result.thumbnailUrl
                              });
                              newEntry.attachments = newAttachments;
                              newDeficit.entries.splice(entryIndex, 1, newEntry);
                              newDeficits.splice(deficitIndex, 1, newDeficit);
                              newProtocol.deficits = newDeficits;
                            }
                            return newProtocol;
                          }
                        }
                        return oldProtocol!;
                      });
                      return `Das Foto wurde erfolgreich aktualisiert!`;
                    },
                    error: (code) => {
                        var message = `Aktualisieren fehlgeschlagen! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 404: {
                                    message += `Fehler 404`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                  });
                  return promise;
                },
                restoreDeficitEntryAttachment: (deficitId: string, entryId: string, attachmentId: string, blobId: string) => {
                  var promise = new Promise<IApiFileDataEditResponse>((resolve, reject) => {
                    restoreImage(blobId).then(response => resolve(response));
                  });
                  toast.promise(promise, {
                    loading: `Foto wird wiederhergestellt...`,
                    success: (result) => {
                      setProtocol(oldProtocol => {
                        var newProtocol = { ...oldProtocol! };
                        var newDeficits = [...newProtocol!.deficits];
                        var deficitIndex = newDeficits.findIndex(d => d.id === deficitId);
                        if(deficitIndex >= 0) {
                          var newDeficit = {...newDeficits[deficitIndex]};
                          var entryIndex = newDeficit.entries.findIndex(e => e.id === entryId);
                          if(entryIndex >= 0) {
                            var newEntry = {...newDeficit.entries[entryIndex]};
                            var attachmentIndex = newEntry.attachments.findIndex(a => a.id === attachmentId);
                            if(attachmentIndex >= 0) {
                              var newAttachments = [...newEntry.attachments];
                              newAttachments.splice(attachmentIndex, 1, {
                                ...newEntry.attachments[attachmentIndex],
                                downloadUrl: result.downloadUrl,
                                thumbnailUrl: result.thumbnailUrl
                              });
                              newEntry.attachments = newAttachments;
                              newDeficit.entries.splice(entryIndex, 1, newEntry);
                              newDeficits.splice(deficitIndex, 1, newDeficit);
                              newProtocol.deficits = newDeficits;
                            }
                            return newProtocol;
                          }
                        }
                        return oldProtocol!;
                      });
                      return `Foto erfolgreich wiederhergestellt!`;
                    },
                    error: (code) => {
                      return `Fehler beim Wiederherstellen des Fotos!`;
                    }
                  });
                  return promise;
                },

                getTextSuggestions: (deficitId: string, entryId: string, text: string = "") => {
                  var promise = getSuggestions(protocol.id, deficitId, entryId, "text", text);
                  return promise;
                },

                getImageSuggestions: (deficitId: string, entryId: string) => {
                  var promise = getSuggestions(protocol.id, deficitId, entryId, "image");
                  return promise;
                },

                onSubmit: () => {
                  var promise = submitProtocol(protocol.id, false);
                  toast.promise(promise, {
                    loading: `Protokoll wird abgeschlossen...`,
                    success: (result) => {
                      console.log(result)
                      window.open(result.protocolUrl, "_blank")

                      setProtocol(oldProtocol => ({ ...oldProtocol!, ...result }));

                      // TODO: update protocol object
                      return `Protokoll erfolgreich abgeschlossen!`;
                    },
                    error: (code) => {
                      return `Fehler beim Abschließen des Protokolls!`;
                    }
                  });
                  return promise;
                },
                onPreview: () => {
                  var promise = submitProtocol(protocol.id, true);
                  toast.promise(promise, {
                    loading: `Vorschau wird erstellt...`,
                    success: (result) => {
                      window.open(result.protocolUrl, "_blank")
                      return `Vorschau erfolgreich erstellt!`;
                    },
                    error: (code) => {
                      return `Fehler beim Erstellen der Vorschau!`;
                    }
                  });
                  return promise;
                },

                setOverlay
            }}
        >
            { props.children({ protocol, parties: props.parties, safetyChecklist: props.safetyChecklist, safetyChecklistStatistics, overlay }) }
        </ProtocolContext.Provider>
    );
};