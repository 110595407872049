import React from "react";
import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { Field } from "formik";

interface IInputFormControlProps<T> {

    field: keyof T;
    label: string;
    isInvalid: boolean;
    disabled: boolean;

    errorMessage: string | undefined;
    helperText?: string;

    validate?(value: string): string | undefined;
}

export const InputFormControl = <T,>(props: IInputFormControlProps<T>) => {
    return (
        <FormControl isInvalid={props.isInvalid}>    
            <FormLabel htmlFor={props.field.toString()}>{props.label}</FormLabel>
            <Field
                as={Input}
                id={props.field}
                name={props.field}
                disabled={props.disabled}
                validate={props.validate}
            />
            {(props.isInvalid && props.errorMessage && (
                <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
            )) || (props.helperText && (
                <FormHelperText>{props.helperText}</FormHelperText>
            ))}
        </FormControl>
    );
}