import React, { useState, useContext, useEffect } from "react";
import { Badge, TagLeftIcon, Divider, HStack, Skeleton, Tag, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiMail, FiPhone, FiSmartphone, FiHome } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import { ApiContext } from "../../contexts/ApiProvider";
import { IApiProjectContactResponse } from "../../models/api/contact/IApiProjectContactResponse";

interface IProjectContactsProps {

}

export const ProjectContacts: React.FunctionComponent<IProjectContactsProps> = (props: IProjectContactsProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { setAppShellConfig, projects } = useContext(AppContext);

    const { projectId } = useParams();
    const project = projects.find(p => p.id === projectId);   
    const [contacts, setContacts] = useState<IApiProjectContactResponse[]>([]);

    const { getProjectContacts } = useContext(ApiContext);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Kontakte`
            },
            subHeading: {
                text: `${project?.name} (${project?.ref})`
            }
        });
        if(!project) {
            navigate(`/projects`);
            return;
        }
        setLoading(true);
        getProjectContacts(project.id).then(contacts => {
            setLoading(false);
            setContacts(contacts);
        });
    }, [setAppShellConfig, project, navigate]);

    if(!project) {
        return <h1>Projekt nicht gefunden!</h1>
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            <ResponsiveTable 
                data={contacts}
                loading={loading}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('active', {
                        header: () => 'Firma',
                        cell: info => {
                            const contact = info.row.original;
                            return (
                                <VStack spacing="0" align="start">
                                    {contact.name.length > 0 && (
                                        <Text fontWeight="medium" fontSize="sm">
                                            {contact.name}
                                        </Text>
                                    )}
                                    {contact.firstName.length > 0 && contact.lastName.length > 0 && (
                                        <Text color="muted" fontSize="sm">
                                            {`${contact.firstName} ${contact.lastName}`}
                                        </Text>
                                    )}
                                </VStack>
                            );
                        },
                    }),
                    columnHelper.accessor('role', {
                        header: () => 'Rolle/Gewerk',
                        cell: info => {
                            const contact = info.row.original;
                            return (
                                <VStack spacing="2" align="start">
                                    <Badge colorScheme={contact.active ? 'green' : 'red'} size="sm">
                                        {contact.active ? 'aktiv' : 'inaktiv'}
                                    </Badge>
                                    {contact.role && (
                                        <Badge colorScheme={'purple'} size="sm">{contact.role}</Badge>
                                    )}
                                    {contact.trade && (
                                        <Badge colorScheme={'blue'} size="sm">{contact.trade}</Badge>
                                    )}
                                </VStack>
                            );
                        },
                    }),
                    columnHelper.accessor('street1', {
                        header: () => 'Adresse',
                        cell: info => {
                            const contact = info.row.original;
                            return (
                                <Text fontSize="sm" lineHeight={1.5}>
                                    {contact.street1 && (<>{`${contact.street1}`}<br/></>)}
                                    {contact.street2 && (<>{`${contact.street2}`}<br/></>)}
                                    {contact.zip && contact.city && (<>{`${contact.zip} ${contact.city}`}<br/></>)}
                                    {contact.country && (<>{`${contact.country}`}</>)} 
                                </Text>
                            );
                        },
                    }),
                    columnHelper.accessor('phone1', {
                        header: () => 'Kontaktdaten',
                        cell: info => {
                            const contact = info.row.original;
                            return (
                                <VStack spacing="4" align="start">
                                    {contact.phone1 && (
                                        <a href={`tel:${contact.phone1}`}>
                                            <Tag fontSize="xs" px="2">
                                                <TagLeftIcon boxSize='12px' as={FiPhone} />
                                                {contact.phone1}
                                            </Tag>
                                        </a>
                                    )}
                                    {contact.phoneM && (
                                        <a href={`tel:${contact.phoneM}`}>
                                            <Tag fontSize="xs" px="2">
                                                <TagLeftIcon boxSize='12px' as={FiSmartphone} />
                                                {contact.phoneM}
                                            </Tag>
                                        </a>
                                    )}
                                    {contact.email && (
                                        <a href={`mailto:${contact.email}`}>
                                            <Tag fontSize="xs" px="2">
                                                <TagLeftIcon boxSize='12px' as={FiMail} />
                                                {contact.email}
                                            </Tag>
                                        </a>
                                    )}
                                </VStack>
                            );
                        },
                    }),


                ]}
                getCard={(contact) => ({
                    key: contact.id,
                    value: contact.id,
                    content: (
                        <VStack spacing="2" align="start">
                            <VStack alignItems="start">
                                <HStack>
                                    {contact.name.length > 0 && (
                                        <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                            {contact.name}
                                        </Text>
                                    )}
                                    {contact.role && (
                                        <Badge colorScheme={'red'} size={{ base: "sm", md: "md" }}>{contact.role}</Badge>
                                    )}
                                    {contact.trade && (
                                        <Badge colorScheme={'blue'} size={{ base: "sm", md: "md" }}>{contact.trade}</Badge>
                                    )}
                                </HStack>
                                {contact.firstName.length > 0 && contact.lastName.length > 0 && (
                                    <Text color="muted" fontSize={{ base: "sm", md: "md" }}>
                                        {`${contact.firstName} ${contact.lastName}`}
                                    </Text>
                                )}
                            </VStack>
                            <Divider />
                            <VStack spacing="2" align="start" w="full">
                                {contact.email && (
                                    <a href={`mailto:${contact.email}`} style={{ width: "100%"}}>
                                        <Tag variant="outline" 
                                             colorScheme="black" 
                                             w="full" 
                                             fontSize={{ base: "sm", md: "md" }} 
                                             px="2" py={{ base: "2", md: "2" }}>
                                                <TagLeftIcon as={FiMail} />
                                                {contact.email}
                                        </Tag>
                                    </a>
                                )}
                                {(contact.phone1 || contact.phoneM) && (
                                    <HStack w="full">
                                        {contact.phone1 && (
                                            <a href={`tel:${contact.phone1}`} style={{ flexGrow: "1"}}>
                                                <Tag variant="outline" colorScheme="black" w="full" 
                                                    fontSize={{ base: "sm", md: "md" }} 
                                                    px="2" py={{ base: "2", md: "2" }}>
                                                        <TagLeftIcon as={FiPhone} />
                                                        {contact.phone1}
                                                </Tag>
                                            </a>
                                        )}
                                        {contact.phoneM && (
                                            <a href={`tel:${contact.phoneM}`} style={{ flexGrow: "1"}}>
                                                <Tag variant="outline" colorScheme="black" w="full" 
                                                    fontSize={{ base: "sm", md: "md" }} 
                                                    px="2" py={{ base: "2", md: "2" }}>
                                                        <TagLeftIcon as={FiSmartphone} />
                                                        {contact.phoneM}
                                                </Tag>
                                            </a>
                                        )}  
                                    </HStack> 
                                )} 
                            </VStack>
                            {contact.zip && contact.city && (
                                <>
                                    <Divider /> 
                                    <Tag variant="outline" colorScheme="black" w="full" 
                                         fontSize={{ base: "sm", md: "md" }} 
                                         px="2" py={{ base: "2", md: "2" }} lineHeight={1.5}>
                                            <TagLeftIcon as={FiHome} />
                                            {contact.street1 && (<>{`${contact.street1}`}<br/></>)}
                                            {contact.street2 && (<>{`${contact.street2}`}<br/></>)}
                                            {contact.zip && contact.city && (<>{`${contact.zip} ${contact.city}`}<br/></>)}
                                            {contact.country && (<>{`${contact.country}`}</>)} 
                                    </Tag>
                                </>
                            )}
                        </VStack>
                    )
                })}
            />
        </Stack>
    );

}