import { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import auth0 from "auth0-js";
import { useNavigate } from "react-router-dom";
import { IApiLoginResponse, IApiLoginResponseOrganization } from "../models/api/login/IApiLoginResponse";
import { LoginWithCenteredForm } from "../components/LoginWithCenteredForm/LoginWithCenteredForm";
import {
    Divider,
    Button,
    Image,
    Container,
    Spinner,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    ListItem,
    Input,
    List,
    Alert,
    AlertIcon,
    AlertTitle,
    Link,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
  } from '@chakra-ui/react'
import { Logo } from "../components/LoginWithCenteredForm/Logo";
import Helpers from '../Helpers';

interface IOrganizationContext {
    email: string | undefined;
    organization: string | undefined;
}
    
export const OrganizationContext = createContext({} as IOrganizationContext);

export const OrganizationProvider = (props: { 
    baseUri: string,
    callbackPath: string,
    children: React.ReactElement 
}) => {

    const [email, setEmail] = useState<string | undefined>(undefined);
    const [loginInfo, setLoginInfo] = useState<IApiLoginResponse | undefined>(undefined);
    const [organization, setOrganization] = useState<IApiLoginResponseOrganization | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<string | undefined>(undefined);

    const emailRef = useRef<HTMLInputElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const getLoginInfo = useMemo(() => async (email: string): Promise<IApiLoginResponse> => {
        var result = await fetch(`${props.baseUri}/login?user=${email}`, {
            method : "GET",
        });
        return result.json();
    }, [props.baseUri]);

    const headingSize = useBreakpointValue({ base: 'xs', md: 'sm' });
    const boxBg = useBreakpointValue({ base: 'transparent', sm: 'bg-surface' });
    const boxShadow = { base: 'none', sm: useColorModeValue('md', 'md-dark') };

    if(window.location.pathname.startsWith(`/${props.callbackPath}`)) {
        // /login -> either through url-shortener or auth0-callback
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        return (
            <OrganizationContext.Provider
                value={{
                    email: undefined,
                    organization: params.organization
                }}
            >  
                {props.children}
            </OrganizationContext.Provider>
        );
    }
    else if(document.cookie.match('auth0\..+\.is\.authenticated')) {
        // already authenticated -> cookie exists
        return (
            <OrganizationContext.Provider
                value={{
                    email: undefined,
                    organization: undefined
                }}
            >  
                {props.children}
            </OrganizationContext.Provider>
        );
    }
    else if (email && organization) {
        // redirect to sign in page
        return (
            <OrganizationContext.Provider
                value={{
                    email,
                    organization: organization.id
                }}
            >  
                {props.children}
            </OrganizationContext.Provider>
        );
    } else {
        return (
            <Container
                h="full"
                w="full" 
                display="flex"
                flexDir="column"
                justifyContent="center"
                justifyItems="center"
                px="0"
                py="0"
            >
                <Container 
                    w={{ base: "full", sm: "400px" }}
                    h={{ base: "full", sm: "auto" }}
                    px="0"
                    py="0"
                    borderRadius={{ base: undefined, sm: "5px" }}
                >
                    <Stack 
                        p="40px" 
                        spacing="0" 
                        borderRadius="5px" 
                        h={{ base: "full", sm: undefined }}
                        minH="500" 
                        background="white" 
                        boxShadow="0 12px 40px rgba(0,0,0,0.12)" 
                        display="flex"
                        justifyContent="center"
                        justifyItems="center"
                    >
                        <Stack spacing={{ base: "6", sm: "8" }} mt="0 !important">
                            <Image src="https://storagebaukglogos1.blob.core.windows.net/public/baukgapp-logo-0.png" />
                            <Stack spacing={{ base: '2', sm: '3' }} textAlign="center">
                                <Heading size="xs">
                                    Anmelden
                                </Heading>
                                <HStack spacing="1" justify="center">
                                    <Text color="muted">Kein Account? Jetzt <Link color="#E3032E" href="https://baukg.app/signup">registrieren</Link></Text>
                                </HStack>
                            </Stack>
                        </Stack>
                        <Stack spacing="0" mt="10 !important">
                            <Input 
                                id="email" 
                                type="email" 
                                ref={emailRef} 
                                disabled={loading || error !== undefined || email !== undefined} 
                                onKeyDown={e => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        btnRef.current?.click();
                                    }
                                }}
                                borderRadius="3px"
                                borderColor="#C9CACE"
                                minH="52px"
                                fontSize="16px"
                                placeholder="E-Mail Adresse"
                            />
                            {error && (
                                <Alert status='error'>
                                    <AlertIcon />
                                    <AlertTitle>{error}</AlertTitle>
                                </Alert>
                            )}
                        </Stack>
                        {(email !== undefined) && loginInfo && (
                            <Stack spacing="2" mt="5 !important">
                                {loginInfo.organizations.map(o => (
                                    <Button
                                        key={o.id}
                                        minH="52px"
                                        variant="primary" 
                                        backgroundColor="#E3032E"
                                        borderRadius="3px"
                                        position="relative"
                                        cursor={"pointer"}
                                        onClick={() => setOrganization(o)}
                                    >
                                        {o.name}
                                    </Button>
                                ))}
                            </Stack>
                        )}
                        {(email === undefined) && (
                            <Stack spacing="0" mt="5 !important">
                                <Button 
                                    backgroundColor="#E3032E"
                                    minH="52px"
                                    borderRadius="3px"
                                    w="full"
                                    variant="primary" 
                                    disabled={loading} 
                                    ref={btnRef} 
                                    onClick={() => {
                                        if(!emailRef.current || !emailRef.current.value) {
                                            return;
                                        }
                                        if(!error && !Helpers.validateEmail(emailRef.current.value)) {
                                            setError("Ungültige E-Mail Adresse!");
                                            return;
                                        }
                                        if(!error) {
                                            // Weiter
                                            setLoading(true);
                                            getLoginInfo(emailRef.current!.value).then(info => {
                                                if(info.organizations.length === 0) {
                                                    setError("Ungültige E-Mail Adresse!");
                                                }
                                                else if(info.organizations.length === 1) {
                                                    setOrganization(info.organizations[0]);
                                                    setLoginInfo(info);
                                                    setEmail(emailRef.current!.value);
                                                } else {
                                                    setLoginInfo(info);
                                                    setEmail(emailRef.current!.value);
                                                }
                                                setLoading(false);
                                            });
                                        } else {
                                            // Zurück
                                            emailRef.current!.value = "";
                                            setError(undefined)
                                            setLoginInfo(undefined);
                                        }
                                    }}>
                                        {loading && <Spinner mr={4} />}
                                        {!error ? "Weiter" : "Zurück"}
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                </Container>
            </Container>
        );
    }
}