import * as React from 'react'
import {
    Stack,
    Button,
    Text,
    IconButton,
    ButtonProps,
    useId,
    useRadio,
    useRadioGroup,
    UseRadioProps,
} from '@chakra-ui/react'
import { FiArrowLeft, FiArrowRight, FiFastForward, FiRewind, FiSkipBack } from 'react-icons/fi';
import { useMemo } from 'react';

interface IPaginationItemProps {
  isCurrent?: boolean;
  page: number;
  onPageChange: (page: number) => void;
  colorScheme?: (string & {}) | "whiteAlpha" | "blackAlpha" | "gray" | "red" | "orange" | "yellow" | "green" | "teal" | "blue" | "cyan" | "purple" | "pink" | "linkedin" | "facebook" | "messenger" | "whatsapp" | "twitter" | "telegram" | undefined;
}

const PaginationItem = ({ 
  isCurrent = false, 
  page, 
  onPageChange, 
  colorScheme 
}: IPaginationItemProps) => {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="xs"
        width="4"
        colorScheme={colorScheme}
        disabled
        _disabled={{
          bg: `${colorScheme}.500`,
          cursor: "pointer",
        }}
      >
        {page}
      </Button>
    );
  }
  return (
    <Button
      size="sm"
      fontSize="xs"
      width="4"
      bg="gray.100"
      _hover={{
        bg: "gray.300",
      }}
      onClick={() => onPageChange(page)}
    >
      {page}
    </Button>
  );
}

interface IPaginationControlsProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  lastPage: number;
  colorScheme?: (string & {}) | "whiteAlpha" | "blackAlpha" | "gray" | "red" | "orange" | "yellow" | "green" | "teal" | "blue" | "cyan" | "purple" | "pink" | "linkedin" | "facebook" | "messenger" | "whatsapp" | "twitter" | "telegram" | undefined;
}
  
export const PaginationControls = ({
  currentPage,
  lastPage,
  onPageChange,
  colorScheme,
}: IPaginationControlsProps) => {

  var pages = useMemo(() => {
    if(lastPage <= 3) {
      switch(lastPage) {
        case 1: { return [1]; }
        case 2: { return [1, 2]; }
        case 3: { return [1, 2, 3]; }
      }
    } else if(currentPage === 1) {
      return [1, 2, 3];
    }
    else if(currentPage === lastPage) {
      return [currentPage - 2, currentPage - 1, currentPage];
    } else {
      return [currentPage - 1, currentPage, currentPage + 1];
    }
  }, [lastPage, currentPage]);
  
  if(lastPage < currentPage) {
    return (<></>);
  }

  return (
    <Stack direction="row" mt="8" justify="center" align="center" spacing="6">
      <Stack direction="row" spacing="2">
        <Button 
          aria-label='to-first'  
          fontSize="14px" 
          size="sm" 
          onClick={() => onPageChange(1)}
          visibility={((currentPage === 1) || (lastPage <= 3)) ? "hidden" : "visible"}  
          variant={'ghost'}
        >
          &lt;&lt;
        </Button>
        <Button 
          aria-label='back'
          fontSize="14px" 
          size="sm" 
          onClick={() => onPageChange(currentPage - 1)} 
          visibility={(currentPage === 1) ? "hidden" : "visible"} 
          variant={'ghost'} 
        >
          &lt;
        </Button>

        {pages?.map(p => (
          <Button 
            aria-label={`goto-${p}`} 
            variant={(p === currentPage) ? 'outline' : 'ghost'} 
            fontSize="14px" 
            size="sm" 
            onClick={() => (p !== currentPage) && onPageChange(p)}
          >
            {p}
          </Button>
        ))}

        <Button 
          aria-label='next'     
          fontSize="14px" 
          size="sm" 
          onClick={() => onPageChange(currentPage + 1)} 
          visibility={(currentPage === lastPage) ? "hidden" : "visible"} 
          variant={'ghost'}
        >
          &gt;
        </Button>
        <Button 
          aria-label='to-last' 
          fontSize="14px" 
          size="sm" 
          onClick={() => onPageChange(lastPage)} 
          visibility={((currentPage === lastPage) || (lastPage <= 3)) ? "hidden" : "visible"}  
          variant={'ghost'}
        >
          &gt;&gt;
        </Button>
      </Stack>
    </Stack>
  );
  
}
