import { createContext, JSXElementConstructor, ReactElement, useContext, useEffect } from "react";
import { useAuth0, User } from "@auth0/auth0-react";
import { OrganizationContext } from "./OrganizationProvider";

interface IAuthContext {
    logout(): void;
    user: User,
    getAccessTokenSilently(): Promise<string>;
}

export const AuthContext = createContext({} as IAuthContext);

export const AuthProvider = (props: { 
    logoutRedirectUri: string,
    children: ReactElement<any, string | JSXElementConstructor<any>>
}) => {

    const { loginWithRedirect, user, isAuthenticated, isLoading, getAccessTokenSilently, logout } = useAuth0();
    const { organization, email } = useContext(OrganizationContext);

    if(!isAuthenticated) {
        if(!isLoading) {
            if(organization) {
                loginWithRedirect({ 
                    organization: organization,
                    login_hint: email 
                });
            }
        }
        return (<h1>Logging in...</h1>);
    }

    if(!user) {
        return (<h1>Failed to retrieve user!</h1>);
    }

    if(isLoading) {
        return (<h1>Still loading...</h1>);
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                logout: () => logout({ returnTo: props.logoutRedirectUri }),
                getAccessTokenSilently
            }}
        >
            { props.children }
        </AuthContext.Provider>
    );
};