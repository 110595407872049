import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, PinInputField, PinInput } from '@chakra-ui/react'
import * as React from 'react'
import { useContext } from 'react';
import { FiDownload, FiMail } from 'react-icons/fi';
import Helpers from '../../../Helpers';
import { ProtocolContext } from './ProtocolProvider';

export interface IProtocolDrawerRecipientsProps {
    
}

export const ProtocolDrawerRecipients = (props: IProtocolDrawerRecipientsProps ) => {
    const { protocol, setDate, setTime } = useContext(ProtocolContext);
    return (
        <VStack spacing={6} w="full">
            <Accordion 
                allowMultiple={true} 
                flexGrow={1} 
                overflow="auto"
                w="full"
            >
                {protocol.recipients.map(recipient => (
                    <AccordionItem key={recipient.contact.id}>
                        <h2>
                            <AccordionButton>
                                    <VStack spacing="1" align="start" flexGrow={1}>
                                        {recipient.contact.firstName.length > 0 && recipient.contact.lastName.length > 0 && (
                                            <Text fontWeight="medium" fontSize={{ base: "sm" }}>
                                                {`${recipient.contact.firstName} ${recipient.contact.lastName}`}
                                            </Text>
                                        )}
                                        {recipient.contact.name.length > 0 && (
                                            <Text color="muted" fontSize={{ base: "sm" }}>
                                                {recipient.contact.name}
                                            </Text>
                                        )}
                                        <HStack>
                                            <Tag variant="outline" colorScheme="black" w="full" fontSize={{ base: "xs" }} pl="0" py="2">
                                                <TagLeftIcon as={FiMail} />
                                                {recipient.contact.email}
                                            </Tag>
                                        </HStack>
                                    </VStack>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <VStack>
                                {recipient.protocolDownloads.length === 0 && recipient.documentDownloads.length === 0 && (
                                    <Text>Kein Download!</Text>
                                )}
                                {recipient.protocolDownloads.length > 0 && (
                                    <FormControl>
                                        <FormLabel>Protokoll ({recipient.protocolDownloads.length} Download{recipient.protocolDownloads.length !== 1 ? "s" : ""})</FormLabel>
                                        <List>
                                            {recipient.protocolDownloads.map(date => (
                                                <ListItem>
                                                    <ListIcon as={FiDownload} />
                                                    {Helpers.getDownloadDateString(date)}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </FormControl>
                                )}
                                {recipient.documentDownloads.length > 0 && (
                                    <FormControl>
                                        <FormLabel>Dokumente ({recipient.documentDownloads.length} Download{recipient.documentDownloads.length !== 1 ? "s" : ""})</FormLabel>
                                        <List>
                                            {recipient.documentDownloads.map(date => (
                                                <ListItem>
                                                    <ListIcon as={FiDownload} />
                                                    {Helpers.getDownloadDateString(date)}
                                                </ListItem>
                                            ))}
                                        </List>
                                    </FormControl>
                                )}
                            </VStack>
                        </AccordionPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        </VStack>
    );
}