import React, { useState, useContext, useEffect } from "react";
import { Badge, Select, Box, Button, ButtonGroup, Checkbox, Divider, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";

interface IProjectHomeProps {

}

export const ProjectHome: React.FunctionComponent<IProjectHomeProps> = (props: IProjectHomeProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { setAppShellConfig, projects } = useContext(AppContext);

    const { projectId } = useParams();
    const project = projects.find(p => p.id === projectId);   

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: project?.name
            },
            subHeading: {
                text: `Referenz: ${project?.ref}`
            }
        });
        if(!project) {
            navigate(`/projects`);
        }
    }, [setAppShellConfig, project, navigate]);

    if(!project) {
        return <h1>Projekt nicht gefunden!</h1>
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            
        </Stack>
    );

}