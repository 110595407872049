import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, FormLabel, FormErrorMessage, useBreakpointValue } from '@chakra-ui/react'
import { Field, FormikProps } from 'formik';
import * as React from 'react'

import { IApiSafetyChecklistEditRequest } from '../../../models/api/safetychecklist/IApiSafetyChecklistEditRequest';
import { SafetyChecklistEditor } from '../../SafetyChecklistEditor/SafetyChecklistEditor';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { IBaseDrawerProps } from '../BaseDrawer';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';

export interface IEditChecklistDrawerProps extends Omit<IBaseDrawerProps<IApiSafetyChecklistEditRequest>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText" | "isOpen"> {
    defaultValue: IApiSafetyChecklistEditRequest | undefined;
}

export const EditChecklistDrawer = (props: IEditChecklistDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.defaultValue !== undefined}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue}
            headerText="Checkliste bearbeiten"
            drawerSize={'lg'}
            validateOnBlur={false}
        >
            {({ handleSubmit, errors, touched, isSubmitting, values, setFieldValue }: FormikProps<IApiSafetyChecklistEditRequest>) => (
                <Stack px="0" spacing="4">
                    <InputFormControl 
                        field="name"
                        label="Name"
                        isInvalid={(errors.name && touched.name) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Name ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.name}
                    />
                    <InputFormControl 
                        field="description"
                        label="Beschreibung"
                        isInvalid={(errors.description && touched.description) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.description}
                    />
                    <FormControl>
                        <FormLabel htmlFor="checklist">Themen & Punkte</FormLabel>
                        <SafetyChecklistEditor topics={values.topics} onChange={(topics) => setFieldValue("topics", topics, false)} />
                    </FormControl>
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}