import { Button, Divider, HStack, IconButton, useColorModeValue as mode, useBreakpointValue, ResponsiveValue } from '@chakra-ui/react'
import * as React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { Formik, FormikProps } from 'formik';

export interface IBaseDrawerWithFormProps<T> {
    isOpen: boolean;
    onSubmit(data: T): Promise<any>;
    onDiscard(): void;

    children(formikProps: FormikProps<any>): React.ReactNode;

    defaultValues: any;

    headerText: string;
    saveText?: string;
    discardText?: string;

    drawerSize?: ResponsiveValue<"lg" | "sm" | (string & {}) | "md" | "xl" | "full" | "xs"> | undefined;

    validateOnBlur?: boolean;
}

export const BaseDrawerWithForm = <T,>({ isOpen, onSubmit, onDiscard, children, defaultValues, headerText, saveText, discardText, drawerSize, validateOnBlur }: IBaseDrawerWithFormProps<T>) => {

    const isPhone = useBreakpointValue({ base: true, md: false });

    return (
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={onDiscard}
            size={isPhone ? 'full' : drawerSize ?? 'md'}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <DrawerOverlay />
            <DrawerContent h="full">
                <Formik
                    initialValues={defaultValues}
                    validateOnMount={true}
                    onSubmit={(values, formikHelpers) => {
                        onSubmit(values).then(result => formikHelpers.setSubmitting(false), error => formikHelpers.setSubmitting(false));
                    }}
                    validateOnBlur={validateOnBlur}
                >
                    {(formikProps) => (
                        <form onSubmit={formikProps.handleSubmit} style={{ display: "flex", flexDirection: "column", flexGrow: "1", overflowY: "auto"}}>
                            <DrawerHeader>
                                <HStack justifyContent="space-between">
                                    <span>
                                        {headerText}
                                    </span>
                                    <IconButton
                                        icon={<FiX fontSize="1.25rem" />}
                                        variant="ghost"
                                        aria-label="Discard"
                                        onClick={onDiscard}
                                        zIndex="100"
                                    />
                                </HStack>
                            </DrawerHeader>
                            <Divider />
                            <DrawerBody>
                                {children(formikProps)} 
                            </DrawerBody>
                            <DrawerFooter>
                                <HStack>
                                    <Button disabled={formikProps.isSubmitting} variant='secondary' mr={3} onClick={onDiscard}>
                                        {discardText ?? "Abbrechen"}
                                    </Button>
                                    <Button disabled={!formikProps.isValid || formikProps.isSubmitting} variant='primary' type="submit" >
                                        {saveText ?? "Speichern"}
                                    </Button>
                                </HStack>
                            </DrawerFooter>
                        </form>
                    )}
                </Formik>
            </DrawerContent>
        </Drawer>
    );
}