import { useColorModeValue as mode, Input, Divider, Stack, HStack, InputGroup, ResponsiveValue, useBreakpointValue, InputLeftElement, Icon, Skeleton, List, ListItem } from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
  } from '@chakra-ui/react'
import { Field, FormikProps } from 'formik';
import { prependOnceListener } from 'process';
import * as React from 'react'
import { useContext, useEffect, useRef, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import { ApiContext } from '../../contexts/ApiProvider';
import { IApiContactResponse } from '../../models/api/contact/IApiContactResponse';
import { ContactSearchResult } from './ContactSearchResult';

export interface IContactSearchProps {
    onSelect(data: IApiContactResponse): void;
    maxHeight?: ResponsiveValue<number | "px" | (string & {}) | "4" | "-moz-initial" | "inherit" | "initial" | "revert" | "revert-layer" | "unset" | "sm" | "md" | "lg" | "xl" | "2xl"> | undefined;
    exclusions?: string[];
}

export const ContactSearch= ({ onSelect, maxHeight, exclusions }: IContactSearchProps) => {
    const isPhone = useBreakpointValue({ base: true, md: false });
    const boxShadow = mode('sm', 'sm-dark');

    const { getFilteredContacts } = useContext(ApiContext);

    const [contacts, setContacts] = useState<IApiContactResponse[]>([]);
    const searchRef = useRef<any>();
    const [searching, setSearching] = useState(false);

    useEffect(() => {
        setContacts([]);
    }, [setContacts, onSelect]);

    return (
        <Stack spacing="4" height="full" direction="column" maxHeight={maxHeight}>
            <Stack spacing="4" width="full" direction="row" justifyContent="space-between" align="center">
                <InputGroup>
                    <InputLeftElement pointerEvents="none">
                        <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input ref={searchRef} placeholder="Suchen..." boxShadow={boxShadow} onChange={e => {
                        setSearching(true);
                        getFilteredContacts(e.currentTarget.value).then(result => {
                            setContacts(result);
                            setSearching(false);
                        });
                    }}/>
                </InputGroup>
            </Stack>
            {((searching && (
                <Stack>
                    <Skeleton height='30px' borderRadius={8} />
                    <Skeleton height='30px' borderRadius={8} />
                    <Skeleton height='30px' borderRadius={8} />
                </Stack>
            )) || (
                <List listStyleType="none" spacing={{ base: "2", lg: "4" }} overflowY="auto" mt="2 !important">
                    {contacts.filter(c => (exclusions === undefined || exclusions.findIndex(e => e === c.id) < 0)).map(c => (
                        <ListItem
                            key={c.id}
                            value={c.id}
                            bg="bg-surface"
                            p="4"
                            my="2"
                            mx="2"
                            boxShadow={boxShadow}
                            position="relative"
                            borderRadius="lg"
                            cursor={"pointer"}
                            onClick={() => onSelect(c)}
                        >
                            <ContactSearchResult contact={c} />
                        </ListItem>
                    ))}
                </List>
            ))}
        </Stack>
    );
}