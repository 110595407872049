import React from "react";
import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, FormLabel, FormErrorMessage, Textarea  } from '@chakra-ui/react'
import { Field } from "formik";

interface ITextAreaFormControlProps<T> {

    field: keyof T;
    label: string;
    isInvalid: boolean;
    disabled: boolean;

    errorMessage: string | undefined;
    helperText?: string;

    validate(value: string): string | undefined;

    minHeight?: string | undefined;
}

export const TextAreaFormControl = <T,>(props: ITextAreaFormControlProps<T>) => {
    return (
        <FormControl isInvalid={props.isInvalid}>    
            <FormLabel htmlFor={props.field.toString()}>{props.label}</FormLabel>
            <Field
                as={Textarea}
                id={props.field}
                name={props.field}
                disabled={props.disabled}
                validate={props.validate}
                minHeight={props.minHeight ?? "200"}
            />
            {(props.isInvalid && props.errorMessage && (
                <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
            )) || (props.helperText && (
                <FormHelperText>{props.helperText}</FormHelperText>
            ))}
        </FormControl>
    );
}