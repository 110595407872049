import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, PinInputField, PinInput } from '@chakra-ui/react'
import * as React from 'react'
import { useContext } from 'react';
import { ProtocolContext } from './ProtocolProvider';

export interface IProtocolDrawerGeneralProps {
    readOnly?: boolean;
}

export const ProtocolDrawerGeneral = (props: IProtocolDrawerGeneralProps ) => {
    const { protocol, setDate, setTime } = useContext(ProtocolContext);
    return (
        <VStack spacing={6} px={4} py={8}>
            <FormControl>
                <FormLabel htmlFor='date' 
                    style={{ fontSize: '16px' }}
                >
                    Datum
                </FormLabel>
                <Input id="date" type="date" size="lg" textAlign="center" defaultValue={protocol.date} 
                       onChange={e => setDate(e.currentTarget.value)}
                       disabled={props.readOnly ?? false}
                />
            </FormControl>
            <FormControl>
                <FormLabel htmlFor='time' 
                    style={{ fontSize: '16px' }}
                >
                    Uhrzeit
                </FormLabel>
                <Input id="time" type="time" size="lg" textAlign="center" defaultValue={protocol.time} 
                       onChange={e => setTime(e.currentTarget.value)}
                       disabled={props.readOnly ?? false}
                />
            </FormControl>
        </VStack>
    );
}