import React, { useState, useContext, useEffect, useRef } from "react";
import { FormControl, FormLabel, Editable, EditablePreview, Box, Divider, HStack, Icon, Button, Input, EditableTextarea, InputLeftAddon, InputGroup, InputRightAddon, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus, FiSave } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { ApiContext } from "../../contexts/ApiProvider";
import toast from "react-hot-toast";
import { IApiOrganizationResponse } from "../../models/api/organization/IApiOrganizationResponse";
import EditableEditControls from "../../components/editable/EditableEditControls";
import { AutoResizeTextarea } from "../../components/AutoSizeTextarea/AutoSizeTextarea";
import { validate } from "uuid";
import { IApiOrganizationEditRequest } from "../../models/api/organization/IApiOrganizationEditRequest";
import { Formik } from "formik";
import { InputFormControl } from "../../components/formcontrols/InputFormControl";
import { SelectFormControl } from "../../components/formcontrols/SelectFormControl";

interface IOrganizationProps {

}

const getError = (oldConfig: IApiOrganizationResponse, newConfig: IApiOrganizationResponse): (string | boolean) => {
   if(!newConfig.identity.name) {
    return "Name ist erforderlich!";
   }
   if(!newConfig.identity.street1) {
    return "Adresszeile 1 ist erforderlich!";
   }
   if(!newConfig.identity.zip) {
    return "Postleitzahl ist erforderlich!";
   }
   if(!newConfig.identity.city) {
    return "Ort ist erforderlich!";
   }

   var update = false;

   update ||= (oldConfig.identity.name      !== newConfig.identity.name);
   update ||= (oldConfig.identity.street1   !== newConfig.identity.street1);
   update ||= (oldConfig.identity.street2   !== newConfig.identity.street2);
   update ||= (oldConfig.identity.zip       !== newConfig.identity.zip);
   update ||= (oldConfig.identity.city      !== newConfig.identity.city);
   update ||= (oldConfig.identity.phone1    !== newConfig.identity.phone1);
   update ||= (oldConfig.identity.email     !== newConfig.identity.email);
   update ||= (oldConfig.identity.web       !== newConfig.identity.web);
   update ||= (oldConfig.mail.fromName      !== newConfig.mail.fromName);

   return update;
}

export const Organization: React.FunctionComponent<IOrganizationProps> = (props: IOrganizationProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { me, setAppShellConfig, setDCM, setMe } = useContext(AppContext);
    const { patchOrganization } = useContext(ApiContext);

    const submitButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: "Organisation"
            },
            subHeading: {
                text: "Einstellungen"
            },
            rightButton: {
                text: "Speichern",
                icon: FiSave,
                ariaLabel: "speichern",
                variant: "primary",
                onClick: () => {
                    if(submitButtonRef && submitButtonRef.current) {
                        submitButtonRef.current.click();
                    }
                }
            }
        });
    }, [setAppShellConfig, me, navigate, submitButtonRef]);

    return (
        <Formik
            initialValues={me.organization}
            validateOnMount={true}
            onSubmit={(values, formikHelpers) => {
                var checkResult = getError(me.organization, values);
                if(typeof checkResult === "string") {
                    toast.error(checkResult);
                    formikHelpers.setSubmitting(false);
                } else if(!checkResult) {
                    toast.success("Einstellungen unverändert!");
                    formikHelpers.setSubmitting(false);
                }
                else {
                    const update = (organizationId: string, data: IApiOrganizationEditRequest): Promise<IApiOrganizationResponse> => {
                        var promise = patchOrganization(organizationId, data);
                        toast.promise(promise, {
                            loading: `Einstellungen werden aktualisiert...`,
                            success: (result) => {
                                setMe(old => ({ ...old!, organization: result }));
                                formikHelpers.setSubmitting(false);
                                return `Einstellungen wurden erfolgreich aktualisiert!`;
                            },
                            error: (code) => {
                                var message = `Fehler beim Aktualisieren der Einstellungen! `;
                                switch(code) {
                                    case 400: {
                                        message += `Fehler 400`;
                                        break;
                                    }
                                    case 404: {
                                        message += `Fehler 404`;
                                        break;
                                    }
                                    case 500: {
                                        message += `Fehler 500`;
                                        break;
                                    }
                                }
                                formikHelpers.setSubmitting(false);
                                return message;
                            }
                        });
                        return promise;
                    };
                    setDCM({
                        title: `Einstellungen speichern`,
                        body: `Sind Sie sicher, dass die vorhandenen Einstellungen überschrieben werden sollen?`,
                        onConfirm: () => update(me.organization.id, values)
                    });
                }
            }}
            validateOnBlur={true}
        >
            {({ handleSubmit, errors, touched, setFieldValue, values, isSubmitting, isValid }) => (
                <form onSubmit={handleSubmit}>
                    <Stack 
                        spacing="4" 
                        height="full" 
                        direction="column" 
                        maxW={{ base: undefined, lg: "640px" }} 
                        ml={{ base: undefined, lg: "auto" }}
                        mr={{ base: undefined, lg: "auto" }}
                    >
                        <InputFormControl 
                            field="identity.name"
                            label="Name"
                            isInvalid={(errors.identity?.name && touched.identity?.name ) ? true : false}
                            disabled={isSubmitting}
                            validate={(value: string) => {
                                let error;
                                if (value === null || value === undefined || value.length < 1) {
                                    error = "Name ist erforderlich!";
                                }
                                return error;
                            }}
                            errorMessage={errors.identity?.name}
                        />
                        <Divider />
                        <InputFormControl 
                            field="identity.street1"
                            label="Adresszeile 1"
                            isInvalid={(errors.identity?.street1 && touched.identity?.street1) ? true : false}
                            disabled={isSubmitting}
                            validate={(value: string) => {
                                let error;
                                if (value === null || value === undefined || value.length < 1) {
                                    error = "Adresszeile 1 ist erforderlich!";
                                }
                                return error;
                            }}
                            errorMessage={errors.identity?.street1}
                        />
                        <InputFormControl 
                            field="identity.street2"
                            label="Adresszeile 2"
                            isInvalid={(errors.identity?.street2 && touched.identity?.street2) ? true : false}
                            disabled={isSubmitting}
                            errorMessage={errors.identity?.street1}
                        />
                        <HStack w="full">
                            <Box w="25%">
                                <InputFormControl 
                                    field="identity.zip"
                                    label="Postleitzahl"
                                    isInvalid={(errors.identity?.zip && touched.identity?.zip) ? true : false}
                                    disabled={isSubmitting}
                                    errorMessage={errors.identity?.zip}
                                    validate={(value: string) => {
                                        let error;
                                        if ((value !== null && value !== undefined && value.length > 0) && (value.length < 4 || isNaN(parseInt(value)))) {
                                            error = "Ungültige Postleitzahl!";
                                        }
                                        return error;
                                    }}
                                />
                            </Box>
                            <Box w="75%">
                                <InputFormControl 
                                    field="identity.city"
                                    label="Ort"
                                    isInvalid={(errors.identity?.city && touched.identity?.city) ? true : false}
                                    disabled={isSubmitting}
                                    errorMessage={errors.identity?.city}
                                    validate={(value: string) => {
                                        let error;
                                        if (value === null || value === undefined || value.length < 1) {
                                            error = "Ort ist erforderlich!";
                                        }
                                        return error;
                                    }}
                                />
                            </Box>
                        </HStack>
                        <SelectFormControl 
                            field="identity.country"
                            label="Land"
                            isInvalid={(errors.identity?.country && touched.identity?.country) ? true : false}
                            disabled={isSubmitting}
                            options={() => [
                                (<option key={"country_none"} value=""></option>),
                                (<option key={"country_at"} value="AT">Österreich</option>),
                                (<option key={"country_de"} value="DE">Deutschland</option>),
                                (<option key={"country_ch"} value="CH">Schweiz</option>),
                            ]}
                            onChange={(e) => setFieldValue("identity.country", e.target.value, true)}
                            errorMessage={errors.identity?.country && errors.identity?.country}
                        />
                        <Divider />
                        <InputFormControl 
                            field="identity.email"
                            label="E-Mail"
                            isInvalid={(errors.identity?.email && touched.identity?.email) ? true : false}
                            disabled={isSubmitting}
                            errorMessage={errors.identity?.email}
                        />
                        <InputFormControl 
                            field="identity.phone1"
                            label="Telefon"
                            isInvalid={(errors.identity?.phone1 && touched.identity?.phone1) ? true : false}
                            disabled={isSubmitting}
                            errorMessage={errors.identity?.phone1}
                        />
                        <InputFormControl 
                            field="identity.web"
                            label="Website"
                            isInvalid={(errors.identity?.web && touched.identity?.web) ? true : false}
                            disabled={isSubmitting}
                            errorMessage={errors.identity?.web}
                        />
                        {/*
                        <Divider />
                        <FormControl>    
                            <FormLabel>E-Mail</FormLabel>
                            <AutoResizeTextarea
                                defaultValue={me.organization.mail.body}
                                minH={"350px"} 
                                mb={2}
                                disabled={isSubmitting}
                                onBlur={e => setFieldValue("mail.body", e.currentTarget.value, false)}
                            />
                        </FormControl>
                        */}
                        <Button disabled={!isValid || isSubmitting} type="submit" ref={submitButtonRef} style={{display: 'none'}} />
                    </Stack>
                </form>
            )}
        </Formik>
    );
}