import React, { useState, useContext, useEffect, useRef } from "react";
import { Badge, Select, Box, Button, ButtonGroup, Checkbox, Menu, MenuButton, MenuList, MenuItem, IconButton, Skeleton, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus, FiSave } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";
import { IApiSafetyChecklistResponse, IApiSafetyChecklistTopicResponse } from "../../models/api/safetychecklist/IApiSafetyChecklistResponse";
import { ApiContext } from "../../contexts/ApiProvider";
import { SafetyChecklistEditor } from "../../components/SafetyChecklistEditor/SafetyChecklistEditor";
import { IApiProjectOptionSafetyChecklist } from "../../models/api/project/options/IApiProjectOptionSafetyChecklist";
import toast from "react-hot-toast";

interface IProjectSafetyChecklistProps {

}

export const ProjectSafetyChecklist: React.FunctionComponent<IProjectSafetyChecklistProps> = (props: IProjectSafetyChecklistProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { setAppShellConfig, projects, setDCM } = useContext(AppContext);
    const { getAllChecklists, getProjectSafetyChecklist, patchProjectSafetyChecklist } = useContext(ApiContext);

    const { projectId } = useParams();
    const project = projects.find(p => p.id === projectId);   
    const [option, setOption] = useState<IApiProjectOptionSafetyChecklist>([]);
    const stateRef = useRef<IApiProjectOptionSafetyChecklist>();
    stateRef.current = option;

    const [templates, setTemplates] = useState<IApiSafetyChecklistResponse[]>([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Sicherheitscheckliste`
            },
            subHeading: {
                text: `${project?.name} (${project?.ref})`
            },
            leftButton: () => (
                <Menu>
                    <MenuButton 
                        as={isDesktop ? Button : IconButton} 
                        aria-label="laden" 
                        variant="secondary"
                        icon={<FiPlus fontSize="1.25rem" />} 
                        leftIcon={isDesktop ? <FiPlus fontSize="1.25rem" /> : undefined}
                    >
                        {isDesktop && "Vorlage laden"}
                    </MenuButton>
                    <MenuList>
                        {templates.map(t => (<MenuItem key={t.id} onClick={() => setOption(t.topics)}>{t.name}</MenuItem>))}
                    </MenuList>
                </Menu>
            ),
            rightButton: {
                text: "Speichern",
                icon: FiSave,
                ariaLabel: "speichern",
                variant: "primary",
                onClick: () => {
                    if(project && stateRef.current) {
                        const save = (projectId: string, checklist: IApiProjectOptionSafetyChecklist): Promise<IApiProjectOptionSafetyChecklist> => {
                            var promise = patchProjectSafetyChecklist(projectId, checklist);
                            toast.promise(promise, {
                                loading: `Sicherheitscheckliste wird aktualisiert...`,
                                success: (result) => {
                                    setOption(result);
                                    return `Die Sicherheitscheckliste wurde erfolgreich aktualisiert!`;
                                },
                                error: (code) => {
                                    var message = `Fehler beim Aktualisieren der Sicherheitscheckliste! `;
                                        switch(code) {
                                            case 400: {
                                                message += `Fehler 400`;
                                                break;
                                            }
                                            case 404: {
                                                message += `Fehler 404`;
                                                break;
                                            }
                                            case 500: {
                                                message += `Fehler 500`;
                                                break;
                                            }
                                        }
                                    return message;
                                }
                            });
                            return promise;
                        };
                        setDCM({
                            title: `Checkliste überschreiben`,
                            body: `Sind Sie sicher, dass die vorhandene Checkliste überschrieben werden soll?`,
                            onConfirm: () => save(project.id, stateRef.current!)
                        });
                    }
                }
            }
        });
        if(!project) {
            navigate(`/projects`);
            return;
        }
        if(loading) {
            getProjectSafetyChecklist(project.id).then(checklist => {
                setOption(checklist);
                setLoading(false);
                getAllChecklists().then(result => {
                    setTemplates(result);
                });
            });
        }   
    }, [setAppShellConfig, project, navigate, getAllChecklists, templates, loading, getProjectSafetyChecklist, stateRef, isDesktop]);

    if(!project) {
        return <h1>Projekt nicht gefunden!</h1>
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            {!loading && option && (
                <SafetyChecklistEditor 
                    topics={option} 
                    onChange={(value) => setOption(value)} 
                />
            )}
            {loading && (
                <Stack>
                    <Skeleton height='30px' borderRadius={8} />
                    <Skeleton height='30px' borderRadius={8} />
                    <Skeleton height='30px' borderRadius={8} />
                </Stack>
            )}
        </Stack>
    );

}