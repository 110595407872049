import React, { useState, useContext, useEffect, useRef } from "react";
import { Alert, AlertIcon, Skeleton, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, Badge, Tag, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, TagLeftIcon } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus, FiMail } from "react-icons/fi";
import { useNavigate  } from "react-router-dom";
import { AppContext } from "../../App";
import { ISettingsProps } from "./Settings";
import { EApiContactSource, IApiContactResponse } from "../../models/api/contact/IApiContactResponse";
import { ApiContext } from "../../contexts/ApiProvider";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import toast from "react-hot-toast";
import { AddContactDrawer } from "../../components/drawers/contact/AddContactDrawer";
import { EditContactDrawer } from "../../components/drawers/contact/EditContactDrawer";
import { IApiContactEditRequest } from "../../models/api/contact/IApiContactEditRequest";
import { ContactSearchResult } from "../../components/ContactSearch/ContactSearchResult";

interface IContactsProps extends ISettingsProps {

}

export const Contacts: React.FunctionComponent<IContactsProps> = (props: IContactsProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { me, setAppShellConfig, setDCM } = useContext(AppContext);
    const { getFilteredContacts, postContact, patchContact, deleteContact, getContactsMetadata, getPagedContacts } = useContext(ApiContext);

    const [totalContacts, setTotalContacts] = useState(0);
    const [contacts, setContacts] = useState<IApiContactResponse[]>([]);
    const searchRef = useRef<any>();
    const [searching, setSearching] = useState(false);
    const [showSearchInfo, setShowSearchInfo] = useState(true);

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState<IApiContactResponse | undefined>(undefined);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Kontakte`
            },
            subHeading: {
                text: `Einstellungen`
            },
            rightButton: {
                text: "Erstellen",
                icon: FiPlus,
                ariaLabel: "erstellen",
                variant: "primary",
                onClick: () => {
                    setAdd(true);
                }
            }
        });
        setSearching(true);
        getContactsMetadata().then(meta => {
            setTotalContacts(meta.count);
            getPagedContacts(0).then(contacts => {
                setContacts(contacts);
                setSearching(false);
            })
        });
    }, [setAppShellConfig, me, navigate, setAdd]);

    const deleteAction = (contact: IApiContactResponse) => {
        setDCM({
            title: `Kontakt löschen`,
            body: `Sind Sie sicher, dass dieser Kontakt gelöscht werden soll?`,
            onConfirm: () => {
                var promise = deleteContact(contact.id);
                toast.promise(promise, {
                    loading: `Kontakt wird gelöscht...`,
                    success: (result) => {
                        setContacts(old => {
                            var contacts = [...old];
                            var index = contacts.findIndex(t => t.id === result.id);
                            contacts.splice(index, 1);
                            return contacts;
                        });
                        return `Der Kontakt wurde erfolgreich gelöscht!`;
                    },
                    error: (code) => {
                        var message = `Fehler beim Löschen des Kontakts! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                });
                return promise;
            }
        });
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            <Stack spacing="4" width="full" direction="row" px="2" justifyContent="space-between" align="center">
                <InputGroup maxW={{ base: "75%", lg: undefined }}>
                    <InputLeftElement pointerEvents="none">
                        <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input ref={searchRef} placeholder="Suchen..." boxShadow={boxShadow} onChange={e => {
                        if(e.currentTarget.value.length === 0) {
                            setContacts([]);
                            setSearching(true);
                            getPagedContacts(0).then(result => {
                                setContacts(result);
                                setSearching(false);
                            });
                        } else if(e.currentTarget.value.length < 4) {

                        } else {
                            if(showSearchInfo) {
                                setShowSearchInfo(false);
                            }
                            setContacts([]);
                            setSearching(true);
                            getFilteredContacts(e.currentTarget.value).then(result => {
                                setContacts(result);
                                setSearching(false);
                            });
                        }
                    }}/>
                </InputGroup>
            </Stack>
            <Stack px={2}>
            {showSearchInfo && (
                <Alert status='info' fontSize={isDesktop ? "sm" : "sm"} borderRadius={8}>
                    <AlertIcon />
                    Benutzen Sie das Suchfeld, um nach Kontakten zu suchen (mindestens vier Zeichen).
                </Alert>
            )}
            {!searching && searchRef.current && searchRef.current.value.length >= 4 && contacts.length === 0 && (
                <Alert status='warning' fontSize={isDesktop ? "sm" : "sm"} borderRadius={8}>
                    <AlertIcon />
                    Es konnten keine passenden Kontakte gefunden werden!
                </Alert>
            )}
            </Stack>
            <ResponsiveTable 
                data={contacts}
                loading={(searching && contacts.length === 0)}
                totalItems={totalContacts}
                itemsPerPage={10}
                onPageSelect={(page) => {
                    setContacts([]);
                    setSearching(true);
                    getPagedContacts(page - 1).then(result => {
                        setContacts(result);
                        setSearching(false);
                    });
                }}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('name', {
                        header: () => 'Firma / Anzeigename',
                        cell: info => info.getValue()
                    }),
                    columnHelper.accessor('firstName', {
                        header: () => 'Vorname',
                        cell: info => info.getValue()
                    }),
                    columnHelper.accessor('lastName', {
                        header: () => 'Nachname',
                        cell: info => info.getValue()
                    }),
                    columnHelper.accessor('email', {
                        header: () => 'E-Mail',
                        cell: info => info.getValue()
                    }),
                    columnHelper.accessor('sourceType', {
                        header: () => 'Quelle',
                        cell: info => ([
                            <Badge colorScheme={'blue'} size={{ base: "xs", sm: "sm" }}>uStrich</Badge>,
                            <Badge colorScheme={'yellow'} size={{ base: "xs", sm: "sm" }}>AD</Badge>,
                            <Badge colorScheme={'purple'} size={{ base: "xs", sm: "sm" }}>SharePoint</Badge>,
                            <Badge colorScheme={'green'} size={{ base: "xs", sm: "sm" }}>BauKG</Badge>,
                            <Badge colorScheme={'orange'} size={{ base: "xs", sm: "sm" }}>Benutzer</Badge>,
                            <Badge colorScheme={'gray'} size={{ base: "xs", sm: "sm" }}>N/A</Badge>
                        ][info.getValue()])
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const contact = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">
                                    <IconButton
                                        icon={<FiEdit2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Bearbeiten"
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            setEdit(contact);
                                        }}
                                        disabled={contact.sourceType !== EApiContactSource.BauKG}
                                    />
                                    <IconButton
                                        icon={<FiTrash2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Löschen"
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            deleteAction(contact);
                                        }}
                                        disabled={contact.sourceType !== EApiContactSource.BauKG}
                                    />                           
                                </HStack>
                            );
                        },
                    }),
                ]}
                getCard={(contact) => ({
                    key: contact.id,
                    value: contact.id,
                    content: (
                        <HStack spacing="4" justifyContent="space-between">
                            <ContactSearchResult contact={contact} />
                            <HStack spacing="4" justifyContent="end">
                                <IconButton
                                    icon={<FiEdit2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Bearbeiten"
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        setEdit(contact);
                                    }}
                                    disabled={contact.sourceType !== EApiContactSource.BauKG}
                                />
                                <IconButton
                                    icon={<FiTrash2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Löschen"
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        deleteAction(contact);
                                    }}
                                    disabled={contact.sourceType !== EApiContactSource.BauKG}
                                />
                            </HStack>
                        </HStack>
                    )
                })}
            />
            <AddContactDrawer 
                isOpen={add} 
                onDiscard={() => setAdd(false)}
                onSubmit={(data) => {
                    var promise = postContact(data);
                    toast.promise(promise, {
                      loading: `Kontakt wird erstellt...`,
                      success: (result) => {
                        setAdd(false);
                        setContacts(old => ([...old, result]));
                        return `Der Kontakt wurde erfolgreich erstellt!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Erstellen des Kontakts! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
            />
            <EditContactDrawer 
                defaultValue={edit as IApiContactEditRequest} 
                onDiscard={() => setEdit(undefined)} 
                onSubmit={(data) => {
                    var promise = patchContact(edit!.id, data);
                    toast.promise(promise, {
                      loading: `Kontakt wird aktualisiert...`,
                      success: (result) => {
                        setEdit(undefined);
                        setContacts(old => {
                            var contacts = [...old];
                            var index = contacts.findIndex(d => d.id === result.id);
                            contacts.splice(index, 1, result);
                            return contacts;
                        });
                        return `Der Kontakt wurde erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Aktualisieren des Kontakts! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 404: {
                                message += `Fehler 404`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
            />
        </Stack>
    );

}