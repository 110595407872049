import { Stack, useBreakpointValue, FormControl, FormLabel, HStack, IconButton, Select, FormErrorMessage, Input, Tooltip } from '@chakra-ui/react'
import * as React from 'react'


import { IApiSafetyChecklistCreateRequest } from '../../../models/api/safetychecklist/IApiSafetyChecklistCreateRequest';
import { IBaseDrawerProps } from '../BaseDrawer';
import { Field, FieldProps, FormikProps, validateYupSchema } from 'formik';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { SafetyChecklistEditor } from '../../SafetyChecklistEditor/SafetyChecklistEditor';
import { IApiProjectOptionParty } from '../../../models/api/project/options/IApiProjectOptionParty';
import { ContactSearch } from '../../ContactSearch/ContactSearch';
import { ContactSearchResult } from '../../ContactSearch/ContactSearchResult';
import { FiCheck, FiEdit2, FiSquare, FiX } from 'react-icons/fi';
import { IApiTradeResponse } from '../../../models/api/trade/IApiTradeResponse';
import { boolToString, ProjectPartyDocumentState, stringToBool } from '../../../pages/project-parties/ProjectParties';
import { RadioIconButtonGroup, RadioIconButton } from '../../RadioIconButtonGroup/RadioIconButtonGroup';

export interface IAddPartyDrawerProps extends Omit<IBaseDrawerProps<IApiProjectOptionParty>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText"> {
    defaultValue?: IApiProjectOptionParty;
    exclusions: string[];
    trades: IApiTradeResponse[];
}

export const AddPartyDrawer = (props: IAddPartyDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.isOpen}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue ?? {
                active: true,
                contact: undefined,
                trade: undefined,
                startDate: "",
                aba: null,
                uwn: null,
                bev: null
            }}
            headerText="Beteiligte hinzufügen"
            validateOnBlur={false}
        >
            {({ handleSubmit, errors, touched, isSubmitting, setFieldValue, values }: FormikProps<IApiProjectOptionParty>) => (
                <Stack px="0" spacing="4">
                    <FormControl>
                        <FormLabel htmlFor="contact">Kontakt</FormLabel>
                        <Field name="contact" validate={(value: any) => (value === undefined) ? "Kontakt ist erforderlich!" : undefined}>
                            {({ meta: { value } }: FieldProps) => (
                                <>
                                    {(!value && (
                                        <ContactSearch onSelect={(c) => setFieldValue("contact", c, true)} exclusions={props.exclusions} />
                                    )) || (
                                        <HStack justifyContent="space-between">
                                            <ContactSearchResult contact={value} />
                                            <IconButton aria-label="bearbeiten" icon={<FiEdit2 />} variant="ghost" onClick={() => setFieldValue("contact", undefined, true)} />
                                        </HStack>
                                    )}
                                </>
                            )}
                        </Field>                   
                    </FormControl>
                    <FormControl isInvalid={(errors.trade && touched.trade) ? true : false}>
                        <FormLabel htmlFor="trade">Gewerk</FormLabel>
                        <Field
                            as={Select}
                            id="trade"
                            name="trade"
                            variant="outline"
                            validate={(value: string) => (value !== undefined) ? undefined : "Gewerk ist erforderlich!"}
                            value={values.trade ? values.trade.id : undefined}
                            onChange={(e: any) => {
                                var value = e.target.value;
                                var index = props.trades.findIndex(t => t.id === value);
                                console.log(value, index)
                                if(value !== "trade-undefined" && index >= 0) {
                                    setFieldValue("trade", props.trades[index], true);
                                } else {
                                    setFieldValue("trade", undefined, true);
                                }
                            }}
                        >
                            {(values.trade === undefined) && (
                                <option key="trade-undefined" value="trade-undefined">- bitte wählen -</option>
                            )} 
                            {props.trades.sort((a, b) => a.value.localeCompare(b.value)).map(t => (
                                <option key={t.id} value={t.id}>{t.value}</option>
                            ))}
                        </Field>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="startDate">Arbeitsbeginn (TT.MM.JJJJ)</FormLabel>
                        <Field 
                            as={Input}
                            id="startDate"
                            name="startDate"
                            variant="outline"
                            type='date'
                            size='md'
                            maxLength={10}
                            validate={(value: string) => (value && value.length === 10) ? undefined : "Arbeitsbeginn ist erforderlich!"}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Dokumente</FormLabel>
                        <HStack spacing="5" mt={2}>
                            <RadioIconButtonGroup key="aba" defaultValue={boolToString(values.aba)} size="sm" onChange={(value) => setFieldValue("aba", stringToBool(value), false)}>
                                <RadioIconButton value="true" aria-label="true" icon={<FiCheck fontSize="1.25rem" />} _checked={{ bg: 'green.200' }} />
                                <RadioIconButton value="false" aria-label="false" icon={<FiX fontSize="1.25rem" />} _checked={{ bg: 'red.200' }} />
                                <RadioIconButton value="null" aria-label="null" icon={<FiSquare fontSize="1.25rem" />} />
                            </RadioIconButtonGroup>
                            <FormLabel fontSize="sm" >Arbeitsbeginnanzeige (ABA)</FormLabel>
                        </HStack>
                        <HStack spacing="5" mt={2}>
                            <RadioIconButtonGroup key="uwn" defaultValue={boolToString(values.aba)} size="sm" onChange={(value) => setFieldValue("uwn", stringToBool(value), false)}>
                                <RadioIconButton value="true" aria-label="true" icon={<FiCheck fontSize="1.25rem" />} _checked={{ bg: 'green.200' }} />
                                <RadioIconButton value="false" aria-label="false" icon={<FiX fontSize="1.25rem" />} _checked={{ bg: 'red.200' }} />
                                <RadioIconButton value="null" aria-label="null" icon={<FiSquare fontSize="1.25rem" />} />
                            </RadioIconButtonGroup>
                            <FormLabel fontSize="sm" >Unterweisungsnachweis (UWN)</FormLabel>
                        </HStack>
                        <HStack spacing="5" mt={2}>
                            <RadioIconButtonGroup key="bev" defaultValue={boolToString(values.aba)} size="sm" onChange={(value) => setFieldValue("bev", stringToBool(value), false)}>
                                <RadioIconButton value="true" aria-label="true" icon={<FiCheck fontSize="1.25rem" />} _checked={{ bg: 'green.200' }} />
                                <RadioIconButton value="false" aria-label="false" icon={<FiX fontSize="1.25rem" />} _checked={{ bg: 'red.200' }} />
                                <RadioIconButton value="null" aria-label="null" icon={<FiSquare fontSize="1.25rem" />} />
                            </RadioIconButtonGroup>
                            <FormLabel fontSize="sm" >Baustellenevaluierung (BEV)</FormLabel>
                        </HStack>
                    </FormControl>
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}