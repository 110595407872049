import React, { useState, useContext, useEffect, useRef } from "react";
import { Alert, AlertIcon, Skeleton, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus } from "react-icons/fi";
import { useNavigate  } from "react-router-dom";
import { AppContext } from "../../App";
import { ISettingsProps } from "./Settings";
import { IApiDeficitResponse } from "../../models/api/deficit/IApiDeficitResponse";
import { ApiContext } from "../../contexts/ApiProvider";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import toast from "react-hot-toast";
import { AddDeficitDrawer } from "../../components/drawers/deficit/AddDeficitDrawer";
import { EditDeficitDrawer } from "../../components/drawers/deficit/EditDeficitDrawer";
import { IApiDeficitEditRequest } from "../../models/api/deficit/IApiDeficitEditRequest";
import { PaginationControls } from "../../components/PaginationControls/PaginationControls";

interface IDeficitsProps extends ISettingsProps {

}

export const Deficits: React.FunctionComponent<IDeficitsProps> = (props: IDeficitsProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { me, setAppShellConfig, setDCM } = useContext(AppContext);
    const { getFilteredDeficits, postDeficit, patchDeficit, deleteDeficit, getDeficitsMetadata, getPagedDeficits } = useContext(ApiContext);

    const [totalDeficits, setTotalDeficits] = useState(0);
    const [deficits, setDeficits] = useState<IApiDeficitResponse[]>([]);
    const searchRef = useRef<any>();
    const [searching, setSearching] = useState(false);
    const [showSearchInfo, setShowSearchInfo] = useState(true);

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState<IApiDeficitResponse | undefined>(undefined);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Mängel`
            },
            subHeading: {
                text: `Einstellungen`
            },
            rightButton: {
                text: "Erstellen",
                icon: FiPlus,
                ariaLabel: "erstellen",
                variant: "primary",
                onClick: () => {
                    setAdd(true);
                }
            }
        });
        setSearching(true);
        getDeficitsMetadata().then(meta => {
            setTotalDeficits(meta.count);
            getPagedDeficits(0).then(deficits => {
                setDeficits(deficits);
                setSearching(false);
            })
        });
    }, [setAppShellConfig, me, navigate, setAdd]);

    const deleteAction = (deficit: IApiDeficitResponse) => {
        setDCM({
            title: `Eintrag löschen`,
            body: `Sind Sie sicher, dass dieser Eintrag gelöscht werden soll?`,
            onConfirm: () => {
                var promise = deleteDeficit(deficit.id);
                toast.promise(promise, {
                    loading: `Gewerk wird gelöscht...`,
                    success: (result) => {
                        setDeficits(old => {
                            var deficits = [...old];
                            var index = deficits.findIndex(t => t.id === result.id);
                            deficits.splice(index, 1);
                            return deficits;
                        });
                        return `Der Eintrag wurde erfolgreich gelöscht!`;
                    },
                    error: (code) => {
                        var message = `Fehler beim Löschen des Eintrags! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                });
                return promise;
            }
        });
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            <Stack spacing="4" width="full" direction="row" px="2" justifyContent="space-between" align="center">
                <InputGroup maxW={{ base: "75%", lg: undefined }}>
                    <InputLeftElement pointerEvents="none">
                        <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input ref={searchRef} placeholder="Suchen..." boxShadow={boxShadow} onChange={e => {
                        if(e.currentTarget.value.length === 0) {
                            setDeficits([]);
                            setSearching(true);
                            getPagedDeficits(0).then(result => {
                                setDeficits(result);
                                setSearching(false);
                            });
                        } else if(e.currentTarget.value.length < 4) {

                        } else {
                            if(showSearchInfo) {
                                setShowSearchInfo(false);
                            }
                            setDeficits([]);
                            setSearching(true);
                            getFilteredDeficits(e.currentTarget.value).then(result => {
                                setDeficits(result);
                                setSearching(false);
                            });
                        }
                    }}/>
                </InputGroup>
            </Stack>
            <Stack px={2}>
            {showSearchInfo && (
                <Alert status='info' fontSize={isDesktop ? "sm" : "sm"} borderRadius={8}>
                    <AlertIcon />
                    Benutzen Sie das Suchfeld, um nach Mängel zu suchen (mindestens vier Zeichen).
                </Alert>
            )}
            {!searching && searchRef.current && searchRef.current.value.length >= 4 && deficits.length === 0 && (
                <Alert status='warning' fontSize={isDesktop ? "sm" : "sm"} borderRadius={8}>
                    <AlertIcon />
                    Es konnten keine passenden Einträge gefunden werden!
                </Alert>
            )}
            </Stack>
            <ResponsiveTable 
                data={deficits}
                loading={(searching && deficits.length === 0)}
                totalItems={totalDeficits}
                itemsPerPage={10}
                onPageSelect={(page) => {
                    setDeficits([]);
                    setSearching(true);
                    getPagedDeficits(page - 1).then(result => {
                        setDeficits(result);
                        setSearching(false);
                    });
                }}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('value', {
                        header: () => 'Bezeichnung',
                        cell: info => info.getValue()
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const deficit = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">
                                    <IconButton
                                        icon={<FiEdit2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Bearbeiten"
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            setEdit(deficit);
                                        }}
                                    />
                                    <IconButton
                                        icon={<FiTrash2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Löschen"
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            deleteAction(deficit);
                                        }}
                                    />                           
                                </HStack>
                            );
                        },
                    }),
                ]}
                getCard={(deficit) => ({
                    key: deficit.id,
                    value: deficit.id,
                    content: (
                        <HStack spacing="4" justifyContent="space-between">
                            <VStack spacing="2" align="left">
                                <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                    {deficit.value}
                                </Text>
                            </VStack>
                            <HStack spacing="4" justifyContent="end">
                                <IconButton
                                    icon={<FiEdit2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Bearbeiten"
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        setEdit(deficit);
                                    }}
                                />
                                <IconButton
                                    icon={<FiTrash2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Löschen"
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        deleteAction(deficit);
                                    }}
                                />
                            </HStack>
                        </HStack>
                    )
                })}
            />
            <AddDeficitDrawer 
                isOpen={add} 
                onDiscard={() => setAdd(false)}
                onSubmit={(data) => {
                    var promise = postDeficit(data);
                    toast.promise(promise, {
                      loading: `Eintrag wird erstellt...`,
                      success: (result) => {
                        setAdd(false);
                        setDeficits(old => ([result, ...old]));
                        return `Der Eintrag wurde erfolgreich erstellt!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Erstellen des Eintrags! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
            />
            <EditDeficitDrawer 
                defaultValue={edit as IApiDeficitEditRequest} 
                onDiscard={() => setEdit(undefined)} 
                onSubmit={(data) => {
                    var promise = patchDeficit(edit!.id, data);
                    toast.promise(promise, {
                      loading: `Gewerk wird aktualisiert...`,
                      success: (result) => {
                        setEdit(undefined);
                        setDeficits(old => {
                            var deficits = [...old];
                            var index = deficits.findIndex(d => d.id === result.id);
                            deficits.splice(index, 1, result);
                            return deficits;
                        });
                        return `Der Eintrag wurde erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Aktualisieren des Eintrags! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 404: {
                                message += `Fehler 404`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
            />
        </Stack>
    );

}