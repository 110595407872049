import { Icon } from '@chakra-ui/icons'
import {
  Divider,
  Flex,
  HStack,
  Stack,
  IconButton,
  VStack,
  useTheme
} from '@chakra-ui/react'
import * as React from 'react'
import { useContext } from 'react'
import {
  FiBarChart2,
  FiBookmark,
  FiCheckSquare,
  FiHome,
  FiSettings,
  FiUsers,
  FiFolder,
  FiFileText,
  FiBook,
  FiFile,
  FiLogOut,
  FiLayers,
  FiPenTool,
  FiEye
} from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'
import { AuthContext } from '../../contexts/AuthProvider'
import Helpers from '../../Helpers'
import { Logo } from './Logo'
import { NavGroup } from './NavGroup'
import { NavItem } from './NavItem'
import { UserProfile } from './UserProfile'

export const Sidebar = ({ isOpen, onClose }: { onClose?(): void, isOpen?: boolean }) => {
  
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { me, projects } = useContext(AppContext);
  const { logout } = useContext(AuthContext);

  var selectedId: string | undefined | RegExpMatchArray | null = path.match(/\/projects\/.{36}/);
  selectedId = (selectedId && selectedId.length === 1) ? selectedId[0].replace("/projects/", "") : undefined;
  var selectedProject = selectedId ? projects.find(p => p.id === selectedId) : undefined;

  const close = () => (!isOpen || onClose!());

  return (
    <Flex
      flex="1"
      bg="bg-accent"
      color="on-accent"
      overflowY="auto"
      maxW={{ base: 'full', sm: 'xs' }}
      py={{ base: '6', sm: '8' }}
      px={{ base: '4', sm: '6' }}
      h="full"
    >
      <Stack justify="space-between" spacing="1" w="full" h="full">
        <Stack spacing={{ base: '5', sm: '6' }}>
          <Logo />
          <Divider borderColor="bg-accent-subtle" />
        </Stack>
        <Stack spacing={{ base: '5', sm: '6' }} pt={{ base: '5', sm: '6' }} shouldWrapChildren overflowY="auto" flex="1">
          <NavGroup label="Organisation">
            <NavItem icon={<FiHome />} 
                     label="Home" 
                     onClick={() => { navigate(`/`); close(); }} 
                     active={Helpers.matchPath(path, /^\/?$/)} 
                    />
            <NavItem icon={<FiFolder />} 
                     label="Projekte" 
                     onClick={() => { navigate(`/projects`); close(); }} 
                     active={Helpers.matchPath(path, /^\/projects\/?$/)} 
                    />
            <NavItem icon={<FiSettings />} 
                     label="Einstellungen" 
                     onClick={() => { navigate(`/settings`); close(); }} 
                     active={Helpers.matchPath(path, /^\/settings\/?/)} 
                    />
          </NavGroup>
          {selectedId && (
              <NavGroup label={selectedProject?.name ?? "Projekt"}>
                <NavItem icon={<FiBarChart2 />} 
                         label="Übersicht" 
                         onClick={() => { navigate(`/projects/${selectedId}`); close(); }} 
                         active={Helpers.matchPath(path, /^\/projects\/.{36}\/?$/)} 
                        />
                <NavItem icon={<FiFileText />} 
                         label="Protokolle" 
                         onClick={() => { navigate(`/projects/${selectedId}/protocols`); close(); }} 
                         active={Helpers.matchPath(path, /^\/projects\/.{36}\/protocols\/?$/)} 
                        />
                <NavItem icon={<FiBook />} 
                         label="Kontakte"
                         onClick={() => { navigate(`/projects/${selectedId}/contacts`); close(); }} 
                         active={Helpers.matchPath(path, /^\/projects\/.{36}\/contacts\/?$/)} 
                        />
                <Divider borderColor="gray.600" mt="2" mb="1" />
                <NavItem icon={<FiBookmark />} 
                         label="Stammdaten"
                         onClick={() => { navigate(`/projects/${selectedId}/coredata`); close(); }} 
                         active={Helpers.matchPath(path, /^\/projects\/.{36}\/coredata\/?$/)} 
                        />
                <NavItem icon={<FiUsers />} 
                         label="Beteiligtenliste" 
                         onClick={() => { navigate(`/projects/${selectedId}/parties`); close(); }} 
                         active={Helpers.matchPath(path, /^\/projects\/.{36}\/parties\/?$/)} 
                        />
                <NavItem icon={<FiEye />} 
                         label="Beobachter" 
                         onClick={() => { navigate(`/projects/${selectedId}/observers`); close(); }} 
                         active={Helpers.matchPath(path, /^\/projects\/.{36}\/observers\/?$/)} 
                        />
                <NavItem icon={<FiFile />} 
                         label="Dokumente" 
                         onClick={() => { navigate(`/projects/${selectedId}/documents`); close(); }} 
                         active={Helpers.matchPath(path, /^\/projects\/.{36}\/documents\/?$/)} 
                        />
                <NavItem icon={<FiCheckSquare />} 
                         label="Sicherheitscheckliste" 
                         onClick={() => { navigate(`/projects/${selectedId}/safetychecklist`); close(); }} 
                         active={Helpers.matchPath(path, /^\/projects\/.{36}\/safetychecklist\/?$/)}  
                        />
              </NavGroup>
          )}
        </Stack>
        <Stack spacing={{ base: '5', sm: '6' }}>
          <Divider borderColor="bg-accent-subtle" />
          <HStack w="full" justifyContent="space-between">
            <UserProfile
              name={`${me.user.contact.firstName} ${me.user.contact.lastName}`}
              image=""
              email={me.user.email}
              onClick={() => { navigate(`/profile`); close(); }}
            />
            <IconButton 
                icon={<FiLogOut />} 
                aria-label="logout" 
                variant="ghost" 
                color={theme.colors.brand["400"]} 
                bg="gray.700" 
                _hover={{ bg: theme.colors.brand["700"] }} 
                _active={{ bg: theme.colors.brand["600"] }} 
                onClick={(e) => { e.stopPropagation(); logout(); }}
            />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  );
}
