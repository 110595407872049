import React, { useState, useContext, useEffect, useRef, useMemo } from "react";
import { Badge, Select, Box, Button, ButtonGroup, Checkbox, Divider, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, Skeleton, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus, FiFile, FiDownload } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";
import { StorageContext } from "../../contexts/StorageProvider";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import { ApiContext } from "../../contexts/ApiProvider";
import toast from "react-hot-toast";
import { IApiFileListResponse } from "../../models/api/file/IApiFileListResponse";
import { EditDocumentDrawer } from "../../components/drawers/document/EditDocumentDrawer";

interface IProjectDocumentsProps {

}

export const ProjectDocuments: React.FunctionComponent<IProjectDocumentsProps> = (props: IProjectDocumentsProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { setAppShellConfig, projects, setDCM } = useContext(AppContext);
    const { getAllFiles, getFile, patchFileInfo, deleteFile } = useContext(ApiContext);
    const { uploadDocuments } = useContext(StorageContext);

    const { projectId } = useParams();
    const project = projects.find(p => p.id === projectId); 
    const [documents, setDocuments] = useState<IApiFileListResponse[]>([]);

    const [edit, setEdit] = useState<IApiFileListResponse | undefined>(undefined);

    const inputRef = useRef<any>();

    const [loading, setLoading] = useState(true);

    const uploadAction = useMemo(() => (files: FileList) => {
        var promise = uploadDocuments(files, project!.id);
        toast.promise(promise, {
            loading: files.length > 1 ? `Dokumente werden hochgeladen...` : `Dokument wird hochgeladen...`,
            success: (result) => {
                setDocuments(old => [...old, ...result]);
                return files.length > 1 ? `Die Dokumente wurden erfolgreich hochgeladen!` : `Das Dokument wurde erfolgreich hochgeladen!`;
            },
            error: (code) => {
                var message = `Hochladen fehlgeschlagen! `;
                    switch(code) {
                        case 400: {
                            message += `Fehler 400`;
                            break;
                        }
                        case 404: {
                            message += `Fehler 404`;
                            break;
                        }
                        case 500: {
                            message += `Fehler 500`;
                            break;
                        }
                    }
                return message;
            }
        });
    }, [uploadDocuments, setDocuments]);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Dokumente`
            },
            subHeading: {
                text: `${project?.name} (${project?.ref})`
            },
            rightButton: () => (
                <>
                    <input type='file'
                        name={"fileinput"}
                        ref={inputRef}
                        style={{display: 'none'}} 
                        onChange={(e) => e.target.files && uploadAction(e.target.files)}
                    />
                    {(isDesktop && (
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents="none">
                                <Icon as={FiFile} />
                            </InputLeftElement>
                            <Input
                                placeholder={"Dokument hochladen ..."}
                                onClick={() => inputRef.current.click()}
                                readOnly={true}
                                onDragOver={e => e.preventDefault()}
                                onDrop={e => { e.preventDefault(); console.log(e.dataTransfer.files); }}
                                _hover={{ cursor: 'pointer' }}
                            />
                        </InputGroup>
                    )) || (
                        <IconButton 
                            icon={<FiPlus fontSize={"1.25rem"} />} 
                            aria-label="hochladen"
                            variant="primary"
                            onClick={() => inputRef.current.click()}
                        />
                    )}
                </>
            )
        });
        if(!project) {
            navigate(`/projects`);
            return;
        }
        setLoading(true);
        getAllFiles("documents", project.id).then(files => {
            setDocuments(files);
            setLoading(false);
        });
    }, [setAppShellConfig, project, navigate, inputRef, isDesktop, uploadAction]);

    if(!project) {
        return <h1>Projekt nicht gefunden!</h1>
    }

    const deleteAction = (file: IApiFileListResponse) => {
        setDCM({
            title: `Dokument löschen`,
            body: `Sind Sie sicher, dass dieses Dokument gelöscht werden soll?`,
            onConfirm: () => {
                var files = [...documents];
                var index = documents.findIndex(f => f.id === file.id);
                if(index < 0) {
                    // error, should never happen
                }
                files.splice(index, 1);
                var promise = deleteFile(file.folderName, file.id);
                toast.promise(promise, {
                    loading: `Dokument wird gelöscht...`,
                    success: (result) => {
                        setDocuments(files);
                        return `Das Dokument wurde erfolgreich gelöscht!`;
                    },
                    error: (code) => {
                        var message = `Fehler beim Löschen des Dokuments! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 404: {
                                    message += `Fehler 404`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                });
                return promise;
            }
        });
    };

    return (
        <Stack spacing="4" height="full" direction="column">
            <ResponsiveTable 
                data={documents}
                loading={loading}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('fileName', {
                        header: () => 'Name',
                        cell: info => info.getValue()
                    }),
                    columnHelper.accessor('fileExtension', {
                        header: () => 'Typ',
                        cell: info => (<Badge>{info.getValue()}</Badge>)
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const blobInfo = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">  
                                    <IconButton
                                        icon={<FiDownload fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Herunterladen"
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            getFile(blobInfo.folderName, blobInfo.id);
                                        }}
                                    /> 
                                    <IconButton
                                        icon={<FiEdit2 fontSize="1.25rem" />}
                                        variant="ghost"
                                        aria-label="Bearbeiten"
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            setEdit(blobInfo);
                                        }}
                                    /> 
                                    <IconButton
                                        icon={<FiTrash2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Löschen"
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            deleteAction(blobInfo);
                                        }}
                                    />                 
                                </HStack>
                            );
                        }
                    }),
                ]}
                getCard={(blobInfo) => ({
                    key: blobInfo.id,
                    value: blobInfo.id,
                    content: (
                        <HStack spacing="4" justifyContent="space-between">
                            <VStack spacing="2" align="left" alignItems="start">
                                <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                    {blobInfo.fileName}
                                </Text>
                                <Badge>{blobInfo.fileExtension}</Badge>
                            </VStack>
                            <HStack spacing="0" justifyContent="end">
                                <IconButton
                                    icon={<FiDownload fontSize="1.125rem" />}
                                    variant="ghost"
                                    aria-label="Herunterladen"
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        getFile(blobInfo.folderName, blobInfo.id);
                                    }}
                                /> 
                                <IconButton
                                    icon={<FiEdit2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Bearbeiten"
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        setEdit(blobInfo);
                                    }}
                                /> 
                                <IconButton
                                    icon={<FiTrash2 fontSize="1.125rem" />}
                                    variant="ghost"
                                    aria-label="Löschen"
                                    onClick={(e) => { 
                                        e.stopPropagation();
                                        deleteAction(blobInfo);
                                    }}
                                />  
                            </HStack>
                        </HStack>
                    )
                })}
            />
            <EditDocumentDrawer 
                defaultValue={edit as IApiFileListResponse} 
                onDiscard={() => setEdit(undefined)} 
                onSubmit={(data) => {
                    var promise = patchFileInfo(edit!.folderName, edit!.id, data);
                    toast.promise(promise, {
                      loading: `Dokument wird aktualisiert...`,
                      success: (result) => {
                        setEdit(undefined);
                        setDocuments(old => {
                            var documents = [...old];
                            var index = documents.findIndex(d => d.id === result.id);
                            documents.splice(index, 1, result);
                            return documents;
                        });
                        return `Das Dokument '${result.fileName}' wurde erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Aktualisieren des Dokuments! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 404: {
                                message += `Fehler 404`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }}
            />
        </Stack>
    );

}