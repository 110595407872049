import './ResponsiveTable.css'
import React, { useMemo, useState, useEffect } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Container, List, ListItem, useBreakpointValue, useColorModeValue as mode, Skeleton, Stack } from "@chakra-ui/react";
import { createColumnHelper, ColumnDef, flexRender,
    getCoreRowModel,
    useReactTable, ColumnHelper, Row } from "@tanstack/react-table"
import { PaginationControls } from '../PaginationControls/PaginationControls';

export interface IResponsiveTableProps<T> {
    getColumns(columnHelper: ColumnHelper<T>): ColumnDef<T, any>[];
    data: T[];
    getCard(item: T): { onClick?(): void, value: string, key: string, content: React.ReactNode };
    onRowClick?(row: Row<T>): void;
    cellWidths?: string[];
    loading?: boolean;
    totalItems?: number;
    itemsPerPage?: number;
    onPageSelect?(page: number): void;
}

export const ResponsiveTable = <T,>(props: IResponsiveTableProps<T>) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');
    const { getColumns, data, getCard } = props;
    const columnHelper = useMemo(() => createColumnHelper<T>(), []);
    const columns = useMemo(() => getColumns(columnHelper), [getColumns, columnHelper]);
    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        enableColumnResizing: props.cellWidths ? false : true,
        columnResizeMode: 'onChange',
    });
    const [page, setPage] = useState<number>(1);
    useEffect(() => {
        if(props.totalItems && props.itemsPerPage && ((props.totalItems % props.itemsPerPage) ? 1 : 0) <= page) {
            // do nothing
        } else {
            setPage(1);
        }
    }, [props.totalItems, props.itemsPerPage, page]);

    const Pagination = () => (
        <>
        {!props.loading && props.totalItems !== undefined && props.itemsPerPage !== undefined && props.onPageSelect !== undefined && (
            <PaginationControls 
                currentPage={page} 
                lastPage={Math.trunc(props.totalItems / props.itemsPerPage) + ((props.totalItems % props.itemsPerPage) !== 0 ? 1 : 0)}
                onPageChange={(page) => {
                    setPage(page);
                    props.onPageSelect!(page);
                }} 
            />
        )}
        </>
    );

    if(isDesktop) {
        return (
            <>
            <Container h="full" px="0" py="0" overflowY="auto">
                <Table w="full">
                    <Thead style={{ position: 'sticky', top: '0', zIndex: 4 }}>
                    {table.getHeaderGroups().map(headerGroup => (
                        <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, headerIndex) => {
                            return (
                            <Th
                                key={header.id}
                                colSpan={header.colSpan}
                                style={{ 
                                    position: 'relative', 
                                    width: (props.cellWidths && props.cellWidths.length > headerIndex) ? props.cellWidths[headerIndex] : header.getSize(), 
                                }}
                            >
                                {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                    )}
                                {header.column.getCanResize() && (
                                <div
                                    onMouseDown={header.getResizeHandler()}
                                    onTouchStart={header.getResizeHandler()}
                                    className={`resizer ${
                                    header.column.getIsResizing() ? 'isResizing' : ''
                                    }`}
                                ></div>
                                )}
                            </Th>
                            )
                        })}
                        </Tr>
                    ))}
                    </Thead>
                    <Tbody>
                    {table.getRowModel().rows.map(row => {
                        return (
                        <Tr key={row.id} onClick={() => props.onRowClick && props.onRowClick(row)} _hover={props.onRowClick ? { cursor: 'pointer' } : undefined}>
                            {row.getVisibleCells().map((cell, cellIndex) => {
                            return (
                                <Td key={cell.id} style={{ 
                                    width: (props.cellWidths && props.cellWidths.length > cellIndex) ? props.cellWidths[cellIndex] : cell.column.getSize(), 
                                    whiteSpace: 'normal', 
                                    overflowWrap: 'anywhere' 
                                }}>
                                {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                )}
                                </Td>
                            )
                            })}
                        </Tr>
                        )
                    })}
                    </Tbody>
                </Table>
                {props.loading && (
                    <Stack>
                        <Skeleton height='30px' borderRadius={8} />
                        <Skeleton height='30px' borderRadius={8} />
                        <Skeleton height='30px' borderRadius={8} />
                    </Stack>
                )}
            </Container>
            <Pagination />
            </>
        );
    } else {
        return (
            <>
            <List listStyleType="none" spacing={{ base: "2", lg: "4" }} overflowY="auto" mt="2 !important">
                {data.map(p => {
                    const { onClick, key, value, content } = getCard(p);
                    return (
                        <ListItem
                            key={key}
                            value={value}
                            bg="bg-surface"
                            p="4"
                            my="2"
                            mx="2"
                            boxShadow={boxShadow}
                            position="relative"
                            borderRadius="lg"
                            cursor={onClick ? "pointer" : undefined}
                            onClick={onClick}
                        >
                            {content}
                        </ListItem>
                    )
                })}
                {props.loading && (
                    <Stack>
                        <Skeleton height='30px' borderRadius={8} />
                        <Skeleton height='30px' borderRadius={8} />
                        <Skeleton height='30px' borderRadius={8} />
                    </Stack>
                )}
            </List>
            <Pagination />
            </>
        );
    }

}