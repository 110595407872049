import { useColorModeValue as mode, Input, Divider, Stack, FormControl, FormHelperText, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { Field, FormikProps } from 'formik';
import * as React from 'react'
import Helpers from '../../../Helpers';

import { IApiContactEditRequest } from '../../../models/api/contact/IApiContactEditRequest';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { SelectFormControl } from '../../formcontrols/SelectFormControl';
import { TextAreaFormControl } from '../../formcontrols/TextAreaFormControl';
import { IBaseDrawerProps } from '../BaseDrawer';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';

export interface IEditContactDrawerProps extends Omit<IBaseDrawerProps<IApiContactEditRequest>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText" | "isOpen"> {
    defaultValue: IApiContactEditRequest | undefined;
}

export const EditContactDrawer = (props: IEditContactDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.defaultValue !== undefined}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue}
            headerText="Kontakt bearbeiten"
            validateOnBlur={false}
        >
            {({ handleSubmit, errors, touched, isSubmitting, setFieldValue, values }: FormikProps<IApiContactEditRequest>) => (
                <Stack px="0" spacing="4" pb={"100px" /* country select offset */}>
                    <InputFormControl 
                        field="email"
                        label="E-Mail"
                        isInvalid={(errors.email && touched.email) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "E-Mail ist erforderlich!";
                            } else if (!Helpers.validateEmail(value)) {
                                error = "Ungültiges Adressformat!";
                            }
                            return error;
                        }}
                        errorMessage={errors.email}
                    />
                    <Divider />
                    <SelectFormControl 
                        field="salutation"
                        label="Anrede"
                        isInvalid={(errors.salutation && touched.salutation) ? true : false}
                        disabled={isSubmitting}
                        options={() => [
                            (<option key={"salutation_firma"} value="Firma">Firma</option>),
                            (<option key={"salutation_frau"} value="Frau">Frau</option>),
                            (<option key={"salutation_herr"} value="Herr">Herr</option>),
                        ]}
                        onChange={(e) => {
                            setFieldValue("salutation", e.target.value, true);
                            if(e.target.value === "Firma") {
                                setFieldValue("title", "", false);
                                setFieldValue("firstName", "", false);
                                setFieldValue("lastName", "", false);
                            }
                        }}
                        errorMessage={errors.salutation && errors.salutation}
                    />
                    <SelectFormControl 
                        field="title"
                        label="Title"
                        isInvalid={(errors.salutation && touched.salutation) ? true : false}
                        disabled={isSubmitting || values.salutation === "Firma"}
                        options={() => [
                            (<option key={"title_none"} value=""></option>),
                            (<option key={"title_ing"} value="Ing.">Ing.</option>),
                            (<option key={"title_dipling"} value="Dipl-Ing.">Dipl-Ing.</option>),
                            (<option key={"title_mag"} value="Mag.">Mag.</option>),
                            (<option key={"title_dr"} value="Dr">Dr.</option>),
                            (<option key={"title_kommr"} value="KommR">KommR</option>),
                        ]}
                        onChange={(e) => setFieldValue("title", e.target.value, true)}
                        errorMessage={errors.salutation && errors.salutation}
                    />
                    <InputFormControl 
                        field="firstName"
                        label="Vorname"
                        isInvalid={(errors.firstName && touched.firstName) ? true : false}
                        disabled={isSubmitting || values.salutation === "Firma"}
                        errorMessage={errors.firstName}
                    />
                    <InputFormControl 
                        field="lastName"
                        label="Nachname"
                        isInvalid={(errors.lastName && touched.lastName) ? true : false}
                        disabled={isSubmitting || values.salutation === "Firma"}
                        validate={(value: string) => {
                            let error;
                            if (values.salutation !== "Firma" && (value === null || value === undefined || value.length < 1)) {
                                error = "Nachname ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.lastName}
                    />
                    <InputFormControl 
                        field="name"
                        label="Firmenname"
                        isInvalid={(errors.name && touched.name) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (values.salutation === "Firma" && (value === null || value === undefined || value.length < 1)) {
                                error = "Firmenname ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.name}
                    />
                    <Divider />
                    <InputFormControl 
                        field="phone1"
                        label="Telefon"
                        isInvalid={(errors.phone1 && touched.phone1) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.phone1}
                    />
                    <InputFormControl 
                        field="phoneM"
                        label="Mobil"
                        isInvalid={(errors.phoneM && touched.phoneM) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.phoneM}
                    />
                    <InputFormControl 
                        field="street1"
                        label="Adresszeile 1"
                        isInvalid={(errors.street1 && touched.street1) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.street1}
                    />
                    <InputFormControl 
                        field="street2"
                        label="Adresszeile 2"
                        isInvalid={(errors.street2 && touched.street2) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.street2}
                    />
                    <InputFormControl 
                        field="zip"
                        label="Postleitzahl"
                        isInvalid={(errors.zip && touched.zip) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.zip}
                        validate={(value: string) => {
                            let error;
                            if ((value !== null && value !== undefined && value.length > 0) && (value.length < 4 || isNaN(parseInt(value)))) {
                                error = "Ungültige Postleitzahl!";
                            }
                            return error;
                        }}
                    />
                    <InputFormControl 
                        field="city"
                        label="Ort"
                        isInvalid={(errors.city && touched.city) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.city}
                    />
                    <SelectFormControl 
                        field="country"
                        label="Land"
                        isInvalid={(errors.country && touched.country) ? true : false}
                        disabled={isSubmitting}
                        options={() => [
                            (<option key={"country_none"} value=""></option>),
                            (<option key={"country_at"} value="AT">Österreich</option>),
                            (<option key={"country_de"} value="DE">Deutschland</option>),
                            (<option key={"country_ch"} value="CH">Schweiz</option>),
                        ]}
                        onChange={(e) => setFieldValue("country", e.target.value, true)}
                        errorMessage={errors.salutation && errors.salutation}
                    />
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}