import { Box, Button, ButtonGroup, Container, Divider, HStack, IconButton, TagLeftIcon, Tag, VStack, Editable, EditablePreview, EditableInput, useBreakpointValue, Badge, SimpleGrid, FormControl, FormLabel, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, TagLabel, ModalBody, ModalContent, ModalHeader, ModalOverlay, Image, ExpandedIndex, Select } from '@chakra-ui/react'
import * as React from 'react'
import { useContext, useRef, useState } from 'react';
import { FiCheck, FiX, FiSearch, FiSave, FiTrash2, FiPlus, FiImage, FiMaximize2, FiMinimize2, FiEdit2, FiMinus } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { IApiDeficitResponse } from '../../../models/api/deficit/IApiDeficitResponse';
import { IApiProjectOptionParty } from '../../../models/api/project/options/IApiProjectOptionParty';
import { ProtocolContext } from './ProtocolProvider';
import { IApiProtocolResponseDeficit, IApiProtocolResponseDeficitEntry, IApiProtocolResponseDeficitEntryAttachment } from '../../../models/api/protocol/IApiProtocolResponse';
import Helpers from '../../../Helpers';
import { AutoResizeTextarea } from '../../AutoSizeTextarea/AutoSizeTextarea';
import { RadioIconButton, RadioIconButtonGroup } from '../../RadioIconButtonGroup/RadioIconButtonGroup';
import EditableEditControls from '../../editable/EditableEditControls';
import { ProtocolDeficitEntryEditor } from '../../ProtocolDeficitEntryEditor/ProtocolDeficitEntryEditor';
import { AppContext } from '../../../App';

export interface IProtocolDrawerDeficitsProps {
    readOnly?: boolean;
}

const DeficitEntryAttachment = ({ attachment }: { attachment: IApiProtocolResponseDeficitEntryAttachment }) => {

    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const { protocol, parties } = useContext(ProtocolContext);

    return (
        <Image
            boxSize={{ base: "100px", md: "150px" }}
            objectFit='contain'
            src={attachment.id}
            cursor="pointer"
            onClick={() => window.open(attachment.id, "_blank")}
            border={"solid"}
            borderWidth={"1px"}
            borderColor={"gray.200"}
        />
    )
}

const DeficitEntryAttachments = ({ attachments }: { attachments: IApiProtocolResponseDeficitEntryAttachment[] }) => {

    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const { protocol, parties } = useContext(ProtocolContext);

    return (
        <FormControl>
            <SimpleGrid spacing={'5px'} columns={{ base: 5, sm: 7, md: 6}} mt={2}>
                {attachments.map(attachment => (
                    <Image
                        boxSize={{ base: '70px', sm: '80px', md: '75px'}}
                        objectFit='cover'
                        src={attachment.thumbnailUrl}
                        border={"solid"}
                        borderWidth={"1px"}
                        borderColor={"gray.200"} 
                        borderRadius={'6px'}
                    />
                ))}
            </SimpleGrid>
            <input 
                type='file'
                name={"fileinput"}
                ref={fileInputRef}
                style={{display: 'none'}} 
                onChange={(e) => { alert("not implemented") }}
            />
        </FormControl>
    )
}

const DeficitEntry = ({ deficitId, entry, readOnly }: { deficitId: string, entry: IApiProtocolResponseDeficitEntry, readOnly?: boolean }) => {

    const { setDCM } = useContext(AppContext);

    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

    const { protocol, parties, setDeficitEntrySave, setOverlay, deleteDeficitEntry } = useContext(ProtocolContext);

    return (
        <VStack w="100%" spacing={0}>
            <Badge 
                fontSize='12px'
                w='100%' 
                textAlign='left' 
                borderBottomRadius='0' 
                borderTopRadius='6px'
                colorScheme={(entry.source === protocol.ref) ? 'blue' : undefined}
            >
                {((entry.source === protocol.ref) && (
                    `Neuer Eintrag (Protokoll ${protocol.ref})`
                )) || (
                    `Eintrag vom ${Helpers.formatDate(entry.date)} (Protokoll ${entry.source})`
                )}
            </Badge>
            <AutoResizeTextarea 
                size="sm"
                value={entry.text}
                readOnly={true}
                borderTopRadius={0}
                borderBottomRadius={'6px'}
                _hover={{ cursor: (entry.source === protocol.ref) ? 'pointer' : 'not-allowed' }}
                onClick={(e) => {
                    e.currentTarget.blur();
                    if(entry.source === protocol.ref) {
                        setOverlay(<ProtocolDeficitEntryEditor deficitId={deficitId} entryId={entry.id} />);
                    }
                }}
                isReadOnly={readOnly}
            />
            {((entry.source === protocol.ref) && (
                <>
                    {/*
                    <ButtonGroup size='sm' isAttached variant='outline' display="flex" w="100%">
                        <IconButton 
                            aria-label='Delete Entry' 
                            flexGrow={1} 
                            icon={<FiTrash2 />} 
                            borderTopLeftRadius={0} 
                            onClick={(e) => { 
                                setDCM({
                                    title: `Eintrag löschen`,
                                    body: `Sind Sie sicher, dass dieser Eintrag gelöscht werden soll?`,
                                    onConfirm: () => deleteDeficitEntry(deficitId, entry.id)
                                });
                            }}
                        />
                        <IconButton 
                            aria-label='Save Entry Template' 
                            flexGrow={1} 
                            icon={<FiSave />} 
                            isActive={entry.save}
                            _active={{
                                bg: 'blue.200'
                            }}
                            borderRadius={0}
                            onClick={(e) => { setDeficitEntrySave(deficitId, entry.id, !entry.save); }}
                        />
                        <IconButton 
                            aria-label='Edit entry' 
                            flexGrow={4} 
                            icon={<FiEdit2 />} 
                            borderTopRightRadius={0}
                            isActive={false}
                            onClick={(e) => { e.currentTarget.blur(); setOverlay(<ProtocolDeficitEntryEditor deficitId={deficitId} entryId={entry.id} />); }}
                        />
                    </ButtonGroup>
                    */}
                </>
            ))}
            <DeficitEntryAttachments attachments={entry.attachments} />
            <Divider my={"2 !important"} />
        </VStack>
    )
}

const DeficitEntries = ({ deficitId, entries, protocolRef, readOnly }: { deficitId: string, entries: IApiProtocolResponseDeficitEntry[], protocolRef: number, readOnly?: boolean }) => {

    const { addDeficitEntry, deleteDeficitEntry } = useContext(ProtocolContext);
    const { setDCM } = useContext(AppContext);

    return (
        <FormControl>
            <FormLabel htmlFor='entries' style={{ fontSize: '14px' }}>Beschreibung</FormLabel>
            <VStack id='entries' >
                {entries.map((entry) => (<DeficitEntry key={entry.id} deficitId={deficitId} entry={entry} />))}
            </VStack>
            {((entries.findIndex(e => e.source === protocolRef) >= 0) && (
                <Button 
                    leftIcon={<FiTrash2 />} 
                    mt={4} 
                    color='red' 
                    size='sm' 
                    variant='outline'
                    w="100%"
                    onClick={(e) => { 
                        e.stopPropagation();
                        setDCM({
                            title: `Eintrag löschen`,
                            body: `Sind Sie sicher, dass der letzte Eintrag gelöscht werden soll?`,
                            onConfirm: () => deleteDeficitEntry(deficitId, entries.find(e => e.source === protocolRef)!.id)
                        });
                    }}
                    disabled={readOnly}
                >
                    Eintrag entfernen
                </Button>
            )) || (
                <Button 
                    leftIcon={<FiPlus />} 
                    mt={4} 
                    color='green' 
                    size='sm' 
                    variant='outline'
                    w="100%"
                    onClick={() => { addDeficitEntry(deficitId); }}
                    disabled={readOnly}
                >
                    Eintrag hinzufügen
                </Button>
            )}
        </FormControl>
    );
}

const Deficit = ({ deficit, readOnly }: { deficit: IApiProtocolResponseDeficit, readOnly?: boolean }) => {

    const titlePreviewRef = useRef<HTMLSpanElement | null>(null);

    const { protocol, parties, setDeficitResponsibility, setDeficitClosed, setDeficitTitle, deleteDeficit } = useContext(ProtocolContext);
    const { setDCM } = useContext(AppContext);

    const getPartyName = (party: IApiProjectOptionParty): string => {
        if(!party.contact) {
            return "";
        } else {
            return `${party.contact.name.length > 0 ? party.contact.name : `${party.contact.firstName} ${party.contact.lastName}`}`;
        }
    };

    return (
        <AccordionItem key={deficit.id}>
            <h2>
                <AccordionButton>
                    <HStack flex='1'>
                        <Box textAlign='left' fontWeight='semibold' fontSize='18px'>{`[${deficit.source}/${deficit.ref}] ${deficit.title && deficit.title.length > 0 && `(${deficit.title})`}`}</Box>
                        {(deficit.source === protocol.ref) && !readOnly && (
                            <Button leftIcon={<FiTrash2 />} color='red' size='sm' variant='ghost' as={Box}                              
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDCM({
                                            title: `Mangel löschen`,
                                            body: `Sind Sie sicher, dass dieser Mangel gelöscht werden soll?`,
                                            onConfirm: () => deleteDeficit(deficit.id)
                                        });
                                    }}
                            >
                                entfernen
                            </Button> 
                        )}
                    </HStack>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel>
                <VStack spacing={4}>
                    <FormControl>    
                        <FormLabel>Titel (optional)</FormLabel>
                            <Editable 
                                defaultValue={deficit.title && deficit.title.length > 0 ? deficit.title : "- nicht festgelegt -"} 
                                fontSize="md" 
                                fontWeight="normal" 
                                onSubmit={e => {
                                    setDeficitTitle(deficit.id, e);
                                    if(titlePreviewRef.current && e.length === 0) {
                                        titlePreviewRef.current.innerText = "- nicht festgelegt -";
                                    }
                                }}
                                isPreviewFocusable={false}
                                isDisabled={readOnly}
                            >
                                <HStack>
                                    <EditablePreview ref={titlePreviewRef} />
                                    <EditableInput />
                                    {!readOnly && (<EditableEditControls submitButtonSize='md' editButtonSize='sm' />)}
                                </HStack>
                            </Editable> 
                        </FormControl>
                    <FormControl>
                        <FormLabel htmlFor='responsible' style={{ fontSize: '14px' }}>Zuständigkeit</FormLabel>
                        <Select id='responsible' 
                                style={{ fontSize: '14px' }} 
                                defaultValue={deficit.responsible ? deficit.responsible.id : "null"}
                                onChange={(e) => { 
                                    if(e.target.value === "null") {
                                        setDeficitResponsibility(deficit.id, null);
                                    } else {
                                        var party = parties.find(p => p.contact.id === e.target.value);
                                        if(party) {
                                            setDeficitResponsibility(deficit.id, party.contact);
                                        }
                                    }
                                 }}
                                 disabled={readOnly}
                        >
                            <option key={"null"} value="null">{'ALLE'}</option>
                            {parties.map(p => (<option key={p.contact.id} value={p.contact.id}>{getPartyName(p)}</option>))}
                            {(deficit.responsible !== null && parties.map(p => p.contact.id).filter(id => id == deficit.responsible?.id).length === 0 && (
                                <option key={deficit.responsible?.id} value={deficit.responsible?.id}>{deficit.responsible?.name}</option>  
                            ))}
                        </Select>
                    </FormControl>

                    <DeficitEntries deficitId={deficit.id} entries={deficit.entries} protocolRef={protocol.ref} readOnly={readOnly} />

                    <FormControl>
                        <FormLabel htmlFor='status' style={{ fontSize: '12px' }}>Status</FormLabel>
                        <RadioIconButtonGroup 
                            key={`closed-${deficit.id}`} 
                            defaultValue={`${deficit.closed}`}
                            size={'md'}
                            display="flex"
                            mt={2} 
                            onChange={e => { setDeficitClosed(deficit.id, e === "true") }}
                        >
                            <RadioIconButton
                                value="false"
                                aria-label="Align left"
                                icon={<FiX fontSize="1.125rem" />}
                                grow={true}
                                h={8}
                                _checked={{
                                    bg: 'red.200'
                                }}
                                disabled={readOnly}
                            />
                            <RadioIconButton
                                value="true"
                                aria-label="Align center"
                                icon={<FiCheck fontSize="1.125rem" />}
                                grow={true}
                                h={8}
                                _checked={{
                                    bg: 'green.200'
                                }}
                                disabled={readOnly}
                            />
                        </RadioIconButtonGroup>
                    </FormControl>
                </VStack>
            </AccordionPanel>
        </AccordionItem>
    )

}


export const ProtocolDrawerDeficits = (props: IProtocolDrawerDeficitsProps ) => {

    const { protocol, addDeficit } = useContext(ProtocolContext);
    const { deficits } = protocol;

    const [expanded, setExpanded] = useState<ExpandedIndex>(deficits.map((_, i) => i));

    return (
        <Container flexGrow="1" display="flex" flexDirection="column" overflow="hidden" px={2}>
            <Accordion 
                allowMultiple={true} 
                defaultIndex={expanded} 
                index={expanded} 
                flexGrow={1} 
                overflow="auto" 
                onChange={(expandedIndex: ExpandedIndex) => setExpanded(expandedIndex)}
            >
                {deficits.map((deficit) => (<Deficit key={deficit.id} deficit={deficit} readOnly={props.readOnly} />))}
            </Accordion>
            <Divider my={2} />
            <HStack>
                <IconButton aria-label='collapse' 
                            flexGrow={1}
                            variant="outline" 
                            icon={<FiMinimize2 />} 
                            onClick={() => setExpanded([])}
                />
                <IconButton aria-label='expand' 
                            flexGrow={1}
                            variant="outline" 
                            icon={<FiMaximize2 />} 
                            onClick={() => setExpanded(deficits.map((_, i) => i))}
                />
                <Button leftIcon={<FiPlus />} 
                        variant="outline" 
                        flexShrink={0}
                        flexGrow={6}
                        color='red'
                        onClick={() => {
                            addDeficit();
                            setExpanded([deficits.length]);
                        }}
                        disabled={props.readOnly ?? false}
                    >
                    Mangel
                </Button>
            </HStack>
        </Container>
    )
}