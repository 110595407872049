import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, FormLabel, FormErrorMessage, useBreakpointValue, List, ListItem, Editable, HStack, EditablePreview, EditableInput, Box, Button, IconButton, IconButtonProps, useEditableControls } from '@chakra-ui/react'
import { Field, FormikProps } from 'formik';
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IApiSafetyChecklistEditRequest } from '../../models/api/safetychecklist/IApiSafetyChecklistEditRequest';
import { IApiSafetyChecklistCreateRequest } from '../../models/api/safetychecklist/IApiSafetyChecklistCreateRequest';
import { FiArrowDown, FiArrowUp, FiCheck, FiEdit2, FiPlus, FiTrash2 } from 'react-icons/fi';
import EditableEditControls from '../editable/EditableEditControls';
import { IApiSafetyChecklistTopicEntryResponse, IApiSafetyChecklistTopicResponse } from '../../models/api/safetychecklist/IApiSafetyChecklistResponse';

export interface ISafetyChecklistEditorProps {
    topics: IApiSafetyChecklistTopicResponse[];
    onChange(topics: IApiSafetyChecklistTopicResponse[]): void;
}

export const SafetyChecklistEditor = ({ topics, onChange }: ISafetyChecklistEditorProps) => {
    
    const addTopic = () => {
        var topic = {
            id: uuidv4(),
            name: `Thema ${(topics.length + 1)}`,
            entries: []
        };
        if(topics.find(t => t.name === topic.name) !== undefined) {
            var suffix = 1;
            do {
                topic.name = `Thema ${(topics.length + 1)} (${suffix})`;
                suffix++;
            } while(topics.find(t => t.name === topic.name) !== undefined);
        }
        onChange([...topics, topic]);
    };

    const updateTopic = (topic: IApiSafetyChecklistTopicResponse, name: string) => {
        var index = topics.indexOf(topic);
        if(index >= 0) {
            var newTopics = [...topics];
            var topic = { ...newTopics[index] };

            topic.name = name;
            if(newTopics.find(t => t.name === topic.name) !== undefined) {
                var suffix = 1;
                do {
                    topic.name = `Thema ${(newTopics.length + 1)} (${suffix})`;
                    suffix++;
                } while(newTopics.find(t => t.name === topic.name) !== undefined);
            }
            
            newTopics.splice(index, 1, topic);
            onChange(newTopics);
        }
    };

    const deleteTopic = (topic: IApiSafetyChecklistTopicResponse) => {
        var index = topics.indexOf(topic);
        if(index >= 0) {
            var newTopics = [...topics];
            newTopics.splice(index, 1);
            onChange(newTopics);
        }
    };

    const addEntry = (topic: IApiSafetyChecklistTopicResponse) => {
        var index = topics.indexOf(topic);
        if(index >= 0) {
            var entry = {
                id: uuidv4(),
                name: `${topic.name} Kontrollbereich Nr. ${(topic.entries.length + 1)}`,
                value: null,
                default: null
            };
            if(topic.entries.find(e => e.name === entry.name) !== undefined) {
                var suffix = 1;
                do {
                    entry.name = `${topic.name} Kontrollbereich Nr. ${(topic.entries.length + 1)} (${suffix})`;
                    suffix++;
                } while(topic.entries.find(e => e.name === entry.name) !== undefined);
            }

            var newTopics = [...topics];
            newTopics.splice(index, 1, {
                ...topic,
                entries: [...topic.entries, entry]
            });
            onChange(newTopics);
        }
    };

    const updateEntry = (topic: IApiSafetyChecklistTopicResponse, entry: IApiSafetyChecklistTopicEntryResponse, name: string) => {
        var topicIndex = topics.indexOf(topic);
        if(topicIndex >= 0) {
            var entryIndex = topic.entries.indexOf(entry);
            if(entryIndex >= 0) {
                var newTopics = [...topics];
                var newTopic = { ...newTopics[topicIndex], entries: [...newTopics[topicIndex].entries] };
                var newEntry = { ...newTopic.entries[entryIndex] };

                newEntry.name = name;

                newTopic.entries.splice(entryIndex, 1, newEntry);
                newTopics.splice(topicIndex, 1, newTopic);
                onChange(newTopics);
            }
        }
    };

    const deleteEntry = (topic: IApiSafetyChecklistTopicResponse, entry: IApiSafetyChecklistTopicEntryResponse) => {
        var topicIndex = topics.indexOf(topic);
        if(topicIndex >= 0) {
            var entryIndex = topic.entries.indexOf(entry);
            if(entryIndex >= 0) {
                var newTopics = [...topics];
                var newTopic = { ...newTopics[topicIndex], entries: [...newTopics[topicIndex].entries] };
                newTopic.entries.splice(entryIndex, 1);
                newTopics.splice(topicIndex, 1, newTopic);
                onChange(newTopics);
            }
        }
    };

    const moveEntryUp = (topic: IApiSafetyChecklistTopicResponse, entry: IApiSafetyChecklistTopicEntryResponse) => {
        var topicIndex = topics.indexOf(topic);
        if(topicIndex >= 0) {
            var entryIndex = topic.entries.indexOf(entry);
            if(entryIndex >= 0) {
                var newTopics = [...topics];
                var newTopic = { ...newTopics[topicIndex], entries: [...newTopics[topicIndex].entries] };
                newTopic.entries.splice(entryIndex, 1);
                newTopic.entries.splice(entryIndex - 1, 0, entry);
                newTopics.splice(topicIndex, 1, newTopic);
                onChange(newTopics);
            }
        }
    };

    const moveEntryDown = (topic: IApiSafetyChecklistTopicResponse, entry: IApiSafetyChecklistTopicEntryResponse) => {
        var topicIndex = topics.indexOf(topic);
        if(topicIndex >= 0) {
            var entryIndex = topic.entries.indexOf(entry);
            if(entryIndex >= 0) {
                var newTopics = [...topics];
                var newTopic = { ...newTopics[topicIndex], entries: [...newTopics[topicIndex].entries] };
                newTopic.entries.splice(entryIndex, 1);
                newTopic.entries.splice(entryIndex + 1, 0, entry);
                newTopics.splice(topicIndex, 1, newTopic);
                onChange(newTopics);
            }
        }
    };

    return (
        <List listStyleType="none">
          <Stack spacing="3" width="full">
            {topics.map((topic) => (
                <ListItem
                    key={`${topic}-${topic.id}`}
                    value={topic.id}
                    bg="bg-surface"
                    p="4"
                    boxShadow={mode('sm', 'sm-dark')}
                    position="relative"
                    borderRadius="lg"
                >
                    <Stack spacing="3">
                        <Editable 
                            defaultValue={topic.name} 
                            fontSize="lg" 
                            fontWeight="medium" 
                            onSubmit={e => {
                                if(e.length > 0 && e !== topic.name) {
                                    updateTopic(topic, e)
                                }
                            }}
                            isPreviewFocusable={false}
                        >
                            <HStack>
                                <EditablePreview style={{ overflowWrap: "anywhere" }} />
                                <EditableInput />
                                <EditableEditControls submitButtonSize='md' editButtonSize='sm' />
                            </HStack>
                        </Editable>                                
                        <Box borderWidth={{ base: '0', md: '1px' }} p={{ base: '0', md: '4' }} borderRadius="lg">                               
                            <List spacing={1}>
                                {topic.entries.map((entry, entryIndex) => (
                                    <ListItem key={`entry-${entry.id}`}>
                                        <HStack>
                                            <HStack spacing={0}>
                                                <IconButton 
                                                    aria-label='down' 
                                                    size='xs' 
                                                    icon={<FiArrowDown />} 
                                                    variant='ghost'
                                                    isDisabled={entryIndex == topic.entries.length - 1}
                                                    onClick={() => moveEntryDown(topic, entry)}
                                                />
                                                <IconButton 
                                                    aria-label='up' 
                                                    size='xs' 
                                                    icon={<FiArrowUp />} 
                                                    variant='ghost' 
                                                    isDisabled={entryIndex == 0}
                                                    onClick={() => moveEntryUp(topic, entry)}
                                                />
                                            </HStack>
                                            <Editable 
                                                defaultValue={entry.name} 
                                                w='100%'
                                                onSubmit={e => {
                                                    if(e.length === 0) {
                                                        deleteEntry(topic, entry);
                                                    } else if(e != entry.name) {
                                                        updateEntry(topic, entry, e);
                                                    }
                                                }}
                                                isPreviewFocusable={false}
                                            >
                                                <HStack>
                                                    <EditablePreview style={{ overflowWrap: "anywhere" }} />
                                                    <EditableInput />
                                                    <EditableEditControls submitButtonSize='xs' editButtonSize='xs' />
                                                </HStack>
                                            </Editable>
                                            <IconButton 
                                                aria-label='delete' 
                                                size='xs' 
                                                icon={<FiTrash2 /> } 
                                                variant='ghost' 
                                                color='red.500' 
                                                onClick={() => deleteEntry(topic, entry)}
                                            />
                                        </HStack>
                                    </ListItem>
                                ))}
                                <ListItem key={topic.id + "-add"}>
                                    <Button 
                                        leftIcon={<FiPlus />} 
                                        mt={2} 
                                        color='green' 
                                        size='xs' 
                                        variant='outline'
                                        onClick={() => addEntry(topic)}
                                    >
                                        Neuer Eintrag
                                    </Button>
                                </ListItem>
                            </List>
                        </Box>
                        <Button 
                            leftIcon={<FiTrash2 />} 
                            px={8}
                            color='red' 
                            size='sm' 
                            variant='outline'
                            fontWeight='normal'
                            alignSelf={'end'}
                            onClick={() => deleteTopic(topic)}
                        >
                            Thema entfernen
                        </Button>
                    </Stack>
                </ListItem>
            ))}
            <Button 
                leftIcon={<FiPlus />} 
                px={4}
                mb='2 !important'
                mx='0 !important'
                color='green' 
                size='md' 
                variant='outline'
                fontWeight='normal'
                onClick={() => addTopic()}
            >
                Neues Thema
            </Button>
          </Stack>
        </List>
    );
}