import { Icon } from '@chakra-ui/icons';
import { As, Box, Button, ButtonGroup, ButtonProps, Container, useColorModeValue, Modal, Divider, Select ,Image, useColorModeValue as mode, HStack, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon, PinInputField, PinInput, TableCaption, TableContainer, Tfoot, Checkbox, Switch } from '@chakra-ui/react'
import * as React from 'react'
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FiArrowLeft, FiCheck, FiDelete, FiEdit, FiEye, FiFileText, FiImage, FiPlus, FiRefreshCcw, FiRefreshCw, FiRewind, FiSave, FiSkipBack, FiTrash2, FiX } from 'react-icons/fi';
import { AppContext } from '../../App';
import { IApiProtocolResponseDeficitEntry } from '../../models/api/protocol/IApiProtocolResponse';
import { EApiSuggestionSource, IApiSuggestionResponse } from '../../models/api/suggestion/IApiSuggestionResponse';
import { AutoResizeTextarea } from '../AutoSizeTextarea/AutoSizeTextarea';
import { ProtocolContext } from '../drawers/protocol/ProtocolProvider';
import { IImageEditorProps, ImageEditor } from '../ImageEditor/ImageEditor';

const SuggestionsOverlay = ({onClose, onSelect, suggestions}: { onClose: () => void, onSelect: (value: string) => void, suggestions: IApiSuggestionResponse[] }) => {

    return (
        <>
            <VStack bg="bg-surface" spacing={4} overflowY="auto" flexGrow={1} py={4} align="left">
                {suggestions.map((suggestion, i) => (
                    <HStack>
                        <VStack>
                            {suggestion.source === EApiSuggestionSource.Image ? <FiImage /> : <FiFileText />}
                            {/*<Badge fontSize="12px" py="0" px="2">{`${(suggestion.score * 100).toFixed(0)}%`}</Badge>*/}
                        </VStack>
                        <Text key={i} fontSize="md" px="4" fontWeight="medium" color="emphasized" cursor="pointer"
                            _hover={{ color: "blue.600" }}
                            onClick={() => onSelect(suggestion.value)}
                        >
                            {suggestion.value}
                        </Text>
                    </HStack>
                ))}
            </VStack>
            <Button leftIcon={<FiX />} aria-label="close" variant="ghost" fontSize="16px" color={"red"} onClick={onClose}>Schließen</Button>
        </>
    )
}

export const ProtocolDeficitEntryEditor = ({ deficitId, entryId }: { deficitId: string, entryId: string }) => {

    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });  

    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [ieProps, setIEProps] = useState<IImageEditorProps | undefined>(undefined);

    const { 
        protocol,
        setOverlay, 
        addDeficitEntryAttachment, 
        updateDeficitEntryAttachment, 
        deleteDeficitEntryAttachment, 
        restoreDeficitEntryAttachment,
        setDeficitEntryText,
        setDeficitEntrySave,
        getTextSuggestions,
        getImageSuggestions,
    } = useContext(ProtocolContext);
    const { setDCM } = useContext(AppContext);

    const [suggestions, setSuggestions] = useState<IApiSuggestionResponse[]>([]);
    
    const deficit = useMemo(() => protocol && protocol.deficits.find(d => d.id === deficitId), [protocol]);
    const entry = useMemo(() => deficit && deficit.entries.find(e => e.id === entryId), [protocol]);

    const [initialText, setInitialText] = useState("");
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [selectedSuggestion, setSelectedSuggestion] = useState<string | undefined>(undefined);

    const mergeSuggestions = (e: IApiSuggestionResponse[], n: IApiSuggestionResponse[], eFilter?: EApiSuggestionSource): IApiSuggestionResponse[] => {
        var full: IApiSuggestionResponse[];
        if(eFilter === undefined) {
            full = [...e, ...n];
        }
        else {
            full = [...e.filter(s => s.source === eFilter), ...n];
        }

        var unique: IApiSuggestionResponse[] = [];
        full.forEach((item, index, self) => {
            if(unique.findIndex(s => s.value === item.value) < 0) {
                unique.push(self.filter(s => s.value === item.value).sort((a, b) => b.score - a.score).at(0)!);
            }
        });

        return unique.sort((a, b) => b.score - a.score);
        //return full.sort((a, b) => b.score - a.score);
    }

    useEffect(() => {
        getTextSuggestions(deficitId, entryId, "").then(textSuggestions => {
            getImageSuggestions(deficitId, entryId).then(imageSuggestions => {
                setSuggestions(mergeSuggestions(textSuggestions, imageSuggestions));
            });
        });
    }, []);

    if(!deficit || !entry) {
        return <></>
    }

    return (
        <>
            <Text fontSize={'18px'} fontWeight="medium">
                {`Mangel ${deficit.ref} ${deficit.title && deficit.title.length > 0 && `(${deficit.title})`}`}
            </Text>
            <Text fontSize={'16px'} color="muted">
                {`Eintrag ${entry.ref}`}
            </Text>
            <Divider my={2} />

            {(ieProps && (
                <ImageEditor { ...ieProps } />
            )) || (showSuggestions && (
                <SuggestionsOverlay suggestions={suggestions} onClose={() => setShowSuggestions(false)} 
                    onSelect={(value) => {
                        setSelectedSuggestion(value);
                        setShowSuggestions(false);
                        setDeficitEntryText(deficitId, entryId, value)
                    }} 
                />
            )) || (
                <>
                    <VStack spacing={4} visibility={showSuggestions ? "collapse" : undefined}>
                        <FormControl>
                            <FormLabel htmlFor="text">
                                <HStack spacing={2} minH="24px">
                                    <Text>
                                        Beschreibung
                                    </Text>
                                    {(suggestions.length > 0) && (
                                        <Badge colorScheme="green" onClick={() => setShowSuggestions(true)} cursor="pointer">
                                            {`${suggestions.length} Vorschl${suggestions.length === 1 ? "ag" : "äge"}` }
                                        </Badge>
                                    )}
                                </HStack>
                            </FormLabel>
                            <AutoResizeTextarea 
                                size="md"
                                defaultValue={selectedSuggestion ?? entry.text}
                                minH={"120px"}
                                maxH={"240px"}
                                ref={textAreaRef}
                                onClick={(e) => setInitialText(e.currentTarget.value)}
                                onBlur={e => (initialText !== e.currentTarget.value) && setDeficitEntryText(deficitId, entryId, e.currentTarget.value)}
                                onChange={(e) => {
                                    if(e.currentTarget.value.length > 0) {
                                        getTextSuggestions(deficitId, entryId, e.currentTarget.value).then(result => {
                                            setSuggestions(old => mergeSuggestions(old, result, EApiSuggestionSource.Image));
                                        });
                                    }
                                    else {
                                        getImageSuggestions(deficitId, entryId).then(result => {
                                            setSuggestions(mergeSuggestions(result, []));
                                        });
                                    }
                                }}
                            />
                        </FormControl>
                        <Checkbox 
                            alignSelf={"flex-start"} 
                            defaultChecked={entry.save}
                            onChange={e => setDeficitEntrySave(deficitId, entryId, e.currentTarget.checked)}
                        >
                            Beschreibung als Vorlage speichern
                        </Checkbox>
                        <FormControl>
                            <FormLabel htmlFor="images">Fotos</FormLabel>                       
                            <SimpleGrid spacing={{ base: "6px" }} columns={{ base: 4, sm: 5, md: 4}}>
                                {entry.attachments.map(attachment => (
                                    <div key={attachment.id}>
                                        <Image
                                            height={{ base: '100px', sm: '110px', md: '110px'}}
                                            width="full"
                                            objectFit='cover'
                                            src={attachment.thumbnailUrl}
                                            border={"solid"}
                                            borderWidth={"1px"}
                                            borderColor={"gray.200"} 
                                            borderTopRadius={'6px'}
                                            borderBottomRadius={'0px'}
                                            _hover={{ cursor: "pointer" }}
                                            onClick={() => setIEProps({ 
                                                src: attachment.downloadUrl,
                                                onProcess: (result) => {
                                                    setIEProps(undefined);
                                                    updateDeficitEntryAttachment(deficitId, entryId, attachment.id, attachment.blobId, result.dest);
                                                },
                                                onClose: () => {
                                                    setIEProps(undefined);
                                                }
                                            })}
                                        />
                                        <ButtonGroup 
                                            size='sm' 
                                            isAttached 
                                            variant='outline'
                                            borderBottomRadius={'6px'}
                                            borderTopRadius={'0px'}
                                            w="full"
                                        >
                                            <IconButton 
                                                aria-label='delete' 
                                                icon={<FiTrash2 />} 
                                                borderBottomRadius={'6px'}
                                                borderTopRadius={'0px'}
                                                w="50%"
                                                h="26px"
                                                onClick={() => {
                                                    setDCM({
                                                        title: `Foto löschen?`,
                                                        body: `Sind Sie sicher, dass dieses Foto gelöscht werden soll?`,
                                                        onConfirm: () => deleteDeficitEntryAttachment(deficit.id, entry.id, attachment.id)
                                                    });
                                                }}
                                            />
                                            <IconButton 
                                                aria-label='restore' 
                                                icon={<FiRewind />} 
                                                borderBottomRadius={'6px'}
                                                borderTopRadius={'0px'}
                                                w="50%"
                                                h="26px"
                                                onClick={() => {
                                                    setDCM({
                                                        title: `Foto wiederherstellen?`,
                                                        body: `Sind Sie sicher, dass die Originalversion dieses Fotos wiederhergestellt werden soll?`,
                                                        onConfirm: () => restoreDeficitEntryAttachment(deficit.id, entry.id, attachment.id, attachment.blobId)
                                                    });
                                                }}
                                            />
                                        </ButtonGroup>
                                    </div>
                                ))}
                                <IconButton 
                                    aria-label="add"
                                    icon={<FiPlus />} 
                                    borderRadius={'6px'}
                                    h="full"
                                    height={{ base: '100px', sm: '110px', md: '110px'}}
                                    variant='outline'
                                    color='green'
                                    fontSize="lg"
                                    onClick={() => fileInputRef.current?.click()}
                                />
                            </SimpleGrid>
                            <input 
                                type='file'
                                name={"fileInput"}
                                ref={fileInputRef}
                                style={{display: 'none'}} 
                                onChange={(e) => {
                                    if(e.target.files) {
                                        addDeficitEntryAttachment(deficitId, entryId, e.target.files)
                                        .then(() => setTimeout(() => {
                                            getImageSuggestions(deficitId, entryId).then(result => {
                                                setSuggestions(old => mergeSuggestions(old, result, EApiSuggestionSource.Text));
                                            });
                                        }, 6000));
                                    }
                                }}
                            />
                        </FormControl>
                    </VStack>
                    <Box flexGrow={1} visibility={showSuggestions ? "collapse" : undefined} />
                    <Divider my={2} visibility={showSuggestions ? "collapse" : undefined} />
                    <HStack visibility={showSuggestions ? "collapse" : undefined}>
                        <Button leftIcon={<FiArrowLeft />} 
                                variant="outline" 
                                flexShrink={0}
                                flexGrow={6}
                                onClick={() => {
                                    setOverlay(undefined);
                                }
                            }
                        >
                            Zurück
                        </Button>
                    </HStack>
                </>
            )}
        </>
    );
}