import React, { useState, useContext, useEffect, useRef } from "react";
import { FormControl, FormLabel, Button, EditablePreview, EditableInput, Divider, HStack, Icon, Textarea, Input, Switch, InputLeftAddon, InputGroup, InputRightAddon, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus, FiSave, FiKey } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { ApiContext } from "../../contexts/ApiProvider";
import toast from "react-hot-toast";
import { IApiOrganizationResponse } from "../../models/api/organization/IApiOrganizationResponse";
import EditableEditControls from "../../components/editable/EditableEditControls";
import { AutoResizeTextarea } from "../../components/AutoSizeTextarea/AutoSizeTextarea";
import { validate } from "uuid";
import { IApiOrganizationEditRequest } from "../../models/api/organization/IApiOrganizationEditRequest";
import { IApiContactResponse } from "../../models/api/contact/IApiContactResponse";
import { Formik } from "formik";
import { InputFormControl } from "../../components/formcontrols/InputFormControl";
import { SelectFormControl } from "../../components/formcontrols/SelectFormControl";
import { SalutationOptions, TitleOptions } from "../../select-options/SelectOptions";
import { IApiUserResponse } from "../../models/api/users/IApiUserResponse";
import { IApiMeEditRequest } from "../../models/api/me/IApiMeEditRequest";
import { IApiMeResponse } from "../../models/api/me/IApiMeResponse";

interface IProfileProps {

}

const getError = (oldConfig: IApiUserResponse, newConfig: IApiUserResponse): (string | boolean) => {
   if(!newConfig.contact.name) {
    return "Name ist erforderlich!";
   }
   if(!newConfig.contact.street1) {
    return "Adresszeile 1 ist erforderlich!";
   }
   if(!newConfig.contact.zip) {
    return "Postleitzahl ist erforderlich!";
   }
   if(!newConfig.contact.city) {
    return "Ort ist erforderlich!";
   }

   var update = false;

   update ||= (oldConfig.contact.salutation  !== newConfig.contact.salutation);
   update ||= (oldConfig.contact.title       !== newConfig.contact.title);
   update ||= (oldConfig.contact.firstName   !== newConfig.contact.firstName);
   update ||= (oldConfig.contact.lastName    !== newConfig.contact.lastName);
   
   update ||= (oldConfig.contact.name      !== newConfig.contact.name);
   update ||= (oldConfig.contact.street1   !== newConfig.contact.street1);
   update ||= (oldConfig.contact.street2   !== newConfig.contact.street2);
   update ||= (oldConfig.contact.zip       !== newConfig.contact.zip);
   update ||= (oldConfig.contact.city      !== newConfig.contact.city);
   update ||= (oldConfig.contact.phone1    !== newConfig.contact.phone1);
   update ||= (oldConfig.contact.email     !== newConfig.contact.email);
   update ||= (oldConfig.customAddress     !== newConfig.customAddress);
   update ||= (oldConfig.customContact     !== newConfig.customContact);

   return update;
}

export const Profile: React.FunctionComponent<IProfileProps> = (props: IProfileProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { me, setAppShellConfig, setDCM, setMe } = useContext(AppContext);
    const { patchMe, getMePwdTicket } = useContext(ApiContext);

    const submitButtonRef = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: "Benutzerinformationen"
            },
            /*
            subHeading: {
                text: me.email
            },
            */
            rightButton: {
                text: "Speichern",
                icon: FiSave,
                ariaLabel: "speichern",
                variant: "primary",
                onClick: () => {
                    if(submitButtonRef && submitButtonRef.current) {
                        submitButtonRef.current.click();
                    }
                }
            }
        });
    }, [setAppShellConfig, me, navigate]);

    return (
        <Formik
            initialValues={me.user}
            validateOnMount={true}
            onSubmit={(values, formikHelpers) => {
                var checkResult = getError(me.user, values);
                if(typeof checkResult === "string") {
                    toast.error(checkResult);
                    formikHelpers.setSubmitting(false);
                } else if(!checkResult) {
                    toast.success("Einstellungen unverändert!");
                    formikHelpers.setSubmitting(false);
                }
                else {
                    const update = (data: IApiMeEditRequest): Promise<IApiMeResponse> => {
                        var promise = patchMe(data);
                        toast.promise(promise, {
                            loading: `Einstellungen werden aktualisiert...`,
                            success: (result) => {
                                setMe(result);
                                formikHelpers.setSubmitting(false);
                                return `Einstellungen wurden erfolgreich aktualisiert!`;
                            },
                            error: (code) => {
                                var message = `Fehler beim Aktualisieren der Einstellungen! `;
                                switch(code) {
                                    case 400: {
                                        message += `Fehler 400`;
                                        break;
                                    }
                                    case 404: {
                                        message += `Fehler 404`;
                                        break;
                                    }
                                    case 500: {
                                        message += `Fehler 500`;
                                        break;
                                    }
                                }
                                formikHelpers.setSubmitting(false);
                                return message;
                            }
                        });
                        return promise;
                    };
                    setDCM({
                        title: `Einstellungen speichern`,
                        body: `Sind Sie sicher, dass die vorhandenen Einstellungen überschrieben werden sollen?`,
                        onConfirm: () => update(values)
                    });
                }
            }}
            validateOnBlur={true}
        >
            {({ handleSubmit, errors, touched, setFieldValue, values, isSubmitting, isValid }) => (
                <form onSubmit={handleSubmit}>
                    <Stack 
                        spacing="4" 
                        height="full" 
                        direction="column" 
                        maxW={{ base: undefined, lg: "640px" }} 
                        ml={{ base: undefined, lg: "auto" }}
                        mr={{ base: undefined, lg: "auto" }}
                    >
                    <SelectFormControl 
                        field="contact.salutation"
                        label="Anrede"
                        isInvalid={(errors.contact?.salutation && touched.contact?.salutation) ? true : false}
                        disabled={isSubmitting}
                        options={() => SalutationOptions.filter(so => so !== "Firma")}
                        onChange={(e) => {
                            setFieldValue("contact.salutation", e.target.value, true);
                        }}
                        errorMessage={errors.contact?.salutation && errors.contact?.salutation}
                    />
                    <SelectFormControl 
                        field="contact.title"
                        label="Titel"
                        isInvalid={(errors.contact?.salutation && touched.contact?.salutation) ? true : false}
                        disabled={isSubmitting || values.contact?.salutation === "Firma"}
                        options={() => TitleOptions}
                        onChange={(e) => setFieldValue("contact.title", e.target.value, true)}
                        errorMessage={errors.contact?.title && errors.contact?.title}
                    />
                    <InputFormControl 
                        field="contact.firstName"
                        label="Vorname"
                        isInvalid={(errors.contact?.firstName && touched.contact?.firstName) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Vorname ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.contact?.firstName}
                    />
                    <InputFormControl 
                        field="contact.lastName"
                        label="Nachname"
                        isInvalid={(errors.contact?.lastName && touched.contact?.lastName) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Nachname ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.contact?.lastName}
                    />
                    <Divider />
                    <FormControl display='flex' alignItems='center'>
                        <Switch 
                            size='md' 
                            id='use-org-address' 
                            defaultChecked={!values.customAddress} 
                            onChange={e => {
                                setFieldValue("customAddress", !e.target.checked, true);
                                setFieldValue("contact.name", "", true);
                                setFieldValue("contact.street1", "", true);
                                setFieldValue("contact.street2", "", true);
                                setFieldValue("contact.city", "", true);
                                setFieldValue("contact.zip", "", true);
                                setFieldValue("contact.country", "", true);
                            }} 
                        />
                        <FormLabel htmlFor='use-org-address' mb='0' ml={2} fontSize="md">
                            Adresseinstellungen der Organisation verwenden?
                        </FormLabel>
                    </FormControl>
                    <InputFormControl 
                        field="contact.name"
                        label="Firmenname"
                        isInvalid={(errors.contact?.name && touched.contact?.name) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.name}
                    />
                    <InputFormControl 
                        field="contact.street1"
                        label="Adresszeile 1"
                        isInvalid={(errors.contact?.street1 && touched.contact?.street1) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.street1}
                    />
                    <InputFormControl 
                        field="contact.street2"
                        label="Adresszeile 2"
                        isInvalid={(errors.contact?.street2 && touched.contact?.street2) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.street2}
                    />
                    <InputFormControl 
                        field="contact.zip"
                        label="Postleitzahl"
                        isInvalid={(errors.contact?.zip && touched.contact?.zip) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.zip}
                        validate={(value: string) => {
                            let error;
                            if ((value !== null && value !== undefined && value.length > 0) && (value.length < 4 || isNaN(parseInt(value)))) {
                                error = "Ungültige Postleitzahl!";
                            }
                            return error;
                        }}
                    />
                    <InputFormControl 
                        field="contact.city"
                        label="Ort"
                        isInvalid={(errors.contact?.city && touched.contact?.city) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        errorMessage={errors.contact?.city}
                    />
                    <SelectFormControl 
                        field="contact.country"
                        label="Land"
                        isInvalid={(errors.contact?.country && touched.contact?.country) ? true : false}
                        disabled={!values.customAddress || isSubmitting}
                        options={() => [
                            (<option key={"country_none"} value=""></option>),
                            (<option key={"country_at"} value="AT">Österreich</option>),
                            (<option key={"country_de"} value="DE">Deutschland</option>),
                            (<option key={"country_ch"} value="CH">Schweiz</option>),
                        ]}
                        onChange={(e) => setFieldValue("contact.country", e.target.value, true)}
                        errorMessage={errors.contact?.salutation && errors.contact?.salutation}
                    />
                    <Divider />
                    <FormControl display='flex' alignItems='center'>
                        <Switch 
                            size='md' 
                            id='use-org-contact' 
                            defaultChecked={!values.customContact} 
                            onChange={e => {
                                setFieldValue("customContact", !e.target.checked, true);
                                setFieldValue("contact.phone1", "", true);
                                setFieldValue("contact.phoneM", "", true);
                            }} 
                        />
                        <FormLabel htmlFor='use-org-contact' mb='0' ml={2} fontSize="md">
                            Kontakteinstellungen der Organisation verwenden?
                        </FormLabel>
                    </FormControl>
                    <InputFormControl 
                        field="contact.phone1"
                        label="Telefon"
                        isInvalid={(errors.contact?.phone1 && touched.contact?.phone1) ? true : false}
                        disabled={!values.customContact || isSubmitting}
                        errorMessage={errors.contact?.phone1}
                    />
                    <InputFormControl 
                        field="contact.phoneM"
                        label="Mobil"
                        isInvalid={(errors.contact?.phoneM && touched.contact?.phoneM) ? true : false}
                        disabled={!values.customContact || isSubmitting}
                        errorMessage={errors.contact?.phoneM}
                    />
                    <Button disabled={!isValid || isSubmitting} type="submit" ref={submitButtonRef} style={{display: 'none'}} />
                    <Divider />
                    <Button 
                        color='red' 
                        variant='outline' 
                        size='lg' 
                        leftIcon={<FiKey />}
                        onClick={() => {
                            toast.loading(`Bitte warten...`, { duration: 10000 })
                            getMePwdTicket().then(result => window.open(result.url,"_self"));
                        }}
                    >
                        Kennwort ändern
                    </Button>
                    </Stack>
                </form>
            )}
        </Formik>
    );

}