import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme'
import { AuthProvider } from './contexts/AuthProvider';
import { ApiProvider } from './contexts/ApiProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import { StorageProvider } from './contexts/StorageProvider';
import { OrganizationProvider } from './contexts/OrganizationProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = extendTheme(
  {
    colors: { 
      ...proTheme.colors, 
      brand: {
        50: proTheme.colors.gray["100"],
        100: proTheme.colors.gray["200"],
        200: proTheme.colors.gray["300"],
        300: proTheme.colors.gray["400"],
        400: proTheme.colors.gray["500"],
        500: proTheme.colors.gray["600"],
        600: proTheme.colors.gray["700"],
        700: proTheme.colors.gray["800"],
        800: proTheme.colors.gray["900"],
        900: "#11121A"
      },
    },
  },
  proTheme,
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <OrganizationProvider
        baseUri="https://apim-baukg-test.azure-api.net/v1"
        callbackPath='login'
      >
        <Auth0Provider
          domain="auth.baukg.app" // baukgapp.eu.auth0.com
          clientId="3iKQ00SeADv9nrEzUuTUMqf0DQULqLlA"
          redirectUri={"https://my.baukg.app/login"/*"https://my.baukg.app:3000/login" /*window.location.origin*/}
          audience="https://api.baukg.app/"//"https://baukgapp.eu.auth0.com/api/v2/"
          useRefreshTokens={true}
        >
          <AuthProvider 
            logoutRedirectUri={"https://my.baukg.app"/*"https://my.baukg.app:3000" /*window.location.origin*/}
          >
            <ApiProvider
              baseUri="https://apim-baukg-test.azure-api.net/v1"
            >
              <StorageProvider>
                <App />
              </StorageProvider>
            </ApiProvider>
          </AuthProvider>
        </Auth0Provider>
      </OrganizationProvider>
    </ChakraProvider>
  </React.StrictMode>
);
