import {
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  HStack,
  ResponsiveValue,
  IconButton
} from '@chakra-ui/react'
import * as React from 'react'
import { IconType } from 'react-icons'

export interface IAppShellButtonProps {
    text: string;
    ariaLabel: string;
    variant?: ResponsiveValue<"link" | "outline" | (string & {}) | "ghost" | "solid" | "unstyled"> | undefined;
    icon: IconType;
    iconSize?: string | number | undefined;
    onClick(): void;
    disabled?: boolean | undefined;
}

export const AppShellButton: React.FunctionComponent<IAppShellButtonProps> = (props: IAppShellButtonProps) => {

    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const Icon = props.icon;

    return isDesktop ? (
        <Button leftIcon={<Icon fontSize={props.iconSize ?? "1.25rem"} />} aria-label={props.ariaLabel} variant={props.variant} onClick={props.onClick} disabled={props.disabled ?? false}>
            {props.text}
        </Button>
    ) : (
        <IconButton icon={<Icon fontSize={props.iconSize ?? "1.25rem"} />} aria-label={props.ariaLabel} variant={props.variant} onClick={props.onClick} disabled={props.disabled ?? false}/>
    );
    
}