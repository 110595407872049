import { useColorModeValue as mode, Input, Divider, Stack, HStack, InputGroup, IconButton, Button, useBreakpointValue, InputLeftElement, Icon, Skeleton, Text, Tag, TagLeftIcon, VStack, Badge } from '@chakra-ui/react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
  } from '@chakra-ui/react'
import { FiMail } from 'react-icons/fi';

import { IApiContactResponse } from "../../models/api/contact/IApiContactResponse";

export interface IContactSearchResultProps {
    contact: IApiContactResponse;
    onClick?(): void;
}

export const ContactSearchResult = ({ contact, onClick }: IContactSearchResultProps) => (
    <VStack spacing="1" align="start">
        {contact.name.length > 0 && (
            <Text fontWeight="medium" fontSize={{ base: "sm" }}>
                {contact.name}
            </Text>
        )}
        {contact.firstName.length > 0 && contact.lastName.length > 0 && (
            <Text color="muted" fontSize={{ base: "sm" }}>
                {`${contact.firstName} ${contact.lastName}`}
            </Text>
        )}
        <HStack>
            <Tag variant="outline" colorScheme="black" w="full" fontSize={{ base: "xs" }} pl="0" py="2">
                <TagLeftIcon as={FiMail} />
                {contact.email}
            </Tag>
            {([
                <Badge colorScheme={'blue'} size={{ base: "xs" }}>uStrich</Badge>,
                <Badge colorScheme={'yellow'} size={{ base: "xs" }}>AD</Badge>,
                <Badge colorScheme={'purple'} size={{ base: "xs" }}>SharePoint</Badge>,
                <Badge colorScheme={'green'} size={{ base: "xs" }}>BauKG</Badge>,
                <Badge colorScheme={'orange'} size={{ base: "xs", sm: "sm" }}>Benutzer</Badge>,
                <Badge colorScheme={'gray'} size={{ base: "xs" }}>N/A</Badge>
            ][contact.sourceType])}
        </HStack>
    </VStack>
);