import React, { useState, useContext, useEffect } from "react";
import { Container, SimpleGrid, Heading, Button, ButtonGroup, Checkbox, Divider, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus, FiUsers, FiBook, FiCheckSquare, FiLayers, FiPenTool, FiHome } from "react-icons/fi";
import { Route, Routes, useLocation, useNavigate, useParams  } from "react-router-dom";
import { Contacts } from "./Contacts";
import { Trades } from "./Trades";
import { Deficits } from "./Deficits";
import { Checklists } from "./Checklists";
import { Users } from "./Users";
import { AppContext } from "../../App";
import { Organization } from "./Organization";
import Helpers from "../../Helpers";

export interface ISettingsProps {

}

export const Settings: React.FunctionComponent<ISettingsProps> = (props: ISettingsProps) => {
    const navigate = useNavigate();
    const iconSize = useBreakpointValue({ base: "42px", md: "48px" });
    const titleSize = useBreakpointValue({ base: "18px", md: "24px" });

    const location = useLocation();

    const { me, setAppShellConfig } = useContext(AppContext);

    useEffect(() => {
        if(Helpers.matchPath(location.pathname, /^\/settings\/?$/)) {
            setAppShellConfig({
                topHeading: {
                    text: `Einstellungen`
                },
                subHeading: {
                    text: me.organization.identity.name
                },
            });
        }
    }, [setAppShellConfig, me, location]);

    return (
        <Routes>
            <Route path="/contacts"     element={<Contacts />}/>
            <Route path="/trades"       element={<Trades />}/>
            <Route path="/deficits"     element={<Deficits />} />
            <Route path="/checklists"   element={<Checklists />} />
            <Route path="/users"        element={<Users />} />
            <Route path="/organization" element={<Organization />} />
            <Route path=""              element={
                <Container>
                    <SimpleGrid columns={{ base: 2, md: 3 }} /*spacing={{ base: 1, sm: 2, md: 3 }}*/>
                        {[{
                            title: "Organisation",
                            path: "organization",
                            icon: <FiHome size={iconSize} />
                        },{
                            title: "Benutzer",
                            path: "users",
                            icon: <FiUsers size={iconSize} />
                        },{
                            title: "Checklisten",
                            path: "checklists",
                            icon: <FiCheckSquare size={iconSize} />
                        },{
                            title: "Gewerke",
                            path: "trades",
                            icon: <FiLayers size={iconSize} />
                        },{
                            title: "Mängel",
                            path: "deficits",
                            icon: <FiPenTool size={iconSize} />
                        },{
                            title: "Kontakte",
                            path: "contacts",
                            icon: <FiBook size={iconSize} />
                        }].map(link => (
                            <Button key={link.path} variant="ghost" onClick={() => navigate(`/settings/${link.path}`)} h="full" >
                                <VStack spacing={{ base: 4, lg: 6 }} my={{ base: 8, lg: 12 }}>
                                    {link.icon}
                                    <Text fontSize={titleSize}>{link.title}</Text>
                                </VStack>
                            </Button>
                        ))}
                    </SimpleGrid>
                </Container>
            } />
       </Routes>
    );
}