import React, { useState, useContext, useEffect, useRef } from "react";
import { Badge, Select, Box, Button, Tooltip, Checkbox, Divider, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus, FiImage, FiFile } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";
import { ApiContext } from "../../contexts/ApiProvider";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import Helpers from "../../Helpers";
import { ProtocolDrawer } from "../../components/drawers/protocol/ProtocolDrawer";
import { IApiProjectOptionParty } from "../../models/api/project/options/IApiProjectOptionParty";
import { IApiProjectOptionSafetyChecklist } from "../../models/api/project/options/IApiProjectOptionSafetyChecklist";
import { IApiProjectSummaryResponse } from "../../models/api/project/IApiProjectSummaryResponse";
import toast from "react-hot-toast";
import { IApiProtocolSummaryResponse } from "../../models/api/protocol/IApiProtocolSummaryResponse";
import { IApiProtocolSubmissionResponse } from "../../models/api/protocol/IApiProtocolSubmissionResponse";

interface IProjectProtocolsProps {

}

export const ProjectProtocols: React.FunctionComponent<IProjectProtocolsProps> = (props: IProjectProtocolsProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { setAppShellConfig, projects, updateProject, setDCM } = useContext(AppContext);
    const { postProjectProtocol, getProjectParties, getProjectSafetyChecklist, deleteProtocol } = useContext(ApiContext);

    const { projectId } = useParams();
    const project = projects.find(p => p.id === projectId);
    const [selection, setSelection] = useState<string | undefined>(undefined);

    const [parties, setParties] = useState<IApiProjectOptionParty[]>([]);
    const [safetyChecklist, setSafetyChecklist] = useState<IApiProjectOptionSafetyChecklist>([]);

    //const projectRef = useRef<IApiProjectSummaryResponse>();
    //projectRef.current = project;

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Protokolle`
            },
            subHeading: {
                text: `${project?.name} (${project?.ref})`
            },
            rightButton: {
                text: "Protokoll erstellen",
                icon: FiPlus,
                ariaLabel: "erstellen",
                variant: "primary",
                disabled: (project?.protocols.findIndex(p => !p.submittedAt) ?? 0) >= 0,
                onClick: () => {
                    if(project) {
                        var promise = postProjectProtocol(project.id);
                        toast.promise(promise, {
                            loading: `Protokoll wird erstellt...`,
                            success: (protocol) => {
                                updateProject({
                                    ...project,
                                    protocols: [protocol, ...project.protocols]
                                });
                                return `Protokoll erfolgreich erstellt!`;
                            },
                            error: (code) => {
                                var message = `Fehler beim Erstellen des Protokolls! `;
                                    switch(code) {
                                        case 400: {
                                            message += `Fehler 400`;
                                            break;
                                        }
                                        case 404: {
                                            message += `Fehler 404`;
                                            break;
                                        }
                                        case 500: {
                                            message += `Fehler 500`;
                                            break;
                                        }
                                    }
                                return message;
                            }
                        });
                    }
                }
            }
        });
        if(!project) {
            navigate(`/projects`);
        }
    }, [setAppShellConfig, project, navigate]);

    useEffect(() => {
        if(project) {
            getProjectParties(project.id)
            .then(parties => {
                setParties(parties);
                getProjectSafetyChecklist(project.id)
                .then(safetyChecklist => {
                    setSafetyChecklist(safetyChecklist);
                });
            });
        }
    }, [project]);

    if(!project) {
        return <h1>Projekt nicht gefunden!</h1>
    }

    const deleteAction = (protocol: IApiProtocolSummaryResponse) => {
        setDCM({
            title: `Protokoll löschen`,
            body: `Sind Sie sicher, dass dieses Protokoll gelöscht werden soll?`,
            onConfirm: () => {
                var promise = deleteProtocol(protocol.id);
                toast.promise(promise, {
                    loading: `Protokoll wird gelöscht...`,
                    success: (protocols) => {
                        updateProject({
                            ...project,
                            protocols
                        });
                        return `Das Protokoll wurde erfolgreich gelöscht!`;
                    },
                    error: (code) => {
                        var message = `Fehler beim Löschen des Protokolls! `;
                            switch(code) {
                                case 400: {
                                    message += `Fehler 400`;
                                    break;
                                }
                                case 404: {
                                    message += `Fehler 404`;
                                    break;
                                }
                                case 500: {
                                    message += `Fehler 500`;
                                    break;
                                }
                            }
                        return message;
                    }
                });
                return promise;
            }
        });
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            <ResponsiveTable 
                data={project.protocols}
                onRowClick={(row) => {
                    setSelection(row.original.id);
                }}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('ref', {
                        header: () => 'Referenz',
                        cell: info => info.getValue(),
                    }),
                    columnHelper.accessor('createdAt', {
                        header: () => 'Erstellt am',
                        cell: info => Helpers.formatDate(info.getValue())
                    }),
                    columnHelper.accessor('submittedAt', {
                        header: () => 'Status',
                        cell: info => {
                            const submissionDate = info.getValue();
                            const submitted = submissionDate !== null;
                            return (
                                <>
                                    <Badge colorScheme={submitted ? 'green' : 'red'} size="sm">
                                        {submitted ? 'abgeschlossen' : 'offen'}
                                    </Badge>
                                    {submitted && (
                                        <Badge colorScheme={'blue'} size="sm" ml={2}>
                                            {`versendet am ${Helpers.formatDate(submissionDate)}`}
                                        </Badge>
                                    )}
                                </>
                            )
                        }
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const protocol = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">
                                    {(protocol.submittedAt === null) && (
                                        <>
                                            <IconButton
                                                icon={<FiEdit2 fontSize="1.125rem" />}
                                                variant="ghost"
                                                aria-label="Bearbeiten"
                                                disabled={!project.active}
                                                onClick={(e) => { 
                                                    e.stopPropagation(); 
                                                    setSelection(protocol.id);
                                                }}
                                            />
                                            <IconButton
                                                icon={<FiTrash2 fontSize="1.125rem" />}
                                                variant="ghost"
                                                aria-label="löschen"
                                                disabled={!project.active}
                                                onClick={(e) => { 
                                                    e.stopPropagation(); 
                                                    deleteAction(protocol);
                                                }}
                                            />
                                        </>
                                    )}
                                    {(protocol.submittedAt !== null) && (
                                        <>
                                            <Tooltip label="Fotos herunterladen">
                                                <IconButton
                                                    icon={<FiImage fontSize="1.125rem" />}
                                                    variant="ghost"
                                                    aria-label="download attachments"
                                                    disabled={!protocol.attachmentsUrl}
                                                    onClick={(e) => { 
                                                        e.stopPropagation(); 
                                                        window.open(protocol.attachmentsUrl!, "_blank")
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip label="Dokumente herunterladen">
                                                <IconButton
                                                    icon={<FiFolder fontSize="1.125rem" />}
                                                    variant="ghost"
                                                    aria-label="download documents"
                                                    disabled={!protocol.documentsUrl}
                                                    onClick={(e) => { 
                                                        e.stopPropagation(); 
                                                        window.open(protocol.documentsUrl!, "_blank")
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip label="Protokoll herunterladen">
                                                <IconButton
                                                    icon={<FiFile fontSize="1.125rem" />}
                                                    variant="ghost"
                                                    aria-label="download protocol"
                                                    disabled={!protocol.protocolUrl}
                                                    onClick={(e) => { 
                                                        e.stopPropagation(); 
                                                        window.open(protocol.protocolUrl!, "_blank")
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    )}                        
                                </HStack>
                            );
                        },
                    }),
                ]}
                getCard={(protocol) => ({
                    key: protocol.id,
                    value: protocol.id,
                    onClick: () => setSelection(protocol.id),
                    content: (
                        <HStack spacing="4" justifyContent="space-between">
                            <VStack spacing="2" align="left">
                                <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                    {protocol.ref}
                                </Text>
                                <HStack>
                                    <Text color="muted" fontSize={{ base: "xs", md: "sm" }}>
                                        {`Referenz: ${protocol.ref}`}
                                    </Text>
                                    <>
                                        <Badge colorScheme={protocol.submittedAt !== null ? 'green' : 'red'} size="sm">
                                            {protocol.submittedAt !== null ? 'abgeschlossen' : 'offen'}
                                        </Badge>
                                        {(protocol.submittedAt !== null) && (
                                            <Badge colorScheme={'blue'} size="sm" ml={2}>
                                                {`versendet am ${Helpers.formatDate(protocol.submittedAt.toString())}`}
                                            </Badge>
                                        )}
                                    </>
                                </HStack>
                            </VStack>
                            <HStack spacing="4" justifyContent="end">
                                {(protocol.submittedAt === null) && (
                                    <IconButton
                                        icon={<FiEdit2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Bearbeiten"
                                        disabled={!project.active}
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            setSelection(protocol.id);
                                        }}
                                    />
                                )}
                                {(protocol.submittedAt !== null) && (
                                        <>
                                            <IconButton
                                                icon={<FiFile fontSize="1.125rem" />}
                                                variant="ghost"
                                                aria-label="download protocol"
                                                disabled={!protocol.protocolUrl}
                                                onClick={(e) => { 
                                                    e.stopPropagation(); 
                                                    window.open(protocol.protocolUrl!, "_blank")
                                                }}
                                            />
                                        </>
                                    )}
                            </HStack>
                        </HStack>
                    )
                })}
            />
            <ProtocolDrawer 
                project={project} 
                protocolId={selection} 
                onClose={() => setSelection(undefined)} 
                parties={parties}
                safetyChecklist={safetyChecklist}    
                onSubmit={(result: IApiProtocolSubmissionResponse) => {
                    var protocols = [...project.protocols];
                    var index = protocols.findIndex(p => p.id === selection);
                    if(index >= 0) {
                        protocols.splice(index, 1, { ...protocols[index], ...result });
                        updateProject({ ...project, protocols })
                    }
                    console.log(selection);
                    setSelection(undefined);
                }}
            />
        </Stack>
    );

}