import { createContext, JSXElementConstructor, ReactElement, useContext, useEffect, useMemo, useRef, useState } from "react";
import { BlobServiceClient, AnonymousCredential } from "@azure/storage-blob";
import { ApiContext } from "./ApiProvider";
import { IApiFileListResponse } from "../models/api/file/IApiFileListResponse";
import { AppContext } from "../App";
import { IApiFileDataEditResponse } from "../models/api/file/IApiFileDataEditResponse";
import { IApiFileRestoreEditResponse } from "../models/api/file/IApiFileRestoreEditResponse";

interface IStorageContext {
    uploadDocuments(files: FileList, projectId: string): Promise<IApiFileListResponse[]>;
    uploadImages(files: FileList, projectId: string): Promise<IApiFileListResponse[]>;
    uploadDocument(file: File, projectId: string): Promise<IApiFileListResponse>;
    uploadImage(file: File, projectId: string): Promise<IApiFileListResponse>;
    updateImage(blobId: string, file: File): Promise<IApiFileDataEditResponse>;
    restoreImage(blobId: string): Promise<IApiFileRestoreEditResponse>;
}

export const StorageContext = createContext({} as IStorageContext);

export const StorageProvider = (props: { 
    children: ReactElement<any, string | JSXElementConstructor<any>>
}) => {

    const { postFile, patchFileData, patchFileRestore } = useContext(ApiContext);

    function uploadBlob(folderName: string, file: File, projectId: string): Promise<IApiFileListResponse> {
        return new Promise((resolve, reject) => {
            postFile(folderName, file.name, projectId, file.type).then(blobInfo => {
                const blobServiceClient = new BlobServiceClient(`https://${blobInfo.account}.blob.core.windows.net?${blobInfo.token}`, new AnonymousCredential());
                const containerClient = blobServiceClient.getContainerClient(blobInfo.organizationId);
                const blockBlobClient = containerClient.getBlockBlobClient(`${blobInfo.folderName}/${blobInfo.id}`);
                blockBlobClient.uploadData(file).then(result => {
                    resolve({
                        id: blobInfo.id,
                        folderName: blobInfo.folderName,
                        fileName: file.name.split('.').reverse().pop() ?? "",
                        fileExtension: file.name.split('.').pop() ?? "",
                        createdAt: (new Date()),
                        creator: ""
                    });
                }, error => {
                    console.log(error)
                    reject(null);
                });
            });
        });
    }

    function updateBlob(folderName: string, blobId: string, file: File): Promise<IApiFileDataEditResponse> {
        return new Promise((resolve, reject) => {
            patchFileData(folderName, blobId).then(dataEditResponse => {
                const blobServiceClient = new BlobServiceClient(`https://${dataEditResponse.account}.blob.core.windows.net?${dataEditResponse.token}`, new AnonymousCredential());
                const containerClient = blobServiceClient.getContainerClient(dataEditResponse.organizationId);
                const blockBlobClient = containerClient.getBlockBlobClient(`${dataEditResponse.folderName}/${dataEditResponse.id}`);
                blockBlobClient.uploadData(file).then(uploadResult => {
                    resolve(dataEditResponse);
                }, error => {
                    console.log(error)
                    reject(null);
                });
            });
        });
    }

    function restoreBlob(folderName: string, blobId: string): Promise<IApiFileRestoreEditResponse> {
        return patchFileRestore(folderName, blobId);
    }

    function uploadFileToContainer(file: File, folderName: string, projectId: string): Promise<IApiFileListResponse> {
        return uploadBlob(folderName, file, projectId);
    }

    async function uploadFileListToContainer(files: FileList, folderName: string, projectId: string): Promise<IApiFileListResponse[]> {
        var result: IApiFileListResponse[] = []; 
        for(var i = 0; i < files.length; i++) {
            var file = files.item(i);
            if(file) {
                var blobInfo = await uploadFileToContainer(file, folderName, projectId);
                if(blobInfo) {
                    result.push(blobInfo);
                }
            }
        }
        return result;
    }

    function uploadDocuments(files: FileList, projectId: string): Promise<IApiFileListResponse[]> {
        return uploadFileListToContainer(files, "documents", projectId);
    }

    function uploadImages(files: FileList, projectId: string): Promise<IApiFileListResponse[]> {
        return uploadFileListToContainer(files, "images", projectId);
    }

    function uploadDocument(file: File, projectId: string): Promise<IApiFileListResponse> {
        return uploadFileToContainer(file, "documents", projectId);
    }

    function uploadImage(file: File, projectId: string): Promise<IApiFileListResponse> {
        return uploadFileToContainer(file, "images", projectId);
    }

    function updateImage(blobId: string, file: File): Promise<IApiFileDataEditResponse> {
        return updateBlob("images", blobId, file);
    }

    function restoreImage(blobId: string): Promise<IApiFileRestoreEditResponse> {
        return restoreBlob("images", blobId);
    }

    return (
        <StorageContext.Provider
            value={{
                uploadDocuments,
                uploadImages,
                uploadDocument,
                uploadImage,
                updateImage,
                restoreImage
            }}
        >
            { props.children }
        </StorageContext.Provider>
    );
};