import { Box, BoxProps, Stack, Text } from '@chakra-ui/react'
import * as React from 'react'

export interface IStep {
    title: string;
    description: string;
}

interface StepProps extends BoxProps {
  title: string
  description: string
  isCompleted: boolean
  isActive: boolean
}

export const Step = (props: StepProps) => {
  const { title, description, isActive, isCompleted, ...boxProps } = props
  return (
    <Box
      flex="1"
      py={{ base: '2', md: '3' }}
      ps={{ base: '1', md: '0' }}
      borderTopWidth={'4px'}
      borderLeftWidth={'0'}
      borderColor={isActive || isCompleted ? 'accent' : 'inherit'}
      {...boxProps}
    >
      <Stack spacing="0.5">
        <Text color="emphasized" fontWeight="medium" fontSize={{ base: '12px', md: '16px' }}>
          {title}
        </Text>
        <Text color="muted" fontSize={{ base: '10px', md: '14px' }}>{description}</Text>
      </Stack>
    </Box>
  )
}
