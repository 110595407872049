import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, useDisclosure } from '@chakra-ui/react'
import * as React from 'react'
import { Logo } from './Logo'
import { Sidebar } from './Sidebar'
import { ToggleButton } from './ToggleButton'

export const Navbar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Box width="full" py="4" px={{ base: '4', md: '8' }} bg="bg-accent">
      <Flex justify="space-between">
        <Logo />
        <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} zIndex="unset !important" />
        <Drawer
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          //isFullHeight -> disabled to fix "mobile browser problem" -> added h="full" to container
          preserveScrollBarGap
          // Only disabled for showcase
          //trapFocus={false}
        >
          <DrawerOverlay />
          <DrawerContent h="full">
            <Sidebar onClose={onClose} isOpen={isOpen} />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  )
}
