import React, { useState, useContext, useEffect } from "react";
import { Badge, Select, Box, Button, ButtonGroup, Checkbox, Divider, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, List, ListItem, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";

interface IHomeProps {

}

export const Home: React.FunctionComponent<IHomeProps> = (props: IHomeProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { me, setAppShellConfig, projects } = useContext(AppContext);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: "Übersicht"
            },
            subHeading: {
                text: me.organization.identity.name
            },
        });
    }, [setAppShellConfig, me, navigate]);

    return (
        <Stack spacing="4" height="full" direction="column">
            
        </Stack>
    );

}