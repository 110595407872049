import React from "react";
import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, FormLabel, FormErrorMessage, Select } from '@chakra-ui/react'
import { Field } from "formik";

interface ISelectFormControlProps<T> {

    field: keyof T;
    label: string;
    isInvalid: boolean;
    disabled: boolean;

    errorMessage: string | undefined;
    helperText?: string;

    options(): React.ReactNode[];
    validate?(value: string): string | undefined;
    onChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
}

export const SelectFormControl = <T,>(props: ISelectFormControlProps<T>) => {
    return (
        <FormControl isInvalid={props.isInvalid}>
            <FormLabel htmlFor={props.field.toString()}>{props.label}</FormLabel>
            <Field
                as={Select}
                id={props.field}
                name={props.field}
                disabled={props.disabled}
                onChange={props.onChange}
                validate={props.validate}
                background={undefined}
            >
                {props.options()}
            </Field>
            {(props.isInvalid && props.errorMessage && (
                <FormErrorMessage>{props.errorMessage}</FormErrorMessage>
            )) || (props.helperText && (
                <FormHelperText>{props.helperText}</FormHelperText>
            ))}
        </FormControl>
    );
}