import { IconButton, IconButtonProps, useEditableControls } from '@chakra-ui/react'
import { FiCheck, FiEdit2 } from 'react-icons/fi';


export default function EditableEditControls({ submitButtonSize, editButtonSize }: { submitButtonSize: string; editButtonSize: string; }) {
    const {
      isEditing,
      getEditButtonProps,
      getSubmitButtonProps
    } = useEditableControls();

    return isEditing ? (
        <IconButton size={submitButtonSize} variant='ghost' icon={<FiCheck />} {...getSubmitButtonProps() as IconButtonProps} />
    ) : (
        <IconButton size={editButtonSize} variant='ghost' icon={<FiEdit2 />} {...getEditButtonProps() as IconButtonProps} />
    );
}