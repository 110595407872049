import { Stack, useBreakpointValue, FormControl, FormLabel } from '@chakra-ui/react'
import * as React from 'react'


import { IApiSafetyChecklistCreateRequest } from '../../../models/api/safetychecklist/IApiSafetyChecklistCreateRequest';
import { IBaseDrawerProps } from '../BaseDrawer';
import { FormikProps } from 'formik';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { SafetyChecklistEditor } from '../../SafetyChecklistEditor/SafetyChecklistEditor';

export interface IAddChecklistDrawerProps extends Omit<IBaseDrawerProps<IApiSafetyChecklistCreateRequest>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText"> {
    defaultValue?: IApiSafetyChecklistCreateRequest;
}

export const AddChecklistDrawer = (props: IAddChecklistDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.isOpen}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue ?? {
                id: "",
                name: "",
                description: "",
                topics: []
            }}
            headerText="Checkliste erstellen"
            drawerSize={'lg'}
            validateOnBlur={false}
        >
            {({ handleSubmit, errors, touched, isSubmitting, values, setFieldValue }: FormikProps<IApiSafetyChecklistCreateRequest>) => (
                <Stack px="0" spacing="4">
                    <InputFormControl 
                        field="name"
                        label="Name"
                        isInvalid={(errors.name && touched.name) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Name ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.name}
                    />
                    <InputFormControl 
                        field="description"
                        label="Beschreibung"
                        isInvalid={(errors.description && touched.description) ? true : false}
                        disabled={isSubmitting}
                        errorMessage={errors.description}
                    />
                    <FormControl>
                        <FormLabel htmlFor="checklist">Themen & Punkte</FormLabel>
                        <SafetyChecklistEditor topics={values.topics} onChange={(topics) => setFieldValue("topics", topics, false)} />
                    </FormControl>
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}