import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, Container, Divider, useBreakpointValue, HStack, IconButton, Button } from '@chakra-ui/react'
import { Field, FormikProps } from 'formik';
import * as React from 'react'
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
  } from '@chakra-ui/react'
import { IApiProjectSummaryResponse } from '../../../models/api/project/IApiProjectSummaryResponse';
import { IApiProtocolResponse } from '../../../models/api/protocol/IApiProtocolResponse';
import { FiX } from 'react-icons/fi';
import { ProtocolProvider } from './ProtocolProvider';
import { useStep } from '../../StepsWithAccent/useStep';
import { StepsWithAccent } from '../../StepsWithAccent/StepsWithAccent';
import { ProtocolDrawerGeneral } from './ProtocolDrawerGeneral';
import { ProtocolDrawerChecklist } from './ProtocolDrawerChecklist';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ApiContext } from '../../../contexts/ApiProvider';
import { ProtocolDrawerSummary } from './ProtocolDrawerSummary';
import { IApiProjectOptionParty } from '../../../models/api/project/options/IApiProjectOptionParty';
import { IApiProjectOptionSafetyChecklist } from '../../../models/api/project/options/IApiProjectOptionSafetyChecklist';
import { ProtocolDrawerDeficits } from './ProtocolDrawerDeficits';
import { ImageEditor } from '../../ImageEditor/ImageEditor';
import { IApiProtocolSubmissionResponse } from '../../../models/api/protocol/IApiProtocolSubmissionResponse';
import Helpers from '../../../Helpers';
import { ProtocolDrawerRecipients } from './ProtocolDrawerRecipients';

export interface IProtocolDrawerProps {
    project: IApiProjectSummaryResponse;
    protocolId: string | undefined;
    onClose(): void;
    onSubmit(result: IApiProtocolSubmissionResponse): void;
    parties: IApiProjectOptionParty[];
    safetyChecklist: IApiProjectOptionSafetyChecklist;
}

export const ProtocolDrawer = (props: IProtocolDrawerProps) => {
    const isPhone = useBreakpointValue({ base: true, md: false });

    const numberOfSteps = 4;
    const [step, { setStep }] = useStep({ maxStep: numberOfSteps });

    useEffect(() => {
        if(props.protocolId) {
            setStep(0);
        }
    }, [props.protocolId])

    return (
        <Drawer
            isOpen={props.protocolId !== undefined}
            placement='right'
            onClose={props.onClose}
            size={isPhone ? 'full' : 'md'}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <DrawerOverlay />
            <ProtocolProvider {...props}>
                {({ protocol, safetyChecklistStatistics, overlay }) => (
                <DrawerContent h="full">
                    <DrawerHeader px={4}>
                        <HStack justifyContent="space-between" minH={"40px"}>
                            <span>
                                {`Protokoll ${protocol.ref}`}
                            </span>
                            {!overlay && (
                                <IconButton
                                    icon={<FiX fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Discard"
                                    onClick={props.onClose}
                                />
                            )}
                        </HStack>
                    </DrawerHeader>
                    <Divider />
                    <DrawerBody py={'8px'} px={'0'} style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }} position={overlay ? "relative" : undefined}>
                        <StepsWithAccent currentStep={step} setStep={setStep} steps={[
                            ((!protocol.submittedAt) ? {
                                title: 'Allgemein',
                                description: (protocol.date && protocol.time) ? `${Helpers.formatDate(protocol.date!).substring(0,6)} | ${protocol.time}` : "-",
                            } : {
                                title: 'Empfänger',
                                description: protocol.recipients.length.toString(),
                            }),
                            {
                                title: 'Checkliste',
                                description: `${safetyChecklistStatistics[0]} / ${safetyChecklistStatistics[1]} / ${safetyChecklistStatistics[2]}`,
                            },
                            {
                                title: 'Mängel',
                                description: protocol.deficits.length.toString(),
                            },
                            {
                                title: 'Übersicht',
                                description: `${props.project.ref} / ${protocol.ref}`,
                            }                     
                        ]} />
                        <Divider />
                        {[
                            ((!protocol.submittedAt) && (
                                <ProtocolDrawerGeneral readOnly={protocol.submittedAt !== null} />
                            )) || (
                                <ProtocolDrawerRecipients />
                            ),
                            <ProtocolDrawerChecklist readOnly={protocol.submittedAt !== null} />,
                            <ProtocolDrawerDeficits readOnly={protocol.submittedAt !== null} />,
                            <ProtocolDrawerSummary readOnly={protocol.submittedAt !== null} onSubmit={props.onSubmit} />
                        ][step]}
                        {(overlay && (
                            <Container 
                                h="full" 
                                w="full" 
                                position="absolute" 
                                top={0} 
                                left={0} 
                                overflowY="auto" 
                                backgroundColor="white"
                                px={4}
                                py={2}
                                display="flex"
                                flexDirection="column"
                            >
                                {overlay}
                            </Container>
                        ))}
                    </DrawerBody>
                </DrawerContent>
                )}
            </ProtocolProvider>
        </Drawer>
    );
}