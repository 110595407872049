import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { Field, FormikProps } from 'formik';
import * as React from 'react'
import { IApiFileInfoEditRequest } from '../../../models/api/file/IApiFileInfoEditRequest';

import { IApiTradeEditRequest } from '../../../models/api/trade/IApiTradeEditRequest';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { IBaseDrawerProps } from '../BaseDrawer';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';

export interface IEditDocumentDrawerProps extends Omit<IBaseDrawerProps<IApiFileInfoEditRequest>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText" | "isOpen"> {
    defaultValue: IApiFileInfoEditRequest | undefined;
}

export const EditDocumentDrawer = (props: IEditDocumentDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.defaultValue !== undefined}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue}
            headerText="Dokument bearbeiten"
        >
            {({ handleSubmit, errors, touched, isSubmitting }: FormikProps<IApiFileInfoEditRequest>) => (
                <Stack px="0" spacing="4">
                    <InputFormControl 
                        field="fileName"
                        label="Name"
                        isInvalid={(errors.fileName && touched.fileName) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Dateiname ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.fileName}
                    />
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}