export enum EApiContactSource {
    UStrich,
    AD,
    SharePoint,
    BauKG,
    User,
    NotApplicable
}

export interface IApiContactResponse {
    id: string;
    sourceType: EApiContactSource;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    salutation: string;
    title: string;
    phone1: string;
    phoneM: string;
    street1: string;
    street2: string;
    city: string;
    zip: string;
    country: string;
}