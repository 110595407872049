import { Center, Box, Button, Container, Spinner, useColorModeValue, BoxProps, Divider, Textarea ,Flex, Heading, useColorModeValue as mode, HStack, Icon, IconButton, IconButtonProps, Input, Stack, Text, useEditableControls, VStack, StackDivider, InputGroup, InputLeftElement, useBreakpointValue, Badge, Table, Tbody, Td, Th, Thead, Tr, ListItem, List, Square, UnorderedList, EditableInput, Editable, EditablePreview, ListIcon, useEditableState, SimpleGrid, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Tag, TagLeftIcon, DrawerCloseButton, Portal, AccordionPanel, AccordionItem, Accordion, AccordionButton, AccordionIcon } from '@chakra-ui/react'
import * as React from 'react'
import { useContext, useState } from 'react';
import { BsTropicalStorm } from 'react-icons/bs';
import { FiEdit2, FiMail, FiCheck, FiX, FiSquare, FiEyeOff, FiRefreshCw, FiSettings } from 'react-icons/fi';
import { IApiSafetyChecklistTopicEntryResponse } from '../../../models/api/safetychecklist/IApiSafetyChecklistResponse';
import { RadioIconButton, RadioIconButtonGroup } from '../../RadioIconButtonGroup/RadioIconButtonGroup';
import { ProtocolContext } from './ProtocolProvider';

interface IChecklistEntryProps {
    entry: IApiSafetyChecklistTopicEntryResponse;
    index: number;
    onChange(value: boolean | null): void;
    disabled?: boolean;
}

const ChecklistEntry = (props: IChecklistEntryProps ) => {

    return (
        <Container maxW="lg" px={'0'} mt={props.index > 0 ? 6 : 0}>
            <h6>
                <Box flex='1' textAlign='left' fontWeight='normal' fontSize='14px'>{props.entry.name}</Box>    
            </h6>
            <RadioIconButtonGroup 
                key={props.entry.id} 
                defaultValue={`${props.entry.value ?? 'null'}`} 
                //value={`${props.entry.value ?? 'null'}`} 
                size={'sm'} 
                display="flex" 
                mt={2} 
                onChange={e => {
                    switch(e) {
                        case "false": { props.onChange(false); break; }
                        case "true": { props.onChange(true); break; }
                        case "null": { props.onChange(null); break; }
                    }
                }}
                isDisabled={props.disabled ?? false}
            >
                <RadioIconButton
                    value="false"
                    aria-label="Align left"
                    icon={<FiX fontSize="1.125rem" />}
                    grow={true}
                    _checked={{
                        bg: 'red.200'
                    }}
                />
                <RadioIconButton
                    value="true"
                    aria-label="Align center"
                    icon={<FiCheck fontSize="1.125rem" />}
                    grow={true}
                    _checked={{
                        bg: 'green.200'
                    }}
                />
                <RadioIconButton
                    value="null"
                    aria-label="Align right"
                    icon={<FiEyeOff fontSize="1.125rem" />}
                    grow={true}
                />
            </RadioIconButtonGroup>
        </Container>
    );
}

export interface IProtocolDrawerChecklistProps {
    readOnly?: boolean;
}

export const ProtocolDrawerChecklist = (props: IProtocolDrawerChecklistProps ) => {

    const { protocol, setChecklistEntry, refreshChecklist } = useContext(ProtocolContext);
    const [resetting, setResetting] = useState(false);
    
    if(resetting) {
        return (
            <Container display="flex" alignItems="center" justifyContent="center" h="full"><Spinner size="lg"/></Container>
        );
    }

    return (
        <Accordion allowMultiple={true} defaultIndex={protocol.safetyChecklist.map((_, i) => i)} overflow="auto">
            {protocol.safetyChecklist.map((topic, topicIndex) => (
                <AccordionItem key={`topic-${topic.id}`}>
                    <h2>
                        <AccordionButton>
                            <Box flex='1' textAlign='left' fontWeight='semibold' fontSize='16px'>{topic.name}</Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel>
                    {topic.entries.map((entry, entryIndex) => (
                        <ChecklistEntry 
                            key={`entry-${entry.id}`}
                            index={entryIndex} 
                            entry={entry} 
                            onChange={(value) => setChecklistEntry(topic.id, entry.id, value) }
                            disabled={props.readOnly ?? false}
                        />
                    ))}
                    </AccordionPanel>
                </AccordionItem>
            ))}
            {!(props.readOnly ?? false) && (
                <AccordionItem key={`reload-template`}>
                    <AccordionButton>
                        <Box flex='1' textAlign='left' fontWeight='normal' fontSize='16px'>[Optionen]</Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel>
                        <Button leftIcon={<FiRefreshCw />} 
                                variant="outline" 
                                size="lg"
                                w="100%"
                                mt="1"
                                color='red' 
                                onClick={() => {
                                    setResetting(true)
                                    refreshChecklist().then(() => setResetting(false));
                                }}
                        >
                            Mit aktueller Vorlage überschreiben
                        </Button>
                    </AccordionPanel>
                </AccordionItem>
            )}
        </Accordion>
    )
}