import React, { useState } from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, ResponsiveValue } from '@chakra-ui/react'

interface IDeleteConfirmationModalButtonProps {
    variant?: ResponsiveValue<(string & {}) | "link" | "outline" | "ghost" | "solid" | "unstyled"> | undefined;
    size?: ResponsiveValue<"sm" | "md" | "lg" | (string & {}) | "xs"> | undefined;
    colorScheme?: (string & {}) | "blue" | "whiteAlpha" | "blackAlpha" | "gray" | "red" | "orange" | "yellow" | "green" | "teal" | "cyan" | "purple" | "pink" | "linkedin" | "facebook" | "messenger" | "whatsapp" | "twitter" | "telegram" | undefined;
}

export interface IDeleteConfirmationModalConfig {
    onConfirm?(): Promise<any>;
    onAbort?(): Promise<any>;

    title?: string | undefined;
    body?: string | React.ReactNode | undefined;
    confirmText?: string | undefined;
    abortText?: string | undefined;
    confirmProps?: IDeleteConfirmationModalButtonProps | undefined;
    abortProps?: IDeleteConfirmationModalButtonProps | undefined;
}

export interface IDeleteConfirmationModalProps extends IDeleteConfirmationModalConfig {
    clear(): void;
}

export const DeleteConfirmationModal = (props: IDeleteConfirmationModalProps) => {
    const isOpen = (props.title || props.body || props.abortProps || props.confirmProps || props.abortText || props.confirmText) !== undefined;
    const [isLocked, setIsLocked] = useState(false);

    const abort = () => {
        if(props.onAbort !== undefined) {
            setIsLocked(true);
            props.onAbort().then(() => {
                setIsLocked(false);
                props.clear();
            })
        } else {
            setIsLocked(false);
            props.clear();
        }        
    };
    const confirm = () => {
        if(props.onConfirm !== undefined) {
            setIsLocked(true);
            props.onConfirm().then(() => {
                setIsLocked(false);
                props.clear();
            })
        } else {
            setIsLocked(false);
            props.clear();
        }   
    };

    return (
      <Modal isOpen={isOpen} onClose={abort}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {props.body}
          </ModalBody>
          <ModalFooter>
            <Button {...props.abortProps} onClick={abort} disabled={isLocked}>{props.abortText ?? "Abbrechen"}</Button>
            <Button {...props.confirmProps} onClick={confirm} disabled={isLocked}>{props.confirmText ?? "Bestätigen"}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
}