import React, { useState, useContext, useEffect } from "react";
import { Badge, Checkbox, HStack, Icon, IconButton, Input, InputGroup, InputLeftElement, Stack, Text, useBreakpointValue, useColorModeValue as mode, VStack, } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiExternalLink, FiFolder, FiPlus, FiRefreshCw } from "react-icons/fi";
import { useNavigate  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import Helpers from "../../Helpers";
import { IApiProjectSummaryResponse } from "../../models/api/project/IApiProjectSummaryResponse";
import { IApiProjectEditRequest } from "../../models/api/project/IApiProjectEditRequest";
import { EditProjectDrawer } from "../../components/drawers/project/EditProjectDrawer";
import { ApiContext } from "../../contexts/ApiProvider";
import toast from 'react-hot-toast';

interface IProjectsProps {

}

export const Projects: React.FunctionComponent<IProjectsProps> = (props: IProjectsProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();

    const [filter, setFilter] = useState("");
    const [activeOnly, setActiveOnly] = useState(true);
    const { me, setAppShellConfig, projects, addProject, updateProject, setDCM, reloadProjects } = useContext(AppContext);
    const { postProject, patchProject } = useContext(ApiContext);

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState<IApiProjectSummaryResponse | undefined>(undefined);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: "Projekte"
            },
            subHeading: {
                text: me.organization.identity.name
            },
            leftButton: {
                text: "Aktualisieren",
                icon: FiRefreshCw,
                ariaLabel: "aktualisieren",
                variant: "secondary",
                onClick: () => {
                    reloadProjects();
                }
            },
            rightButton: {
                text: "Erstellen",
                icon: FiPlus,
                ariaLabel: "erstellen",
                variant: "primary",
                onClick: () => {
                    setAdd(true);
                }
            }
        });
    }, [setAppShellConfig, setAdd, me, reloadProjects]);

    return (
        <Stack spacing="4" height="full" direction="column">
            <Stack spacing="4" width="full" direction="row" px="2" justifyContent="space-between" align="center">
                <InputGroup maxW={{ base: "75%", lg: undefined }}>
                    <InputLeftElement pointerEvents="none">
                        <Icon as={FiSearch} color="muted" boxSize="5" />
                    </InputLeftElement>
                    <Input placeholder="Suchen..." onChange={e => setFilter(e.target.value.toLocaleLowerCase())} boxShadow={boxShadow} />
                </InputGroup>
                <Checkbox defaultChecked={activeOnly} onChange={e => setActiveOnly(e.target.checked)}>Nur aktive Projekte</Checkbox>
            </Stack>
            <ResponsiveTable 
                data={projects
                    .filter(p => p.name.toLowerCase().indexOf(filter) >= 0 || p.ref.toLowerCase().indexOf(filter) >= 0)
                    .filter(p => !activeOnly || (activeOnly && p.active))}
                onRowClick={(row) => {
                    if(row.original.active) {
                        navigate(`/projects/${row.original.id}/protocols`);
                    }
                }}
                getColumns={(columnHelper) => [
                    columnHelper.accessor('ref', {
                        header: () => 'Referenz',
                        cell: info => info.getValue(),
                    }),
                    columnHelper.accessor('name', {
                        header: () => 'Name',
                        cell: info => info.renderValue(),
                    }),
                    columnHelper.accessor('active', {
                        header: () => 'Status',
                        cell: info => {
                            const active = info.getValue();
                            const project = info.row.original;
                            return (
                                <Badge colorScheme={active ? 'green' : 'red'} size="sm" _hover={{ cursor: 'pointer' }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDCM({
                                            title: `Projekt ${active ? "d" : "r"}eaktivieren`,
                                            body: `Sind Sie sicher, dass dieses Projekt ${active ? "d" : "r"}eaktiviert werden soll?`,
                                            onConfirm: () => {
                                                var promise = patchProject(project.id, { ...project, active: !active});
                                                toast.promise(promise, {
                                                loading: `Projekt wird ${active ? 'deaktiviert' : 'aktiviert'}...`,
                                                success: (result) => {
                                                    updateProject(result);
                                                    return `Projekt wurde erfolgreich ${active ? 'deaktiviert' : 'aktiviert'}!`;
                                                },
                                                error: (code: number) => {
                                                    var message = `Fehler beim ${active ? 'Deaktivieren' : 'Aktivieren'} des Projekts! `;
                                                    switch(code) {
                                                        case 400: {
                                                            message += `Fehler 400`;
                                                            break;
                                                        }
                                                        case 404: {
                                                            message += `Fehler 404`;
                                                            break;
                                                        }
                                                        case 500: {
                                                            message += `Fehler 500`;
                                                            break;
                                                        }
                                                    }
                                                    return message;
                                                },
                                                });
                                                return promise;
                                            }
                                        });
                                    }}
                                >
                                    {active ? 'aktiv' : 'inaktiv'}
                                </Badge>
                            );
                        },
                    }),
                    columnHelper.accessor('protocols', {
                        id: 'protocols_count',
                        header: () => 'Protokolle',
                        cell: info => {
                            var protocols = info.getValue();
                            return !info.row.original.active ? "" : (protocols ? protocols.length : 0);
                        },
                    }),
                    columnHelper.accessor('protocols', {
                        id: 'protocols_latest',
                        header: () => 'Letztes Protokoll',
                        cell: info => {
                            var protocols = info.getValue();
                            if(info.row.original.active && protocols && protocols.length > 0) {
                                return Helpers.formatDate(protocols[protocols.length - 1].submittedAt);
                            } else {
                                return "";
                            }
                        },
                    }),
                    columnHelper.display({
                        id: 'actions',
                        cell: info => {
                            const project = info.row.original;
                            return (
                                <HStack spacing="0" justifyContent="end">
                                    <IconButton
                                        icon={<FiEdit2 fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Bearbeiten"
                                        disabled={!project.active}
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            setEdit(project);
                                        }}
                                    />
                                    {/*
                                    <IconButton
                                        icon={<FiFolder fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Protokolle"
                                        disabled={!project.active}
                                        onClick={(e) => { 
                                            e.stopPropagation(); 
                                            navigate(`/projects/${project.id}/protocols`);
                                        }}
                                    />
                                    */}
                                    <IconButton
                                        icon={<FiExternalLink fontSize="1.125rem" />}
                                        variant="ghost"
                                        aria-label="Öffnen"
                                        disabled={!project.active}
                                        onClick={(e) => { 
                                            e.stopPropagation();
                                            navigate(`/projects/${project.id}`);
                                        }}
                                    />                           
                                </HStack>
                            );
                        },
                    }),
                ]}
                getCard={(project) => ({
                    key: project.id,
                    value: project.id,
                    onClick: () =>  project.active && navigate(`/projects/${project.id}/protocols`),
                    content: (
                        <HStack spacing="4" justifyContent="space-between">
                            <VStack spacing="2" align="left">
                                <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
                                    {project.name}
                                </Text>
                                <HStack>
                                    <Text color="muted" fontSize={{ base: "xs", md: "sm" }}>
                                        {`Referenz: ${project.ref}`}
                                    </Text>
                                    <Badge colorScheme={project.active ? 'green' : 'red'} size="sm"
                                           onClick={(e) => {
                                            e.stopPropagation();
                                            toast.error(`Wechseln Sie in die Desktopversion, um Projekte zu (de)aktivieren.`);
                                           }}
                                    >
                                        {project.active ? 'aktiv' : 'inaktiv'}
                                    </Badge>
                                </HStack>
                            </VStack>
                            <HStack spacing="4" justifyContent="end">
                                <IconButton
                                    icon={<FiEdit2 fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="Bearbeiten"
                                    disabled={!project.active}
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        setEdit(project);
                                    }}
                                />
                                <IconButton
                                    icon={<FiFolder fontSize="1.25rem" />}
                                    variant="ghost"
                                    aria-label="protocols"
                                    disabled={!project.active}
                                    onClick={(e) => { 
                                        e.stopPropagation(); 
                                        navigate(`/projects/${project.id}/protocols`);
                                    }}
                                />
                            </HStack>
                        </HStack>
                    )
                })}
            />
            <AddProjectDrawer 
                isOpen={add} 
                onDiscard={() => setAdd(false)}
                onSubmit={(data) => {
                    console.log(data)
                    var promise = postProject(data);
                    toast.promise(promise, {
                      loading: `Projekt wird erstellt...`,
                      success: (result) => {
                        setAdd(false);
                        addProject(result);
                        return `Das Projekt '${data.name}' wurde erfolgreich erstellt!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Erstellen des Projekts! `;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 409: {
                                message += `Ein Projekt mit der Referenz '${data.ref}' existiert bereits!`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      }
                    });
                    return promise;
                }} 
            />
            <EditProjectDrawer 
                defaultValue={edit as IApiProjectEditRequest}
                onDiscard={() => setEdit(undefined)} 
                onSubmit={(data) => {
                    var promise = patchProject(edit!.id, { ...edit, ...data });
                    toast.promise(promise, {
                      loading: `Projekt wird aktualisiert...`,
                      success: (result) => {
                        setEdit(undefined);
                        updateProject(result);
                        return `Das Projekt '${data.name}' wurde erfolgreich aktualisiert!`;
                      },
                      error: (code) => {
                        var message = `Fehler beim Aktualisieren des Projekts!`;
                        switch(code) {
                            case 400: {
                                message += `Fehler 400`;
                                break;
                            }
                            case 404: {
                                message += `Fehler 404`;
                                break;
                            }
                            case 500: {
                                message += `Fehler 500`;
                                break;
                            }
                        }
                        return message;
                      },
                    });
                    return promise;
                }} 
                readonly={{
                    ref: edit?.ref
                }}
            />
        </Stack>
    )

}