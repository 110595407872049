import {
  Button,
  Container,
  Flex,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  HStack,
  ResponsiveValue,
  IconButton,
  Divider
} from '@chakra-ui/react'
import * as React from 'react'
import { useContext, useState } from 'react'
import { FiDownloadCloud } from 'react-icons/fi'
import { useParams } from 'react-router-dom'
import { AppContext } from '../../App'
import { AppShellButton, IAppShellButtonProps } from './AppShellButton'
import { Navbar } from './Navbar'
import { Sidebar } from './Sidebar'

interface IAppShellProps {
  children: React.ReactNode;
  config?: IAppShellConfig | undefined;
}

export interface IAppShellConfig {
  topHeading?: {
    text?: string | undefined
  } | undefined, 
  subHeading?: {
    text?: string | undefined
  } | undefined,
  leftButton?: IAppShellButtonProps | undefined | { (): React.ReactNode },
  centerButton?: IAppShellButtonProps | undefined | { (): React.ReactNode },
  rightButton?: IAppShellButtonProps | undefined | { (): React.ReactNode },
}

export const AppShell: React.FunctionComponent<IAppShellProps> = (props: IAppShellProps) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });  
  const topHeadingSize = useBreakpointValue({ base: 'xs', lg: 'sm' });
  const subHeadingSize = useBreakpointValue({ base: 'xs', lg: 'sm' });

  const { config } = props;

  return (
    <Flex
      as="section"
      direction={{ base: 'column', lg: 'row' }}
      height="100%" // mobile browser fix?
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? <Sidebar /> : <Navbar />}
      <Container py="4" px={isDesktop ? "4" : "2"} flex="1" h={isDesktop ? "full" : "calc(100% - 64px)"}>
        <Stack spacing={{ base: '8', lg: '6' }} h="full">
          {config && (
            <>
            <HStack
              spacing="4"
              justify="space-between"
              align="center"
            >
              <Stack spacing="1">
                {config.topHeading && config.topHeading.text && (
                  <Heading size={topHeadingSize} fontWeight="medium">
                    {config.topHeading.text}
                  </Heading>
                )}
                {config.subHeading && config.subHeading.text && (
                  <Text size={subHeadingSize} color="muted">
                    {config.subHeading.text}
                  </Text>
                )}
              </Stack>
              <HStack spacing="3">
                <>
                  {config.leftButton   && typeof(config.leftButton)   !== 'function' && <AppShellButton {...config.leftButton} />}
                  {config.leftButton   && typeof(config.leftButton)   === 'function' && config.leftButton()}

                  {config.centerButton && typeof(config.centerButton) !== 'function' && <AppShellButton {...config.centerButton} />}
                  {config.centerButton && typeof(config.centerButton) === 'function' && config.centerButton()}

                  {config.rightButton  && typeof(config.rightButton)  !== 'function' && <AppShellButton {...config.rightButton} />}
                  {config.rightButton  && typeof(config.rightButton)  === 'function' && config.rightButton()}
                </>
              </HStack>
            </HStack>
            <Divider mt="4 !important"  />
            </>
          )}
          <Container overflowY="auto" flex="1" px="0" mt="4 !important">
            {props.children}
          </Container>
        </Stack>
      </Container>
    </Flex>
  )
}
