import { useColorModeValue as mode, Input, Stack, FormControl, FormHelperText, FormLabel, FormErrorMessage } from '@chakra-ui/react'
import { Field, FormikProps } from 'formik';
import * as React from 'react'

import { IApiDeficitEditRequest } from '../../../models/api/deficit/IApiDeficitEditRequest';
import { InputFormControl } from '../../formcontrols/InputFormControl';
import { TextAreaFormControl } from '../../formcontrols/TextAreaFormControl';
import { IBaseDrawerProps } from '../BaseDrawer';
import { BaseDrawerWithForm } from '../BaseDrawerWithForm';

export interface IEditDeficitDrawerProps extends Omit<IBaseDrawerProps<IApiDeficitEditRequest>, "defaultValue" | "submitAllowed" | "children" | "saveText" | "discardText" | "headerText" | "isOpen"> {
    defaultValue: IApiDeficitEditRequest | undefined;
}

export const EditDeficitDrawer = (props: IEditDeficitDrawerProps) => {
    return (
        <BaseDrawerWithForm
            isOpen={props.defaultValue !== undefined}
            onSubmit={props.onSubmit}
            onDiscard={props.onDiscard}
            defaultValues={props.defaultValue}
            headerText="Eintrag bearbeiten"
        >
            {({ handleSubmit, errors, touched, isSubmitting }: FormikProps<IApiDeficitEditRequest>) => (
                <Stack px="0" spacing="4">
                    <TextAreaFormControl 
                        field="value"
                        label="Text"
                        isInvalid={(errors.value && touched.value) ? true : false}
                        disabled={isSubmitting}
                        validate={(value: string) => {
                            let error;
                            if (value === null || value === undefined || value.length < 1) {
                                error = "Text ist erforderlich!";
                            }
                            return error;
                        }}
                        errorMessage={errors.value}
                    />
                </Stack>
            )}
        </BaseDrawerWithForm>
    );
}