import React, { useState, useContext, useEffect } from "react";
import { Badge, Select, Box, Button, ButtonGroup, Checkbox, Divider, HStack, Icon, IconButton, Input, FormControl, FormLabel, InputLeftElement, Text, ListItem, Stack, Skeleton, useBreakpointValue, useColorModeValue as mode, VStack, Editable, EditablePreview, EditableInput, IconButtonProps, useEditableControls } from "@chakra-ui/react";
import { FiEdit2, FiSearch, FiTrash2, FiPlay, FiRefreshCw, FiPlusSquare, FiFolder, FiPlus } from "react-icons/fi";
import { useNavigate, useParams  } from "react-router-dom";
import { AppContext } from "../../App";
import { AddProjectDrawer } from "../../components/drawers/project/AddProjectDrawer";
import { ContactSearchDrawer, IContactSearchDrawerConfig } from "../../components/drawers/contact-search/ContactSearchDrawer";
import { ContactSearchResult } from "../../components/ContactSearch/ContactSearchResult";
import { ResponsiveTable } from "../../components/ResponsiveTable/ResponsiveTable";
import { EApiContactSource } from "../../models/api/contact/IApiContactResponse";
import { IApiProjectOptionCoreData } from "../../models/api/project/options/IApiProjectOptionCoreData";
import { ApiContext } from "../../contexts/ApiProvider";
import { Formik, FormikProps } from "formik";
import { InputFormControl } from "../../components/formcontrols/InputFormControl";
import { TextAreaFormControl } from "../../components/formcontrols/TextAreaFormControl";
import EditableEditControls from "../../components/editable/EditableEditControls";
import toast from "react-hot-toast";

interface IProjectCoreDataProps {

}

export const ProjectCoreData: React.FunctionComponent<IProjectCoreDataProps> = (props: IProjectCoreDataProps) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true }, { ssr: false });
    const boxShadow = mode('sm', 'sm-dark');

    const navigate = useNavigate();
    const { setAppShellConfig, projects } = useContext(AppContext);
    const [sdc, setSDC] = useState<IContactSearchDrawerConfig | undefined>(undefined);
    
    const { projectId } = useParams();
    const project = projects.find(p => p.id === projectId);   
    const [option, setOption] = useState<IApiProjectOptionCoreData | undefined>(undefined);

    const { getProjectCoreData, patchProjectCoreData } = useContext(ApiContext);

    useEffect(() => {
        setAppShellConfig({
            topHeading: {
                text: `Stammdaten`
            },
            subHeading: {
                text: `${project?.name} (${project?.ref})`
            }
        });
        if(!project) {
            navigate(`/projects`);
            return;
        }
        getProjectCoreData(project.id).then(result => setOption(result));
    }, [setAppShellConfig, project, navigate, getProjectCoreData, setOption]);

    if(!project) {
        return <h1>Projekt nicht gefunden!</h1>
    }

    const patch = (id: string, data: IApiProjectOptionCoreData, propertyName: string): Promise<IApiProjectOptionCoreData> => {
        var promise = patchProjectCoreData(id, data);
        toast.promise(promise, {
            loading: `${propertyName} wird aktualisiert...`,
            success: `${propertyName} erfolgreich aktualisiert!`,
            error: (code) => {
                var message = `Fehler beim Aktualisieren von ${propertyName}!`;
                switch(code) {
                    case 400: {
                        message += `Fehler 400`;
                        break;
                    }
                    case 404: {
                        message += `Fehler 404`;
                        break;
                    }
                    case 500: {
                        message += `Fehler 500`;
                        break;
                    }
                }
                return message;
            },
        });
        return promise;
    }

    return (
        <Stack spacing="4" height="full" direction="column">
            {option === undefined && (
                <Stack>
                    <Skeleton height='30px' borderRadius={8} />
                    <Skeleton height='30px' borderRadius={8} />
                    <Skeleton height='30px' borderRadius={8} />
                </Stack>
            )}
            {option !== undefined && (
                <Stack px="2" spacing="4">
                                <Stack direction={isDesktop ? "row" : "column"} >
                                    <FormControl>    
                                        <FormLabel>Bauvorhaben</FormLabel>
                                        <Editable 
                                            defaultValue={option.bauvorhaben && option.bauvorhaben.length > 0 ? option.bauvorhaben : "- nicht festgelegt -"} 
                                            fontSize="lg" 
                                            fontWeight="medium" 
                                            onSubmit={e => patch(projectId!, { ...option, bauvorhaben: e}, "Bauvorhaben").then(result => setOption(result))}
                                            isPreviewFocusable={false}
                                        >
                                            <HStack>
                                                <EditablePreview style={{ overflowWrap: "anywhere" }} />
                                                <EditableInput />
                                                <EditableEditControls submitButtonSize='md' editButtonSize='sm' />
                                            </HStack>
                                        </Editable> 
                                    </FormControl>
                                    <FormControl>    
                                        <FormLabel>Adresse</FormLabel>
                                        <Editable 
                                            defaultValue={option.adresse && option.adresse.length > 0 ? option.adresse : "- nicht festgelegt -"} 
                                            fontSize="lg" 
                                            fontWeight="medium" 
                                            onSubmit={e => patch(projectId!, { ...option, adresse: e}, "Adresse").then(result => setOption(result))}
                                            isPreviewFocusable={false}
                                        >
                                            <HStack>
                                                <EditablePreview style={{ overflowWrap: "anywhere" }} />
                                                <EditableInput />
                                                <EditableEditControls submitButtonSize='md' editButtonSize='sm' />
                                            </HStack>
                                        </Editable> 
                                    </FormControl>
                                </Stack>
                                <Divider />
                                <ResponsiveTable 
                                    data={[{
                                            property: "bauherr",
                                            role: "Bauherr",
                                            contact: option.bauherr
                                        },{
                                            property: "oeba",
                                            role: "ÖBA",
                                            contact: option.oeba
                                        },{
                                            property: "planungskoordinator",
                                            role: "Planungskoordinator",
                                            contact: option.planungskoordinator
                                        },{
                                            property: "projektleiter",
                                            role: "Projektleiter",
                                            contact: option.projektleiter
                                        },{
                                            property: "baustellenkoordinator",
                                            role: "Baustellenkoordinator",
                                            contact: option.baustellenkoordinator
                                        }
                                    ]}
                                    getColumns={(columnHelper) => [
                                        columnHelper.accessor('role', {
                                            header: () => 'Rolle',
                                            cell: info => info.getValue()
                                        }),
                                        columnHelper.accessor('contact', {
                                            header: () => 'Zuordnung',
                                            cell: info => {
                                                const contact = info.getValue();
                                                if(contact) {
                                                    return (<ContactSearchResult contact={contact} />)
                                                }
                                                return ("- nicht festgelegt -");
                                            }
                                        }),
                                        columnHelper.display({
                                            id: 'actions',
                                            cell: info => (
                                                <HStack spacing="0" justifyContent="end">
                                                    <IconButton
                                                        icon={<FiEdit2 fontSize="1.125rem" />}
                                                        variant="ghost"
                                                        aria-label="Bearbeiten"
                                                        onClick={(e) => { 
                                                            e.stopPropagation(); 
                                                            setSDC({
                                                                onSubmit: (contact) => {
                                                                    patch(projectId!, { ...option, [info.row.original.property]: contact}, info.row.original.role).then(result => setOption(result));
                                                                    setSDC(undefined);                                                                    
                                                                }
                                                            });
                                                        }}
                                                    />                      
                                                </HStack>
                                            )
                                        }),
                                    ]}
                                    getCard={({ property, role, contact }) => ({
                                        key: role,
                                        value: role,
                                        content: (
                                            <HStack spacing="4" justifyContent="space-between">
                                                <FormControl>    
                                                    <FormLabel>{role}</FormLabel>
                                                    <Divider mb="2" />
                                                    {(contact && (
                                                        <ContactSearchResult contact={contact} />
                                                    )) || (
                                                        <Text fontSize="sm">- nicht festgelegt -</Text>
                                                    )}
                                                </FormControl>
                                                <HStack spacing="4" justifyContent="end">
                                                    <IconButton
                                                        icon={<FiEdit2 fontSize="1.25rem" />}
                                                        variant="ghost"
                                                        aria-label="Bearbeiten"
                                                        onClick={(e) => { 
                                                            e.stopPropagation(); 
                                                            setSDC({
                                                                onSubmit: (contact) => {
                                                                    patch(projectId!, { ...option, [property]: contact}, role).then(result => setOption(result));
                                                                    setSDC(undefined);                                                                    
                                                                }
                                                            });
                                                        }}
                                                    />
                                                </HStack>
                                            </HStack>
                                        )
                                    })}
                                />
                            </Stack>
            )}
            <ContactSearchDrawer {...sdc} clear={() => setSDC(undefined)} />
        </Stack>
    );

}